////
/// Core Tool : Reset btn
/// @group core
////

@mixin btn-reset ($minimal: false) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  margin: 0; // Address margins set differently in Firefox 4+, Safari, and Chrome.
  @if not $minimal {
    background-color: transparent;
    font-family: inherit;
  }
}
