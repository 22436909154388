////
/// Core Module : Grid col
/// @group core
////

#{ns(col)} {
  // alignment
  &--top {
    align-self: flex-start;
  }

  &--bottom {
    align-self: flex-end;
  }

  &--middle {
    align-self: center;
  }
}
