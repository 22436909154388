////
/// Core Module : Reset headings
/// @group core
////

@if (map-get($reset-settings, heading)) {
  @each $title, $variation in ($title-settings) {
    @if str-index(quote($title), 'h') == 1 {
      #{$title} {
        @include title-style($title, true);
      }
    }
  }
}
