@charset "UTF-8";
/* ¯¯¯¯¯¯¯¯¯ *\
  CORE
\* ˍˍˍˍˍˍˍˍˍ */
@media (min-width: 36em) {
  /*! media sm */
}

@media (min-width: 48em) {
  /*! media md */
}

@media (min-width: 62em) {
  /*! media lg */
}

@media (min-width: 78em) {
  /*! media xl */
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --underline-img: linear-gradient(0deg, currentColor, currentColor);
  --link-blank-content: '';
}

[href] {
  background-image: var(--underline-img), var(--underline-img);
  background-position: 50% calc(50% + 0.65625em), 50% calc(50% + 0.65625em);
  background-repeat: no-repeat, no-repeat;
  transition: background-size 0s;
  background-size: 0 0.125em, 100% 0.0625em;
}

[target="_blank"]::after {
  content: "";
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: middle;
  background-color: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}

[target="_blank"]::after {
  --icon-size: 1rem;
}

[target="_blank"]::after {
  content: var(--link-blank-content);
  -webkit-mask-image: url("../icons/system/external-link-line.svg");
  mask-image: url("../icons/system/external-link-line.svg");
}

.fr-raw-link {
  --underline-img: none;
  --link-blank-content: none;
}

.fr-reset-link {
  --underline-img: none;
  --link-blank-content: none;
  text-decoration: underline;
  text-underline-offset: 2px;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  background-color: transparent;
  font-family: inherit;
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Marianne", arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  margin: 0;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

*:focus,
*:focus-visible {
  z-index: 500;
}

a, button, input, input[type="checkbox"] + label::before, input[type="radio"] + label::before, input[type="button"], input[type="image"], input[type="reset"], input[type="submit"], select, textarea, [tabindex], video[controls], audio[controls], [contenteditable]:not([contenteditable="false"]), details, details > summary:first-of-type {
  outline-offset: 2px;
  outline-width: 2px;
  outline-color: #0a76f6;
}

a:focus, button:focus, input:focus, input[type="checkbox"]:focus + label::before, input[type="radio"]:focus + label::before, input[type="button"]:focus, input[type="image"]:focus, input[type="reset"]:focus, input[type="submit"]:focus, select:focus, textarea:focus, [tabindex]:focus, video:focus[controls], audio:focus[controls], [contenteditable]:not([contenteditable="false"]):focus, details:focus, details > summary:first-of-type:focus {
  outline-style: solid;
}

a:focus:not(:focus-visible), button:focus:not(:focus-visible), input:focus:not(:focus-visible), input[type="checkbox"]:focus:not(:focus-visible) + label::before, input[type="radio"]:focus:not(:focus-visible) + label::before, input[type="button"]:focus:not(:focus-visible), input[type="image"]:focus:not(:focus-visible), input[type="reset"]:focus:not(:focus-visible), input[type="submit"]:focus:not(:focus-visible), select:focus:not(:focus-visible), textarea:focus:not(:focus-visible), [tabindex]:focus:not(:focus-visible), video:focus:not(:focus-visible)[controls], audio:focus:not(:focus-visible)[controls], [contenteditable]:not([contenteditable="false"]):focus:not(:focus-visible), details:focus:not(:focus-visible), details > summary:first-of-type:focus:not(:focus-visible) {
  outline-style: none;
}

a:focus-visible, button:focus-visible, input:focus-visible, input[type="checkbox"]:focus-visible + label::before, input[type="radio"]:focus-visible + label::before, input[type="button"]:focus-visible, input[type="image"]:focus-visible, input[type="reset"]:focus-visible, input[type="submit"]:focus-visible, select:focus-visible, textarea:focus-visible, [tabindex]:focus-visible, video:focus-visible[controls], audio:focus-visible[controls], [contenteditable]:not([contenteditable="false"]):focus-visible, details:focus-visible, details > summary:first-of-type:focus-visible {
  outline-style: solid;
}

@media (hover: hover) and (pointer: fine) {
  :root {
    --brighten: -1;
  }
  a[href]:hover, button:not(:disabled):hover, input[type="button"]:not(:disabled):hover, input[type="image"]:not(:disabled):hover, input[type="reset"]:not(:disabled):hover, input[type="submit"]:not(:disabled):hover {
    background-color: var(--hover-tint);
  }
  a[href]:active, button:not(:disabled):active, input[type="button"]:not(:disabled):active, input[type="image"]:not(:disabled):active, input[type="reset"]:not(:disabled):active, input[type="submit"]:not(:disabled):active {
    background-color: var(--active-tint);
  }
  a[href]:hover, a[href]:active {
    background-size: 100% 0.125em, 100% 0.0625em;
  }
}

button, input[type="button"], input[type="image"], input[type="reset"], input[type="submit"] {
  --hover-tint: var(--hover);
  --active-tint: var(--active);
}

a {
  --hover-tint: transparent;
  --active-tint: var(--active);
}

a, button, input[type="checkbox"], input[type="checkbox"] + label, input[type="radio"], input[type="radio"] + label, input[type="file"], input[type="range"], input[type="button"], input[type="image"], input[type="reset"], input[type="submit"], select, textarea, video[controls], audio[controls], details, details > summary:first-of-type {
  cursor: pointer;
}

a:not([href]), button:disabled, input:disabled, input[type="checkbox"]:disabled, input[type="checkbox"]:disabled + label, input[type="radio"]:disabled, input[type="radio"]:disabled + label, input[type="file"]:disabled, input[type="range"]:disabled, input[type="button"]:disabled, input[type="image"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled, select:disabled, textarea:disabled, video:not([href])[controls], audio:not([href])[controls] {
  cursor: not-allowed;
}

/**
 * Override de l'opacité chrome sur un élément disabled
 */
a:not([href]), button:disabled, input:disabled, input[type="checkbox"]:disabled, input[type="checkbox"]:disabled + label, input[type="radio"]:disabled, input[type="radio"]:disabled + label, textarea:disabled, video:not([href]), audio:not([href]) {
  opacity: 1;
}

.fr-enlarge-link {
  position: relative;
}

.fr-enlarge-link a {
  background-image: none !important;
  outline-width: 0 !important;
}

.fr-enlarge-link a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline-offset: 2px;
  outline-style: inherit;
  outline-color: inherit;
  outline-width: 2px;
  z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
  .fr-enlarge-link:hover {
    background-color: var(--hover);
  }
  .fr-enlarge-link:active {
    background-color: var(--active);
  }
}

.fr-transition-none {
  transition: none !important;
}

/**
 * Déclaration des fontes
 */
@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Light.woff2") format("woff2"), url("../fonts/Marianne-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Light_Italic.woff2") format("woff2"), url("../fonts/Marianne-Light_Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Regular.woff2") format("woff2"), url("../fonts/Marianne-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Regular_Italic.woff2") format("woff2"), url("../fonts/Marianne-Regular_Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Medium.woff2") format("woff2"), url("../fonts/Marianne-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Medium_Italic.woff2") format("woff2"), url("../fonts/Marianne-Medium_Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Bold.woff2") format("woff2"), url("../fonts/Marianne-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url("../fonts/Marianne-Bold_Italic.woff2") format("woff2"), url("../fonts/Marianne-Bold_Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Spectral;
  src: url("../fonts/Spectral-Regular.woff2") format("woff2"), url("../fonts/Spectral-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Spectral;
  src: url("../fonts/Spectral-ExtraBold.woff2") format("woff2"), url("../fonts/Spectral-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

h6 {
  font-weight:  700 ;
  font-size:  1.125rem ;
  line-height:  1.5rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h6 {
    font-size:  1.25rem ;
    line-height:  1.75rem ;
  }
}

h5 {
  font-weight:  700 ;
  font-size:  1.25rem ;
  line-height:  1.75rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h5 {
    font-size:  1.375rem ;
    line-height:  1.75rem ;
  }
}

h4 {
  font-weight:  700 ;
  font-size:  1.375rem ;
  line-height:  1.75rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h4 {
    font-size:  1.5rem ;
    line-height:  2rem ;
  }
}

h3 {
  font-weight:  700 ;
  font-size:  1.5rem ;
  line-height:  2rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h3 {
    font-size:  1.75rem ;
    line-height:  2.25rem ;
  }
}

h2 {
  font-weight:  700 ;
  font-size:  1.75rem ;
  line-height:  2.25rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h2 {
    font-size:  2rem ;
    line-height:  2.5rem ;
  }
}

h1 {
  font-weight:  700 ;
  font-size:  2rem ;
  line-height:  2.5rem ;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  h1 {
    font-size:  2.5rem ;
    line-height:  3rem ;
  }
}

p {
  font-size:  1rem ;
  line-height:  1.5rem ;
  margin: var(--text-spacing);
}

:root {
  --ul-type: disc;
  --ol-type: decimal;
  --ul-start: 1rem;
  --ol-start: 1.5rem;
  --xl-block: 0.5rem;
  --li-bottom: 0.25rem;
  --xl-base: 1em;
  --ol-content: counters(li-counter, ".") ".\00a0\00a0";
}

ul,
ol {
  margin: 0;
  padding: 0;
  margin-block-start: var(--xl-block);
  margin-block-end: var(--xl-block);
  --xl-size: var(--xl-base);
}

ul {
  list-style-type: var(--ul-type);
  padding-inline-start: var(--ul-start);
}

ul > li::marker {
  font-size: calc(var(--xl-size) * 0.9);
}

ol {
  list-style-type: var(--ol-type);
  padding-inline-start: var(--ol-start);
  counter-reset: li-counter;
}

ol > li {
  counter-increment: li-counter;
}

ol > li::marker {
  content: var(--ol-content);
  font-size: var(--xl-size);
  font-weight: bold;
}

li {
  --xl-base: calc(var(--xl-size) * 0.9);
  padding-bottom: var(--li-bottom);
}

.fr-raw-list {
  --ul-type: none;
  --ol-type: none;
  --ul-start: 0;
  --ol-start: 0;
  --xl-block: 0;
  --li-bottom: 0;
  --ol-content: none;
}

.fr-list {
  --ul-type: disc;
  --ol-type: decimal;
  --ul-start: 1rem;
  --ol-start: 1.5rem;
  --xl-block: 0.5rem;
  --li-bottom: 0.25rem;
  --xl-base: 1em;
  --ol-content: counters(li-counter, ".") ".\00a0\00a0";
}

sub {
  line-height: 1;
}

sup {
  line-height: 1;
}

.fr-text--light {
  font-weight: 300 !important;
}

.fr-text--regular {
  font-weight: 400 !important;
}

.fr-text--bold {
  font-weight: 700 !important;
}

.fr-text--heavy {
  font-weight: 900 !important;
}

:root {
  --text-spacing: 0 0 1.5rem;
  --title-spacing: 0 0 1.5rem;
  --display-spacing: 0 0 2rem;
}

.fr-h6 {
  font-weight:  700  !important;
  font-size:  1.125rem  !important;
  line-height:  1.5rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h6 {
    font-size:  1.25rem  !important;
    line-height:  1.75rem  !important;
  }
}

.fr-h5 {
  font-weight:  700  !important;
  font-size:  1.25rem  !important;
  line-height:  1.75rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h5 {
    font-size:  1.375rem  !important;
    line-height:  1.75rem  !important;
  }
}

.fr-h4 {
  font-weight:  700  !important;
  font-size:  1.375rem  !important;
  line-height:  1.75rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h4 {
    font-size:  1.5rem  !important;
    line-height:  2rem  !important;
  }
}

.fr-h3 {
  font-weight:  700  !important;
  font-size:  1.5rem  !important;
  line-height:  2rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h3 {
    font-size:  1.75rem  !important;
    line-height:  2.25rem  !important;
  }
}

.fr-h2 {
  font-weight:  700  !important;
  font-size:  1.75rem  !important;
  line-height:  2.25rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h2 {
    font-size:  2rem  !important;
    line-height:  2.5rem  !important;
  }
}

.fr-h1 {
  font-weight:  700  !important;
  font-size:  2rem  !important;
  line-height:  2.5rem  !important;
  margin: var(--title-spacing);
}

@media (min-width: 48em) {
  .fr-h1 {
    font-size:  2.5rem  !important;
    line-height:  3rem  !important;
  }
}

.fr-display--xs {
  font-weight:  700  !important;
  font-size:  2.5rem  !important;
  line-height:  3rem  !important;
  margin: var(--display-spacing);
}

@media (min-width: 48em) {
  .fr-display--xs {
    font-size:  3rem  !important;
    line-height:  3.5rem  !important;
  }
}

.fr-display--sm {
  font-weight:  700  !important;
  font-size:  3rem  !important;
  line-height:  3.5rem  !important;
  margin: var(--display-spacing);
}

@media (min-width: 48em) {
  .fr-display--sm {
    font-size:  3.5rem  !important;
    line-height:  4rem  !important;
  }
}

.fr-display--md {
  font-weight:  700  !important;
  font-size:  3.5rem  !important;
  line-height:  4rem  !important;
  margin: var(--display-spacing);
}

@media (min-width: 48em) {
  .fr-display--md {
    font-size:  4rem  !important;
    line-height:  4.5rem  !important;
  }
}

.fr-display--lg {
  font-weight:  700  !important;
  font-size:  4rem  !important;
  line-height:  4.5rem  !important;
  margin: var(--display-spacing);
}

@media (min-width: 48em) {
  .fr-display--lg {
    font-size:  4.5rem  !important;
    line-height:  5rem  !important;
  }
}

.fr-display--xl {
  font-weight:  700  !important;
  font-size:  4.5rem  !important;
  line-height:  5rem  !important;
  margin: var(--display-spacing);
}

@media (min-width: 48em) {
  .fr-display--xl {
    font-size:  5rem  !important;
    line-height:  5.5rem  !important;
  }
}

.fr-text--alt {
  font-family: "Spectral", georgia, serif !important;
}

.fr-text--xs {
  font-size:  0.75rem  !important;
  line-height:  1.25rem  !important;
  margin: var(--text-spacing);
}

.fr-text--sm {
  font-size:  0.875rem  !important;
  line-height:  1.5rem  !important;
  margin: var(--text-spacing);
}

.fr-text--md {
  font-size:  1rem  !important;
  line-height:  1.5rem  !important;
  margin: var(--text-spacing);
}

.fr-text--lg {
  font-size:  1.125rem  !important;
  line-height:  1.75rem  !important;
  margin: var(--text-spacing);
}

.fr-text--xl, .fr-text--lead {
  font-size:  1.25rem  !important;
  line-height:  2rem  !important;
  margin: var(--text-spacing);
}

:root {
  --background-default-grey: var(--grey-1000-50);
  --background-default-grey-hover: var(--grey-1000-50-hover);
  --background-default-grey-active: var(--grey-1000-50-active);
  --background-elevated-grey: var(--grey-1000-75);
  --background-elevated-grey-hover: var(--grey-1000-75-hover);
  --background-elevated-grey-active: var(--grey-1000-75-active);
  --background-alt-grey: var(--grey-975-75);
  --background-alt-grey-hover: var(--grey-975-75-hover);
  --background-alt-grey-active: var(--grey-975-75-active);
  --background-alt-blue-france: var(--blue-france-975-75);
  --background-alt-blue-france-hover: var(--blue-france-975-75-hover);
  --background-alt-blue-france-active: var(--blue-france-975-75-active);
  --background-alt-red-marianne: var(--red-marianne-975-75);
  --background-alt-red-marianne-hover: var(--red-marianne-975-75-hover);
  --background-alt-red-marianne-active: var(--red-marianne-975-75-active);
  --background-alt-green-tilleul-verveine: var(--green-tilleul-verveine-975-75);
  --background-alt-green-tilleul-verveine-hover: var(--green-tilleul-verveine-975-75-hover);
  --background-alt-green-tilleul-verveine-active: var(--green-tilleul-verveine-975-75-active);
  --background-alt-green-bourgeon: var(--green-bourgeon-975-75);
  --background-alt-green-bourgeon-hover: var(--green-bourgeon-975-75-hover);
  --background-alt-green-bourgeon-active: var(--green-bourgeon-975-75-active);
  --background-alt-green-emeraude: var(--green-emeraude-975-75);
  --background-alt-green-emeraude-hover: var(--green-emeraude-975-75-hover);
  --background-alt-green-emeraude-active: var(--green-emeraude-975-75-active);
  --background-alt-green-menthe: var(--green-menthe-975-75);
  --background-alt-green-menthe-hover: var(--green-menthe-975-75-hover);
  --background-alt-green-menthe-active: var(--green-menthe-975-75-active);
  --background-alt-green-archipel: var(--green-archipel-975-75);
  --background-alt-green-archipel-hover: var(--green-archipel-975-75-hover);
  --background-alt-green-archipel-active: var(--green-archipel-975-75-active);
  --background-alt-blue-ecume: var(--blue-ecume-975-75);
  --background-alt-blue-ecume-hover: var(--blue-ecume-975-75-hover);
  --background-alt-blue-ecume-active: var(--blue-ecume-975-75-active);
  --background-alt-blue-cumulus: var(--blue-cumulus-975-75);
  --background-alt-blue-cumulus-hover: var(--blue-cumulus-975-75-hover);
  --background-alt-blue-cumulus-active: var(--blue-cumulus-975-75-active);
  --background-alt-purple-glycine: var(--purple-glycine-975-75);
  --background-alt-purple-glycine-hover: var(--purple-glycine-975-75-hover);
  --background-alt-purple-glycine-active: var(--purple-glycine-975-75-active);
  --background-alt-pink-macaron: var(--pink-macaron-975-75);
  --background-alt-pink-macaron-hover: var(--pink-macaron-975-75-hover);
  --background-alt-pink-macaron-active: var(--pink-macaron-975-75-active);
  --background-alt-pink-tuile: var(--pink-tuile-975-75);
  --background-alt-pink-tuile-hover: var(--pink-tuile-975-75-hover);
  --background-alt-pink-tuile-active: var(--pink-tuile-975-75-active);
  --background-alt-yellow-tournesol: var(--yellow-tournesol-975-75);
  --background-alt-yellow-tournesol-hover: var(--yellow-tournesol-975-75-hover);
  --background-alt-yellow-tournesol-active: var(--yellow-tournesol-975-75-active);
  --background-alt-yellow-moutarde: var(--yellow-moutarde-975-75);
  --background-alt-yellow-moutarde-hover: var(--yellow-moutarde-975-75-hover);
  --background-alt-yellow-moutarde-active: var(--yellow-moutarde-975-75-active);
  --background-alt-orange-terre-battue: var(--orange-terre-battue-975-75);
  --background-alt-orange-terre-battue-hover: var(--orange-terre-battue-975-75-hover);
  --background-alt-orange-terre-battue-active: var(--orange-terre-battue-975-75-active);
  --background-alt-brown-cafe-creme: var(--brown-cafe-creme-975-75);
  --background-alt-brown-cafe-creme-hover: var(--brown-cafe-creme-975-75-hover);
  --background-alt-brown-cafe-creme-active: var(--brown-cafe-creme-975-75-active);
  --background-alt-brown-caramel: var(--brown-caramel-975-75);
  --background-alt-brown-caramel-hover: var(--brown-caramel-975-75-hover);
  --background-alt-brown-caramel-active: var(--brown-caramel-975-75-active);
  --background-alt-brown-opera: var(--brown-opera-975-75);
  --background-alt-brown-opera-hover: var(--brown-opera-975-75-hover);
  --background-alt-brown-opera-active: var(--brown-opera-975-75-active);
  --background-alt-beige-gris-galet: var(--beige-gris-galet-975-75);
  --background-alt-beige-gris-galet-hover: var(--beige-gris-galet-975-75-hover);
  --background-alt-beige-gris-galet-active: var(--beige-gris-galet-975-75-active);
  --background-contrast-grey: var(--grey-950-100);
  --background-contrast-grey-hover: var(--grey-950-100-hover);
  --background-contrast-grey-active: var(--grey-950-100-active);
  --background-contrast-blue-france: var(--blue-france-950-100);
  --background-contrast-blue-france-hover: var(--blue-france-950-100-hover);
  --background-contrast-blue-france-active: var(--blue-france-950-100-active);
  --background-contrast-red-marianne: var(--red-marianne-950-100);
  --background-contrast-red-marianne-hover: var(--red-marianne-950-100-hover);
  --background-contrast-red-marianne-active: var(--red-marianne-950-100-active);
  --background-contrast-green-tilleul-verveine: var(--green-tilleul-verveine-950-100);
  --background-contrast-green-tilleul-verveine-hover: var(--green-tilleul-verveine-950-100-hover);
  --background-contrast-green-tilleul-verveine-active: var(--green-tilleul-verveine-950-100-active);
  --background-contrast-green-bourgeon: var(--green-bourgeon-950-100);
  --background-contrast-green-bourgeon-hover: var(--green-bourgeon-950-100-hover);
  --background-contrast-green-bourgeon-active: var(--green-bourgeon-950-100-active);
  --background-contrast-green-emeraude: var(--green-emeraude-950-100);
  --background-contrast-green-emeraude-hover: var(--green-emeraude-950-100-hover);
  --background-contrast-green-emeraude-active: var(--green-emeraude-950-100-active);
  --background-contrast-green-menthe: var(--green-menthe-950-100);
  --background-contrast-green-menthe-hover: var(--green-menthe-950-100-hover);
  --background-contrast-green-menthe-active: var(--green-menthe-950-100-active);
  --background-contrast-green-archipel: var(--green-archipel-950-100);
  --background-contrast-green-archipel-hover: var(--green-archipel-950-100-hover);
  --background-contrast-green-archipel-active: var(--green-archipel-950-100-active);
  --background-contrast-blue-ecume: var(--blue-ecume-950-100);
  --background-contrast-blue-ecume-hover: var(--blue-ecume-950-100-hover);
  --background-contrast-blue-ecume-active: var(--blue-ecume-950-100-active);
  --background-contrast-blue-cumulus: var(--blue-cumulus-950-100);
  --background-contrast-blue-cumulus-hover: var(--blue-cumulus-950-100-hover);
  --background-contrast-blue-cumulus-active: var(--blue-cumulus-950-100-active);
  --background-contrast-purple-glycine: var(--purple-glycine-950-100);
  --background-contrast-purple-glycine-hover: var(--purple-glycine-950-100-hover);
  --background-contrast-purple-glycine-active: var(--purple-glycine-950-100-active);
  --background-contrast-pink-macaron: var(--pink-macaron-950-100);
  --background-contrast-pink-macaron-hover: var(--pink-macaron-950-100-hover);
  --background-contrast-pink-macaron-active: var(--pink-macaron-950-100-active);
  --background-contrast-pink-tuile: var(--pink-tuile-950-100);
  --background-contrast-pink-tuile-hover: var(--pink-tuile-950-100-hover);
  --background-contrast-pink-tuile-active: var(--pink-tuile-950-100-active);
  --background-contrast-yellow-tournesol: var(--yellow-tournesol-950-100);
  --background-contrast-yellow-tournesol-hover: var(--yellow-tournesol-950-100-hover);
  --background-contrast-yellow-tournesol-active: var(--yellow-tournesol-950-100-active);
  --background-contrast-yellow-moutarde: var(--yellow-moutarde-950-100);
  --background-contrast-yellow-moutarde-hover: var(--yellow-moutarde-950-100-hover);
  --background-contrast-yellow-moutarde-active: var(--yellow-moutarde-950-100-active);
  --background-contrast-orange-terre-battue: var(--orange-terre-battue-950-100);
  --background-contrast-orange-terre-battue-hover: var(--orange-terre-battue-950-100-hover);
  --background-contrast-orange-terre-battue-active: var(--orange-terre-battue-950-100-active);
  --background-contrast-brown-cafe-creme: var(--brown-cafe-creme-950-100);
  --background-contrast-brown-cafe-creme-hover: var(--brown-cafe-creme-950-100-hover);
  --background-contrast-brown-cafe-creme-active: var(--brown-cafe-creme-950-100-active);
  --background-contrast-brown-caramel: var(--brown-caramel-950-100);
  --background-contrast-brown-caramel-hover: var(--brown-caramel-950-100-hover);
  --background-contrast-brown-caramel-active: var(--brown-caramel-950-100-active);
  --background-contrast-brown-opera: var(--brown-opera-950-100);
  --background-contrast-brown-opera-hover: var(--brown-opera-950-100-hover);
  --background-contrast-brown-opera-active: var(--brown-opera-950-100-active);
  --background-contrast-beige-gris-galet: var(--beige-gris-galet-950-100);
  --background-contrast-beige-gris-galet-hover: var(--beige-gris-galet-950-100-hover);
  --background-contrast-beige-gris-galet-active: var(--beige-gris-galet-950-100-active);
  --background-contrast-info: var(--info-950-100);
  --background-contrast-info-hover: var(--info-950-100-hover);
  --background-contrast-info-active: var(--info-950-100-active);
  --background-contrast-success: var(--success-950-100);
  --background-contrast-success-hover: var(--success-950-100-hover);
  --background-contrast-success-active: var(--success-950-100-active);
  --background-contrast-warning: var(--warning-950-100);
  --background-contrast-warning-hover: var(--warning-950-100-hover);
  --background-contrast-warning-active: var(--warning-950-100-active);
  --background-contrast-error: var(--error-950-100);
  --background-contrast-error-hover: var(--error-950-100-hover);
  --background-contrast-error-active: var(--error-950-100-active);
  --background-flat-grey: var(--grey-200-850);
  --background-flat-info: var(--info-425-625);
  --background-flat-success: var(--success-425-625);
  --background-flat-warning: var(--warning-425-625);
  --background-flat-error: var(--error-425-625);
  --background-action-high-blue-france: var(--blue-france-sun-113-625);
  --background-action-high-blue-france-hover: var(--blue-france-sun-113-625-hover);
  --background-action-high-blue-france-active: var(--blue-france-sun-113-625-active);
  --background-action-high-red-marianne: var(--red-marianne-425-625);
  --background-action-high-red-marianne-hover: var(--red-marianne-425-625-hover);
  --background-action-high-red-marianne-active: var(--red-marianne-425-625-active);
  --background-action-high-green-tilleul-verveine: var(--green-tilleul-verveine-sun-418-moon-817);
  --background-action-high-green-tilleul-verveine-hover: var(--green-tilleul-verveine-sun-418-moon-817-hover);
  --background-action-high-green-tilleul-verveine-active: var(--green-tilleul-verveine-sun-418-moon-817-active);
  --background-action-high-green-bourgeon: var(--green-bourgeon-sun-425-moon-759);
  --background-action-high-green-bourgeon-hover: var(--green-bourgeon-sun-425-moon-759-hover);
  --background-action-high-green-bourgeon-active: var(--green-bourgeon-sun-425-moon-759-active);
  --background-action-high-green-emeraude: var(--green-emeraude-sun-425-moon-753);
  --background-action-high-green-emeraude-hover: var(--green-emeraude-sun-425-moon-753-hover);
  --background-action-high-green-emeraude-active: var(--green-emeraude-sun-425-moon-753-active);
  --background-action-high-green-menthe: var(--green-menthe-sun-373-moon-652);
  --background-action-high-green-menthe-hover: var(--green-menthe-sun-373-moon-652-hover);
  --background-action-high-green-menthe-active: var(--green-menthe-sun-373-moon-652-active);
  --background-action-high-green-archipel: var(--green-archipel-sun-391-moon-716);
  --background-action-high-green-archipel-hover: var(--green-archipel-sun-391-moon-716-hover);
  --background-action-high-green-archipel-active: var(--green-archipel-sun-391-moon-716-active);
  --background-action-high-blue-ecume: var(--blue-ecume-sun-247-moon-675);
  --background-action-high-blue-ecume-hover: var(--blue-ecume-sun-247-moon-675-hover);
  --background-action-high-blue-ecume-active: var(--blue-ecume-sun-247-moon-675-active);
  --background-action-high-blue-cumulus: var(--blue-cumulus-sun-368-moon-732);
  --background-action-high-blue-cumulus-hover: var(--blue-cumulus-sun-368-moon-732-hover);
  --background-action-high-blue-cumulus-active: var(--blue-cumulus-sun-368-moon-732-active);
  --background-action-high-purple-glycine: var(--purple-glycine-sun-319-moon-630);
  --background-action-high-purple-glycine-hover: var(--purple-glycine-sun-319-moon-630-hover);
  --background-action-high-purple-glycine-active: var(--purple-glycine-sun-319-moon-630-active);
  --background-action-high-pink-macaron: var(--pink-macaron-sun-406-moon-833);
  --background-action-high-pink-macaron-hover: var(--pink-macaron-sun-406-moon-833-hover);
  --background-action-high-pink-macaron-active: var(--pink-macaron-sun-406-moon-833-active);
  --background-action-high-pink-tuile: var(--pink-tuile-sun-425-moon-750);
  --background-action-high-pink-tuile-hover: var(--pink-tuile-sun-425-moon-750-hover);
  --background-action-high-pink-tuile-active: var(--pink-tuile-sun-425-moon-750-active);
  --background-action-high-yellow-tournesol: var(--yellow-tournesol-sun-407-moon-922);
  --background-action-high-yellow-tournesol-hover: var(--yellow-tournesol-sun-407-moon-922-hover);
  --background-action-high-yellow-tournesol-active: var(--yellow-tournesol-sun-407-moon-922-active);
  --background-action-high-yellow-moutarde: var(--yellow-moutarde-sun-348-moon-860);
  --background-action-high-yellow-moutarde-hover: var(--yellow-moutarde-sun-348-moon-860-hover);
  --background-action-high-yellow-moutarde-active: var(--yellow-moutarde-sun-348-moon-860-active);
  --background-action-high-orange-terre-battue: var(--orange-terre-battue-sun-370-moon-672);
  --background-action-high-orange-terre-battue-hover: var(--orange-terre-battue-sun-370-moon-672-hover);
  --background-action-high-orange-terre-battue-active: var(--orange-terre-battue-sun-370-moon-672-active);
  --background-action-high-brown-cafe-creme: var(--brown-cafe-creme-sun-383-moon-885);
  --background-action-high-brown-cafe-creme-hover: var(--brown-cafe-creme-sun-383-moon-885-hover);
  --background-action-high-brown-cafe-creme-active: var(--brown-cafe-creme-sun-383-moon-885-active);
  --background-action-high-brown-caramel: var(--brown-caramel-sun-425-moon-901);
  --background-action-high-brown-caramel-hover: var(--brown-caramel-sun-425-moon-901-hover);
  --background-action-high-brown-caramel-active: var(--brown-caramel-sun-425-moon-901-active);
  --background-action-high-brown-opera: var(--brown-opera-sun-395-moon-820);
  --background-action-high-brown-opera-hover: var(--brown-opera-sun-395-moon-820-hover);
  --background-action-high-brown-opera-active: var(--brown-opera-sun-395-moon-820-active);
  --background-action-high-beige-gris-galet: var(--beige-gris-galet-sun-407-moon-821);
  --background-action-high-beige-gris-galet-hover: var(--beige-gris-galet-sun-407-moon-821-hover);
  --background-action-high-beige-gris-galet-active: var(--beige-gris-galet-sun-407-moon-821-active);
  --background-action-high-info: var(--info-425-625);
  --background-action-high-info-hover: var(--info-425-625-hover);
  --background-action-high-info-active: var(--info-425-625-active);
  --background-action-high-success: var(--success-425-625);
  --background-action-high-success-hover: var(--success-425-625-hover);
  --background-action-high-success-active: var(--success-425-625-active);
  --background-action-high-warning: var(--warning-425-625);
  --background-action-high-warning-hover: var(--warning-425-625-hover);
  --background-action-high-warning-active: var(--warning-425-625-active);
  --background-action-high-error: var(--error-425-625);
  --background-action-high-error-hover: var(--error-425-625-hover);
  --background-action-high-error-active: var(--error-425-625-active);
  --background-action-low-blue-france: var(--blue-france-925-125);
  --background-action-low-blue-france-hover: var(--blue-france-925-125-hover);
  --background-action-low-blue-france-active: var(--blue-france-925-125-active);
  --background-action-low-red-marianne: var(--red-marianne-925-125);
  --background-action-low-red-marianne-hover: var(--red-marianne-925-125-hover);
  --background-action-low-red-marianne-active: var(--red-marianne-925-125-active);
  --background-action-low-green-tilleul-verveine: var(--green-tilleul-verveine-925-125);
  --background-action-low-green-tilleul-verveine-hover: var(--green-tilleul-verveine-925-125-hover);
  --background-action-low-green-tilleul-verveine-active: var(--green-tilleul-verveine-925-125-active);
  --background-action-low-green-bourgeon: var(--green-bourgeon-925-125);
  --background-action-low-green-bourgeon-hover: var(--green-bourgeon-925-125-hover);
  --background-action-low-green-bourgeon-active: var(--green-bourgeon-925-125-active);
  --background-action-low-green-emeraude: var(--green-emeraude-925-125);
  --background-action-low-green-emeraude-hover: var(--green-emeraude-925-125-hover);
  --background-action-low-green-emeraude-active: var(--green-emeraude-925-125-active);
  --background-action-low-green-menthe: var(--green-menthe-925-125);
  --background-action-low-green-menthe-hover: var(--green-menthe-925-125-hover);
  --background-action-low-green-menthe-active: var(--green-menthe-925-125-active);
  --background-action-low-green-archipel: var(--green-archipel-925-125);
  --background-action-low-green-archipel-hover: var(--green-archipel-925-125-hover);
  --background-action-low-green-archipel-active: var(--green-archipel-925-125-active);
  --background-action-low-blue-ecume: var(--blue-ecume-925-125);
  --background-action-low-blue-ecume-hover: var(--blue-ecume-925-125-hover);
  --background-action-low-blue-ecume-active: var(--blue-ecume-925-125-active);
  --background-action-low-blue-cumulus: var(--blue-cumulus-925-125);
  --background-action-low-blue-cumulus-hover: var(--blue-cumulus-925-125-hover);
  --background-action-low-blue-cumulus-active: var(--blue-cumulus-925-125-active);
  --background-action-low-purple-glycine: var(--purple-glycine-925-125);
  --background-action-low-purple-glycine-hover: var(--purple-glycine-925-125-hover);
  --background-action-low-purple-glycine-active: var(--purple-glycine-925-125-active);
  --background-action-low-pink-macaron: var(--pink-macaron-925-125);
  --background-action-low-pink-macaron-hover: var(--pink-macaron-925-125-hover);
  --background-action-low-pink-macaron-active: var(--pink-macaron-925-125-active);
  --background-action-low-pink-tuile: var(--pink-tuile-925-125);
  --background-action-low-pink-tuile-hover: var(--pink-tuile-925-125-hover);
  --background-action-low-pink-tuile-active: var(--pink-tuile-925-125-active);
  --background-action-low-yellow-tournesol: var(--yellow-tournesol-925-125);
  --background-action-low-yellow-tournesol-hover: var(--yellow-tournesol-925-125-hover);
  --background-action-low-yellow-tournesol-active: var(--yellow-tournesol-925-125-active);
  --background-action-low-yellow-moutarde: var(--yellow-moutarde-925-125);
  --background-action-low-yellow-moutarde-hover: var(--yellow-moutarde-925-125-hover);
  --background-action-low-yellow-moutarde-active: var(--yellow-moutarde-925-125-active);
  --background-action-low-orange-terre-battue: var(--orange-terre-battue-925-125);
  --background-action-low-orange-terre-battue-hover: var(--orange-terre-battue-925-125-hover);
  --background-action-low-orange-terre-battue-active: var(--orange-terre-battue-925-125-active);
  --background-action-low-brown-cafe-creme: var(--brown-cafe-creme-925-125);
  --background-action-low-brown-cafe-creme-hover: var(--brown-cafe-creme-925-125-hover);
  --background-action-low-brown-cafe-creme-active: var(--brown-cafe-creme-925-125-active);
  --background-action-low-brown-caramel: var(--brown-caramel-925-125);
  --background-action-low-brown-caramel-hover: var(--brown-caramel-925-125-hover);
  --background-action-low-brown-caramel-active: var(--brown-caramel-925-125-active);
  --background-action-low-brown-opera: var(--brown-opera-925-125);
  --background-action-low-brown-opera-hover: var(--brown-opera-925-125-hover);
  --background-action-low-brown-opera-active: var(--brown-opera-925-125-active);
  --background-action-low-beige-gris-galet: var(--beige-gris-galet-925-125);
  --background-action-low-beige-gris-galet-hover: var(--beige-gris-galet-925-125-hover);
  --background-action-low-beige-gris-galet-active: var(--beige-gris-galet-925-125-active);
  --background-active-blue-france: var(--blue-france-sun-113-625);
  --background-active-blue-france-hover: var(--blue-france-sun-113-625-hover);
  --background-active-blue-france-active: var(--blue-france-sun-113-625-active);
  --background-active-red-marianne: var(--red-marianne-425-625);
  --background-active-red-marianne-hover: var(--red-marianne-425-625-hover);
  --background-active-red-marianne-active: var(--red-marianne-425-625-active);
  --background-open-blue-france: var(--blue-france-925-125);
  --background-open-blue-france-hover: var(--blue-france-925-125-hover);
  --background-open-blue-france-active: var(--blue-france-925-125-active);
  --background-open-red-marianne: var(--red-marianne-925-125);
  --background-open-red-marianne-hover: var(--red-marianne-925-125-hover);
  --background-open-red-marianne-active: var(--red-marianne-925-125-active);
  --background-disabled-grey: var(--grey-925-125);
  --background-overlay-grey: var(--grey-50-1000-a375);
  --text-default-grey: var(--grey-200-850);
  --text-default-info: var(--info-425-625);
  --text-default-success: var(--success-425-625);
  --text-default-warning: var(--warning-425-625);
  --text-default-error: var(--error-425-625);
  --text-action-high-grey: var(--grey-50-1000);
  --text-action-high-blue-france: var(--blue-france-sun-113-625);
  --text-action-high-red-marianne: var(--red-marianne-425-625);
  --text-action-high-green-tilleul-verveine: var(--green-tilleul-verveine-sun-418-moon-817);
  --text-action-high-green-bourgeon: var(--green-bourgeon-sun-425-moon-759);
  --text-action-high-green-emeraude: var(--green-emeraude-sun-425-moon-753);
  --text-action-high-green-menthe: var(--green-menthe-sun-373-moon-652);
  --text-action-high-green-archipel: var(--green-archipel-sun-391-moon-716);
  --text-action-high-blue-ecume: var(--blue-ecume-sun-247-moon-675);
  --text-action-high-blue-cumulus: var(--blue-cumulus-sun-368-moon-732);
  --text-action-high-purple-glycine: var(--purple-glycine-sun-319-moon-630);
  --text-action-high-pink-macaron: var(--pink-macaron-sun-406-moon-833);
  --text-action-high-pink-tuile: var(--pink-tuile-sun-425-moon-750);
  --text-action-high-yellow-tournesol: var(--yellow-tournesol-sun-407-moon-922);
  --text-action-high-yellow-moutarde: var(--yellow-moutarde-sun-348-moon-860);
  --text-action-high-orange-terre-battue: var(--orange-terre-battue-sun-370-moon-672);
  --text-action-high-brown-cafe-creme: var(--brown-cafe-creme-sun-383-moon-885);
  --text-action-high-brown-caramel: var(--brown-caramel-sun-425-moon-901);
  --text-action-high-brown-opera: var(--brown-opera-sun-395-moon-820);
  --text-action-high-beige-gris-galet: var(--beige-gris-galet-sun-407-moon-821);
  --text-title-grey: var(--grey-50-1000);
  --text-title-blue-france: var(--blue-france-sun-113-625);
  --text-title-red-marianne: var(--red-marianne-425-625);
  --text-label-grey: var(--grey-50-1000);
  --text-label-blue-france: var(--blue-france-sun-113-625);
  --text-label-red-marianne: var(--red-marianne-425-625);
  --text-label-green-tilleul-verveine: var(--green-tilleul-verveine-sun-418-moon-817);
  --text-label-green-bourgeon: var(--green-bourgeon-sun-425-moon-759);
  --text-label-green-emeraude: var(--green-emeraude-sun-425-moon-753);
  --text-label-green-menthe: var(--green-menthe-sun-373-moon-652);
  --text-label-green-archipel: var(--green-archipel-sun-391-moon-716);
  --text-label-blue-ecume: var(--blue-ecume-sun-247-moon-675);
  --text-label-blue-cumulus: var(--blue-cumulus-sun-368-moon-732);
  --text-label-purple-glycine: var(--purple-glycine-sun-319-moon-630);
  --text-label-pink-macaron: var(--pink-macaron-sun-406-moon-833);
  --text-label-pink-tuile: var(--pink-tuile-sun-425-moon-750);
  --text-label-yellow-tournesol: var(--yellow-tournesol-sun-407-moon-922);
  --text-label-yellow-moutarde: var(--yellow-moutarde-sun-348-moon-860);
  --text-label-orange-terre-battue: var(--orange-terre-battue-sun-370-moon-672);
  --text-label-brown-cafe-creme: var(--brown-cafe-creme-sun-383-moon-885);
  --text-label-brown-caramel: var(--brown-caramel-sun-425-moon-901);
  --text-label-brown-opera: var(--brown-opera-sun-395-moon-820);
  --text-label-beige-gris-galet: var(--beige-gris-galet-sun-407-moon-821);
  --text-active-grey: var(--grey-50-1000);
  --text-active-blue-france: var(--blue-france-sun-113-625);
  --text-active-red-marianne: var(--red-marianne-425-625);
  --text-mention-grey: var(--grey-425-625);
  --text-inverted-grey: var(--grey-1000-50);
  --text-inverted-blue-france: var(--blue-france-975-sun-113);
  --text-inverted-red-marianne: var(--red-marianne-975-75);
  --text-inverted-info: var(--info-975-75);
  --text-inverted-success: var(--success-975-75);
  --text-inverted-warning: var(--warning-975-75);
  --text-inverted-error: var(--error-975-75);
  --text-inverted-green-tilleul-verveine: var(--green-tilleul-verveine-975-75);
  --text-inverted-green-bourgeon: var(--green-bourgeon-975-75);
  --text-inverted-green-emeraude: var(--green-emeraude-975-75);
  --text-inverted-green-menthe: var(--green-menthe-975-75);
  --text-inverted-green-archipel: var(--green-archipel-975-75);
  --text-inverted-blue-ecume: var(--blue-ecume-975-75);
  --text-inverted-blue-cumulus: var(--blue-cumulus-975-75);
  --text-inverted-purple-glycine: var(--purple-glycine-975-75);
  --text-inverted-pink-macaron: var(--pink-macaron-975-75);
  --text-inverted-pink-tuile: var(--pink-tuile-975-75);
  --text-inverted-yellow-tournesol: var(--yellow-tournesol-975-75);
  --text-inverted-yellow-moutarde: var(--yellow-moutarde-975-75);
  --text-inverted-orange-terre-battue: var(--orange-terre-battue-975-75);
  --text-inverted-brown-cafe-creme: var(--brown-cafe-creme-975-75);
  --text-inverted-brown-caramel: var(--brown-caramel-975-75);
  --text-inverted-brown-opera: var(--brown-opera-975-75);
  --text-inverted-beige-gris-galet: var(--beige-gris-galet-975-75);
  --text-disabled-grey: var(--grey-625-425);
  --border-default-grey: var(--grey-925-125);
  --border-default-blue-france: var(--blue-france-main-525);
  --border-default-red-marianne: var(--red-marianne-main-472);
  --border-default-green-tilleul-verveine: var(--green-tilleul-verveine-main-707);
  --border-default-green-bourgeon: var(--green-bourgeon-main-640);
  --border-default-green-emeraude: var(--green-emeraude-main-632);
  --border-default-green-menthe: var(--green-menthe-main-548);
  --border-default-green-archipel: var(--green-archipel-main-557);
  --border-default-blue-ecume: var(--blue-ecume-main-400);
  --border-default-blue-cumulus: var(--blue-cumulus-main-526);
  --border-default-purple-glycine: var(--purple-glycine-main-494);
  --border-default-pink-macaron: var(--pink-macaron-main-689);
  --border-default-pink-tuile: var(--pink-tuile-main-556);
  --border-default-yellow-tournesol: var(--yellow-tournesol-main-731);
  --border-default-yellow-moutarde: var(--yellow-moutarde-main-679);
  --border-default-orange-terre-battue: var(--orange-terre-battue-main-645);
  --border-default-brown-cafe-creme: var(--brown-cafe-creme-main-782);
  --border-default-brown-caramel: var(--brown-caramel-main-648);
  --border-default-brown-opera: var(--brown-opera-main-680);
  --border-default-beige-gris-galet: var(--beige-gris-galet-main-702);
  --border-active-blue-france: var(--blue-france-sun-113-625);
  --border-active-red-marianne: var(--red-marianne-425-625);
  --border-action-high-grey: var(--grey-50-1000);
  --border-action-high-blue-france: var(--blue-france-sun-113-625);
  --border-action-high-red-marianne: var(--red-marianne-425-625);
  --border-action-high-info: var(--info-425-625);
  --border-action-high-success: var(--success-425-625);
  --border-action-high-warning: var(--warning-425-625);
  --border-action-high-error: var(--error-425-625);
  --border-action-low-blue-france: var(--blue-france-850-200);
  --border-action-low-red-marianne: var(--red-marianne-850-200);
  --border-action-low-green-tilleul-verveine: var(--green-tilleul-verveine-850-200);
  --border-action-low-green-bourgeon: var(--green-bourgeon-850-200);
  --border-action-low-green-emeraude: var(--green-emeraude-850-200);
  --border-action-low-green-menthe: var(--green-menthe-850-200);
  --border-action-low-green-archipel: var(--green-archipel-850-200);
  --border-action-low-blue-ecume: var(--blue-ecume-850-200);
  --border-action-low-blue-cumulus: var(--blue-cumulus-850-200);
  --border-action-low-purple-glycine: var(--purple-glycine-850-200);
  --border-action-low-pink-macaron: var(--pink-macaron-850-200);
  --border-action-low-pink-tuile: var(--pink-tuile-850-200);
  --border-action-low-yellow-tournesol: var(--yellow-tournesol-850-200);
  --border-action-low-yellow-moutarde: var(--yellow-moutarde-850-200);
  --border-action-low-orange-terre-battue: var(--orange-terre-battue-850-200);
  --border-action-low-brown-cafe-creme: var(--brown-cafe-creme-850-200);
  --border-action-low-brown-caramel: var(--brown-caramel-850-200);
  --border-action-low-brown-opera: var(--brown-opera-850-200);
  --border-action-low-beige-gris-galet: var(--beige-gris-galet-850-200);
  --border-open-blue-france: var(--blue-france-925-125);
  --border-open-red-marianne: var(--red-marianne-925-125);
  --border-plain-grey: var(--grey-200-850);
  --border-plain-blue-france: var(--blue-france-sun-113-625);
  --border-plain-red-marianne: var(--red-marianne-425-625);
  --border-plain-info: var(--info-425-625);
  --border-plain-success: var(--success-425-625);
  --border-plain-warning: var(--warning-425-625);
  --border-plain-error: var(--error-425-625);
  --border-plain-green-tilleul-verveine: var(--green-tilleul-verveine-sun-418-moon-817);
  --border-plain-green-bourgeon: var(--green-bourgeon-sun-425-moon-759);
  --border-plain-green-emeraude: var(--green-emeraude-sun-425-moon-753);
  --border-plain-green-menthe: var(--green-menthe-sun-373-moon-652);
  --border-plain-green-archipel: var(--green-archipel-sun-391-moon-716);
  --border-plain-blue-ecume: var(--blue-ecume-sun-247-moon-675);
  --border-plain-blue-cumulus: var(--blue-cumulus-sun-368-moon-732);
  --border-plain-purple-glycine: var(--purple-glycine-sun-319-moon-630);
  --border-plain-pink-macaron: var(--pink-macaron-sun-406-moon-833);
  --border-plain-pink-tuile: var(--pink-tuile-sun-425-moon-750);
  --border-plain-yellow-tournesol: var(--yellow-tournesol-sun-407-moon-922);
  --border-plain-yellow-moutarde: var(--yellow-moutarde-sun-348-moon-860);
  --border-plain-orange-terre-battue: var(--orange-terre-battue-sun-370-moon-672);
  --border-plain-brown-cafe-creme: var(--brown-cafe-creme-sun-383-moon-885);
  --border-plain-brown-caramel: var(--brown-caramel-sun-425-moon-901);
  --border-plain-brown-opera: var(--brown-opera-sun-395-moon-820);
  --border-plain-beige-gris-galet: var(--beige-gris-galet-sun-407-moon-821);
  --border-disabled-grey: var(--grey-925-125);
  --artwork-major-blue-france: var(--blue-france-sun-113-625);
  --artwork-major-red-marianne: var(--red-marianne-425-625);
  --artwork-minor-blue-france: var(--blue-france-main-525);
  --artwork-minor-red-marianne: var(--red-marianne-main-472);
  --artwork-minor-green-tilleul-verveine: var(--green-tilleul-verveine-main-707);
  --artwork-minor-green-bourgeon: var(--green-bourgeon-main-640);
  --artwork-minor-green-emeraude: var(--green-emeraude-main-632);
  --artwork-minor-green-menthe: var(--green-menthe-main-548);
  --artwork-minor-green-archipel: var(--green-archipel-main-557);
  --artwork-minor-blue-ecume: var(--blue-ecume-main-400);
  --artwork-minor-blue-cumulus: var(--blue-cumulus-main-526);
  --artwork-minor-purple-glycine: var(--purple-glycine-main-494);
  --artwork-minor-pink-macaron: var(--pink-macaron-main-689);
  --artwork-minor-pink-tuile: var(--pink-tuile-main-556);
  --artwork-minor-yellow-tournesol: var(--yellow-tournesol-main-731);
  --artwork-minor-yellow-moutarde: var(--yellow-moutarde-main-679);
  --artwork-minor-orange-terre-battue: var(--orange-terre-battue-main-645);
  --artwork-minor-brown-cafe-creme: var(--brown-cafe-creme-main-782);
  --artwork-minor-brown-caramel: var(--brown-caramel-main-648);
  --artwork-minor-brown-opera: var(--brown-opera-main-680);
  --artwork-minor-beige-gris-galet: var(--beige-gris-galet-main-702);
  --artwork-decorative-blue-france: var(--blue-france-950-100);
  --artwork-decorative-red-marianne: var(--red-marianne-950-100);
  --grey-1000-50: #fff;
  --grey-1000-50-hover: #f6f6f6;
  --grey-1000-50-active: #ededed;
  --grey-1000-75: #fff;
  --grey-1000-75-hover: #f6f6f6;
  --grey-1000-75-active: #ededed;
  --grey-975-75: #f6f6f6;
  --grey-975-75-hover: #dfdfdf;
  --grey-975-75-active: #cfcfcf;
  --grey-950-100: #eee;
  --grey-950-100-hover: #d2d2d2;
  --grey-950-100-active: #c1c1c1;
  --grey-200-850: #3a3a3a;
  --grey-925-125: #e5e5e5;
  --grey-50-1000-a375: rgba(22, 22, 22, 0.375);
  --grey-50-1000: #161616;
  --grey-425-625: #666;
  --grey-625-425: #929292;
  --blue-france-975-75: #f5f5fe;
  --blue-france-975-75-hover: #dcdcfc;
  --blue-france-975-75-active: #cbcbfa;
  --blue-france-950-100: #ececfe;
  --blue-france-950-100-hover: #cecefc;
  --blue-france-950-100-active: #bbbbfc;
  --blue-france-sun-113-625: #000091;
  --blue-france-sun-113-625-hover: #1212ff;
  --blue-france-sun-113-625-active: #2323ff;
  --blue-france-925-125: #e3e3fd;
  --blue-france-925-125-hover: #c1c1fb;
  --blue-france-925-125-active: #adadf9;
  --blue-france-975-sun-113: #f5f5fe;
  --blue-france-main-525: #6a6af4;
  --blue-france-850-200: #cacafb;
  --red-marianne-975-75: #fef4f4;
  --red-marianne-975-75-hover: #fcd7d7;
  --red-marianne-975-75-active: #fac4c4;
  --red-marianne-950-100: #fee9e9;
  --red-marianne-950-100-hover: #fdc5c5;
  --red-marianne-950-100-active: #fcafaf;
  --red-marianne-425-625: #c9191e;
  --red-marianne-425-625-hover: #f93f42;
  --red-marianne-425-625-active: #f95a5c;
  --red-marianne-925-125: #fddede;
  --red-marianne-925-125-hover: #fbb6b6;
  --red-marianne-925-125-active: #fa9e9e;
  --red-marianne-main-472: #e1000f;
  --red-marianne-850-200: #fcbfbf;
  --info-950-100: #e8edff;
  --info-950-100-hover: #c2d1ff;
  --info-950-100-active: #a9bfff;
  --info-425-625: #0063cb;
  --info-425-625-hover: #3b87ff;
  --info-425-625-active: #6798ff;
  --info-975-75: #f4f6ff;
  --success-950-100: #b8fec9;
  --success-950-100-hover: #46fd89;
  --success-950-100-active: #34eb7b;
  --success-425-625: #18753c;
  --success-425-625-hover: #27a959;
  --success-425-625-active: #2fc368;
  --success-975-75: #dffee6;
  --warning-950-100: #ffe9e6;
  --warning-950-100-hover: #ffc6bd;
  --warning-950-100-active: #ffb0a2;
  --warning-425-625: #b34000;
  --warning-425-625-hover: #ff6218;
  --warning-425-625-active: #ff7a55;
  --warning-975-75: #fff4f3;
  --error-950-100: #ffe9e9;
  --error-950-100-hover: #ffc5c5;
  --error-950-100-active: #ffafaf;
  --error-425-625: #ce0500;
  --error-425-625-hover: #ff2725;
  --error-425-625-active: #ff4140;
  --error-975-75: #fff4f4;
  --green-tilleul-verveine-975-75: #fef7da;
  --green-tilleul-verveine-975-75-hover: #fce552;
  --green-tilleul-verveine-975-75-active: #ebd54c;
  --green-tilleul-verveine-950-100: #fceeac;
  --green-tilleul-verveine-950-100-hover: #e8d45c;
  --green-tilleul-verveine-950-100-active: #d4c254;
  --green-tilleul-verveine-sun-418-moon-817: #66673d;
  --green-tilleul-verveine-sun-418-moon-817-hover: #929359;
  --green-tilleul-verveine-sun-418-moon-817-active: #a7a967;
  --green-tilleul-verveine-925-125: #fbe769;
  --green-tilleul-verveine-925-125-hover: #d7c655;
  --green-tilleul-verveine-925-125-active: #c2b24c;
  --green-tilleul-verveine-main-707: #b7a73f;
  --green-tilleul-verveine-850-200: #e2cf58;
  --green-bourgeon-975-75: #e6feda;
  --green-bourgeon-975-75-hover: #a7fc62;
  --green-bourgeon-975-75-active: #98ed4d;
  --green-bourgeon-950-100: #c9fcac;
  --green-bourgeon-950-100-hover: #9ae95d;
  --green-bourgeon-950-100-active: #8dd555;
  --green-bourgeon-sun-425-moon-759: #447049;
  --green-bourgeon-sun-425-moon-759-hover: #639f6a;
  --green-bourgeon-sun-425-moon-759-active: #72b77a;
  --green-bourgeon-925-125: #a9fb68;
  --green-bourgeon-925-125-hover: #8ed654;
  --green-bourgeon-925-125-active: #7fc04b;
  --green-bourgeon-main-640: #68a532;
  --green-bourgeon-850-200: #95e257;
  --green-emeraude-975-75: #e3fdeb;
  --green-emeraude-975-75-hover: #94f9b9;
  --green-emeraude-975-75-active: #6df1a3;
  --green-emeraude-950-100: #c3fad5;
  --green-emeraude-950-100-hover: #77eda5;
  --green-emeraude-950-100-active: #6dd897;
  --green-emeraude-sun-425-moon-753: #297254;
  --green-emeraude-sun-425-moon-753-hover: #3ea47a;
  --green-emeraude-sun-425-moon-753-active: #49bc8d;
  --green-emeraude-925-125: #9ef9be;
  --green-emeraude-925-125-hover: #69df97;
  --green-emeraude-925-125-active: #5ec988;
  --green-emeraude-main-632: #00a95f;
  --green-emeraude-850-200: #6fe49d;
  --green-menthe-975-75: #dffdf7;
  --green-menthe-975-75-hover: #84f9e7;
  --green-menthe-975-75-active: #70ebd8;
  --green-menthe-950-100: #bafaee;
  --green-menthe-950-100-hover: #79e7d5;
  --green-menthe-950-100-active: #6fd3c3;
  --green-menthe-sun-373-moon-652: #37635f;
  --green-menthe-sun-373-moon-652-hover: #53918c;
  --green-menthe-sun-373-moon-652-active: #62a9a2;
  --green-menthe-925-125: #8bf8e7;
  --green-menthe-925-125-hover: #6ed5c5;
  --green-menthe-925-125-active: #62bfb1;
  --green-menthe-main-548: #009081;
  --green-menthe-850-200: #73e0cf;
  --green-archipel-975-75: #e5fbfd;
  --green-archipel-975-75-hover: #99f2f8;
  --green-archipel-975-75-active: #73e9f0;
  --green-archipel-950-100: #c7f6fc;
  --green-archipel-950-100-hover: #64ecf8;
  --green-archipel-950-100-active: #5bd8e3;
  --green-archipel-sun-391-moon-716: #006a6f;
  --green-archipel-sun-391-moon-716-hover: #009fa7;
  --green-archipel-sun-391-moon-716-active: #00bbc3;
  --green-archipel-925-125: #a6f2fa;
  --green-archipel-925-125-hover: #62dbe5;
  --green-archipel-925-125-active: #58c5cf;
  --green-archipel-main-557: #009099;
  --green-archipel-850-200: #60e0eb;
  --blue-ecume-975-75: #f4f6fe;
  --blue-ecume-975-75-hover: #d7dffb;
  --blue-ecume-975-75-active: #c3cffa;
  --blue-ecume-950-100: #e9edfe;
  --blue-ecume-950-100-hover: #c5d0fc;
  --blue-ecume-950-100-active: #adbffc;
  --blue-ecume-sun-247-moon-675: #2f4077;
  --blue-ecume-sun-247-moon-675-hover: #4e68bb;
  --blue-ecume-sun-247-moon-675-active: #667dcf;
  --blue-ecume-925-125: #dee5fd;
  --blue-ecume-925-125-hover: #b4c5fb;
  --blue-ecume-925-125-active: #99b3f9;
  --blue-ecume-main-400: #465f9d;
  --blue-ecume-850-200: #bfccfb;
  --blue-cumulus-975-75: #f3f6fe;
  --blue-cumulus-975-75-hover: #d3dffc;
  --blue-cumulus-975-75-active: #bed0fa;
  --blue-cumulus-950-100: #e6eefe;
  --blue-cumulus-950-100-hover: #bcd3fc;
  --blue-cumulus-950-100-active: #9fc3fc;
  --blue-cumulus-sun-368-moon-732: #3558a2;
  --blue-cumulus-sun-368-moon-732-hover: #5982e0;
  --blue-cumulus-sun-368-moon-732-active: #7996e6;
  --blue-cumulus-925-125: #dae6fd;
  --blue-cumulus-925-125-hover: #a9c8fb;
  --blue-cumulus-925-125-active: #8ab8f9;
  --blue-cumulus-main-526: #417dc4;
  --blue-cumulus-850-200: #b6cffb;
  --purple-glycine-975-75: #fef3fd;
  --purple-glycine-975-75-hover: #fcd4f8;
  --purple-glycine-975-75-active: #fabff5;
  --purple-glycine-950-100: #fee7fc;
  --purple-glycine-950-100-hover: #fdc0f8;
  --purple-glycine-950-100-active: #fca8f6;
  --purple-glycine-sun-319-moon-630: #6e445a;
  --purple-glycine-sun-319-moon-630-hover: #a66989;
  --purple-glycine-sun-319-moon-630-active: #bb7f9e;
  --purple-glycine-925-125: #fddbfa;
  --purple-glycine-925-125-hover: #fbaff5;
  --purple-glycine-925-125-active: #fa96f2;
  --purple-glycine-main-494: #a558a0;
  --purple-glycine-850-200: #fbb8f6;
  --pink-macaron-975-75: #fef4f2;
  --pink-macaron-975-75-hover: #fcd8d0;
  --pink-macaron-975-75-active: #fac5b8;
  --pink-macaron-950-100: #fee9e6;
  --pink-macaron-950-100-hover: #fdc6bd;
  --pink-macaron-950-100-active: #fcb0a2;
  --pink-macaron-sun-406-moon-833: #8d533e;
  --pink-macaron-sun-406-moon-833-hover: #ca795c;
  --pink-macaron-sun-406-moon-833-active: #e08e73;
  --pink-macaron-925-125: #fddfda;
  --pink-macaron-925-125-hover: #fbb8ab;
  --pink-macaron-925-125-active: #faa18d;
  --pink-macaron-main-689: #e18b76;
  --pink-macaron-850-200: #fcc0b4;
  --pink-tuile-975-75: #fef4f3;
  --pink-tuile-975-75-hover: #fcd7d3;
  --pink-tuile-975-75-active: #fac4be;
  --pink-tuile-950-100: #fee9e7;
  --pink-tuile-950-100-hover: #fdc6c0;
  --pink-tuile-950-100-active: #fcb0a7;
  --pink-tuile-sun-425-moon-750: #a94645;
  --pink-tuile-sun-425-moon-750-hover: #d5706f;
  --pink-tuile-sun-425-moon-750-active: #da8a89;
  --pink-tuile-925-125: #fddfdb;
  --pink-tuile-925-125-hover: #fbb8ad;
  --pink-tuile-925-125-active: #faa191;
  --pink-tuile-main-556: #ce614a;
  --pink-tuile-850-200: #fcbfb7;
  --yellow-tournesol-975-75: #fef6e3;
  --yellow-tournesol-975-75-hover: #fce086;
  --yellow-tournesol-975-75-active: #f5d24b;
  --yellow-tournesol-950-100: #feecc2;
  --yellow-tournesol-950-100-hover: #fbd335;
  --yellow-tournesol-950-100-active: #e6c130;
  --yellow-tournesol-sun-407-moon-922: #716043;
  --yellow-tournesol-sun-407-moon-922-hover: #a28a62;
  --yellow-tournesol-sun-407-moon-922-active: #ba9f72;
  --yellow-tournesol-925-125: #fde39c;
  --yellow-tournesol-925-125-hover: #e9c53b;
  --yellow-tournesol-925-125-active: #d3b235;
  --yellow-tournesol-main-731: #c8aa39;
  --yellow-tournesol-850-200: #efcb3a;
  --yellow-moutarde-975-75: #fef5e8;
  --yellow-moutarde-975-75-hover: #fcdca3;
  --yellow-moutarde-975-75-active: #fbcd64;
  --yellow-moutarde-950-100: #feebd0;
  --yellow-moutarde-950-100-hover: #fdcd6d;
  --yellow-moutarde-950-100-active: #f4be30;
  --yellow-moutarde-sun-348-moon-860: #695240;
  --yellow-moutarde-sun-348-moon-860-hover: #9b7b61;
  --yellow-moutarde-sun-348-moon-860-active: #b58f72;
  --yellow-moutarde-925-125: #fde2b5;
  --yellow-moutarde-925-125-hover: #f6c43c;
  --yellow-moutarde-925-125-active: #dfb135;
  --yellow-moutarde-main-679: #c3992a;
  --yellow-moutarde-850-200: #fcc63a;
  --orange-terre-battue-975-75: #fef4f2;
  --orange-terre-battue-975-75-hover: #fcd8d0;
  --orange-terre-battue-975-75-active: #fac5b8;
  --orange-terre-battue-950-100: #fee9e5;
  --orange-terre-battue-950-100-hover: #fdc6ba;
  --orange-terre-battue-950-100-active: #fcb09e;
  --orange-terre-battue-sun-370-moon-672: #755348;
  --orange-terre-battue-sun-370-moon-672-hover: #ab7b6b;
  --orange-terre-battue-sun-370-moon-672-active: #c68f7d;
  --orange-terre-battue-925-125: #fddfd8;
  --orange-terre-battue-925-125-hover: #fbb8a5;
  --orange-terre-battue-925-125-active: #faa184;
  --orange-terre-battue-main-645: #e4794a;
  --orange-terre-battue-850-200: #fcc0b0;
  --brown-cafe-creme-975-75: #fbf6ed;
  --brown-cafe-creme-975-75-hover: #f2deb6;
  --brown-cafe-creme-975-75-active: #eacf91;
  --brown-cafe-creme-950-100: #f7ecdb;
  --brown-cafe-creme-950-100-hover: #edce94;
  --brown-cafe-creme-950-100-active: #dabd84;
  --brown-cafe-creme-sun-383-moon-885: #685c48;
  --brown-cafe-creme-sun-383-moon-885-hover: #97866a;
  --brown-cafe-creme-sun-383-moon-885-active: #ae9b7b;
  --brown-cafe-creme-925-125: #f4e3c7;
  --brown-cafe-creme-925-125-hover: #e1c386;
  --brown-cafe-creme-925-125-active: #ccb078;
  --brown-cafe-creme-main-782: #d1b781;
  --brown-cafe-creme-850-200: #e7ca8e;
  --brown-caramel-975-75: #fbf5f2;
  --brown-caramel-975-75-hover: #f1dbcf;
  --brown-caramel-975-75-active: #ecc9b5;
  --brown-caramel-950-100: #f7ebe5;
  --brown-caramel-950-100-hover: #eccbb9;
  --brown-caramel-950-100-active: #e6b79a;
  --brown-caramel-sun-425-moon-901: #845d48;
  --brown-caramel-sun-425-moon-901-hover: #bb8568;
  --brown-caramel-sun-425-moon-901-active: #d69978;
  --brown-caramel-925-125: #f3e2d9;
  --brown-caramel-925-125-hover: #e7bea6;
  --brown-caramel-925-125-active: #e1a982;
  --brown-caramel-main-648: #c08c65;
  --brown-caramel-850-200: #eac7b2;
  --brown-opera-975-75: #fbf5f2;
  --brown-opera-975-75-hover: #f1dbcf;
  --brown-opera-975-75-active: #ecc9b5;
  --brown-opera-950-100: #f7ece4;
  --brown-opera-950-100-hover: #eccdb3;
  --brown-opera-950-100-active: #e6ba90;
  --brown-opera-sun-395-moon-820: #745b47;
  --brown-opera-sun-395-moon-820-hover: #a78468;
  --brown-opera-sun-395-moon-820-active: #c09979;
  --brown-opera-925-125: #f3e2d7;
  --brown-opera-925-125-hover: #e7bfa0;
  --brown-opera-925-125-active: #deaa7e;
  --brown-opera-main-680: #bd987a;
  --brown-opera-850-200: #eac7ad;
  --beige-gris-galet-975-75: #f9f6f2;
  --beige-gris-galet-975-75-hover: #eadecd;
  --beige-gris-galet-975-75-active: #e1ceb1;
  --beige-gris-galet-950-100: #f3ede5;
  --beige-gris-galet-950-100-hover: #e1d0b5;
  --beige-gris-galet-950-100-active: #d1bea2;
  --beige-gris-galet-sun-407-moon-821: #6a6156;
  --beige-gris-galet-sun-407-moon-821-hover: #988b7c;
  --beige-gris-galet-sun-407-moon-821-active: #afa08f;
  --beige-gris-galet-925-125: #eee4d9;
  --beige-gris-galet-925-125-hover: #dbc3a4;
  --beige-gris-galet-925-125-active: #c6b094;
  --beige-gris-galet-main-702: #aea397;
  --beige-gris-galet-850-200: #e0cab0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  box-sizing: border-box;
}

body {
  font-family: "Marianne", arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  font-size:  1rem ;
  line-height:  1.5rem ;
}

[class^="fr-icon-"], [class*=" fr-icon-"], [class^="fr-fi-"], [class*=" fr-fi-"] {
  --icon-size: 1.5rem;
}

[class^="fr-icon-"]::before, [class*=" fr-icon-"]::before, [class^="fr-fi-"]::before, [class*=" fr-fi-"]::before {
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: middle;
  background-color: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.fr-icon--xs {
  --icon-size: 0.75rem;
}

.fr-icon--sm {
  --icon-size: 1rem;
}

.fr-icon--md {
  --icon-size: 1.5rem;
}

.fr-icon--lg {
  --icon-size: 2rem;
}

.fr-hidden {
  display: none !important;
}

@media (min-width: 36em) {
  .fr-hidden-sm {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .fr-hidden-md {
    display: none !important;
  }
}

@media (min-width: 62em) {
  .fr-hidden-lg {
    display: none !important;
  }
}

@media (min-width: 78em) {
  .fr-hidden-xl {
    display: none !important;
  }
}

.fr-unhidden {
  display: inherit !important;
}

@media (min-width: 36em) {
  .fr-unhidden-sm {
    display: inherit !important;
  }
}

@media (min-width: 48em) {
  .fr-unhidden-md {
    display: inherit !important;
  }
}

@media (min-width: 62em) {
  .fr-unhidden-lg {
    display: inherit !important;
  }
}

@media (min-width: 78em) {
  .fr-unhidden-xl {
    display: inherit !important;
  }
}

/**
* Fixe le scroll en arrière plan
*/
:root[data-fr-scrolling] body {
  --scroll-top: 0;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  top: var(--scroll-top);
}

.fr-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}

@media (min-width: 36em) {
  .fr-sr-only-sm {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
  }
}

@media (min-width: 48em) {
  .fr-sr-only-md {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
  }
}

@media (min-width: 62em) {
  .fr-sr-only-lg {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
  }
}

@media (min-width: 78em) {
  .fr-sr-only-xl {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
  }
}

hr {
  padding: var(--text-spacing);
  margin: 0;
  border: 0;
  display: block;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.fr-hr {
  padding: var(--text-spacing);
  margin: 0;
  border: 0;
  display: block;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.fr-hr--sm {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
}

.fr-ellipsis {
  display: inline !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fr-responsive-img {
  width: 100%;
  height: auto;
}

.fr-responsive-img--32x9 {
  aspect-ratio: 3.55556 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--16x9 {
  aspect-ratio: 1.77778 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--3x2 {
  aspect-ratio: 1.5 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--4x3 {
  aspect-ratio: 1.33333 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--1x1 {
  aspect-ratio: 1 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--3x4 {
  aspect-ratio: 0.75 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-img--2x3 {
  aspect-ratio: 0.66667 !important;
  object-fit: cover;
  object-position: 50% 50%;
}

.fr-responsive-vid {
  position: relative;
  width: 100%;
  aspect-ratio: 1.77778;
}

.fr-responsive-vid--16x9 {
  aspect-ratio: 1.77778 !important;
}

.fr-responsive-vid--4x3 {
  aspect-ratio: 1.33333 !important;
}

.fr-responsive-vid--1x1 {
  aspect-ratio: 1 !important;
}

.fr-responsive-vid__player, .fr-responsive-vid > .fr-consent-placeholder {
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.fr-grid-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.fr-grid-row--top {
  align-items: flex-start;
}

.fr-grid-row--bottom {
  align-items: flex-end;
}

.fr-grid-row--middle {
  align-items: center;
}

.fr-grid-row--left {
  justify-content: flex-start;
}

.fr-grid-row--right {
  justify-content: flex-end;
}

.fr-grid-row--center {
  justify-content: center;
}

.fr-col--top {
  align-self: flex-start;
}

.fr-col--bottom {
  align-self: flex-end;
}

.fr-col--middle {
  align-self: center;
}

.fr-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.fr-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.fr-container--fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: none;
}

.fr-grid-row--gutters {
  margin: -0.5rem;
}

.fr-grid-row--gutters > [class^="fr-col-"], .fr-grid-row--gutters > [class*=" fr-col-"],
.fr-grid-row--gutters > .fr-col {
  padding: 0.5rem;
}

.fr-grid-row--no-gutters {
  margin: 0;
}

.fr-grid-row--no-gutters > [class^="fr-col-"], .fr-grid-row--no-gutters > [class*=" fr-col-"],
.fr-grid-row--no-gutters > .fr-col {
  padding: 0;
}

.fr-col {
  flex: 1;
}

.fr-col-1 {
  flex: 0 0 calc(100% / 12);
  width: calc(100% / 12);
  max-width: calc(100% / 12);
}

.fr-col-offset-1:not(.fr-col-offset-1--right) {
  margin-left: calc(100% / 12);
}

.fr-col-offset-1--right {
  margin-right: calc(100% / 12);
}

.fr-col-2 {
  flex: 0 0 calc(200% / 12);
  width: calc(200% / 12);
  max-width: calc(200% / 12);
}

.fr-col-offset-2:not(.fr-col-offset-1--right) {
  margin-left: calc(200% / 12);
}

.fr-col-offset-2--right {
  margin-right: calc(200% / 12);
}

.fr-col-3 {
  flex: 0 0 calc(300% / 12);
  width: calc(300% / 12);
  max-width: calc(300% / 12);
}

.fr-col-offset-3:not(.fr-col-offset-1--right) {
  margin-left: calc(300% / 12);
}

.fr-col-offset-3--right {
  margin-right: calc(300% / 12);
}

.fr-col-4 {
  flex: 0 0 calc(400% / 12);
  width: calc(400% / 12);
  max-width: calc(400% / 12);
}

.fr-col-offset-4:not(.fr-col-offset-1--right) {
  margin-left: calc(400% / 12);
}

.fr-col-offset-4--right {
  margin-right: calc(400% / 12);
}

.fr-col-5 {
  flex: 0 0 calc(500% / 12);
  width: calc(500% / 12);
  max-width: calc(500% / 12);
}

.fr-col-offset-5:not(.fr-col-offset-1--right) {
  margin-left: calc(500% / 12);
}

.fr-col-offset-5--right {
  margin-right: calc(500% / 12);
}

.fr-col-6 {
  flex: 0 0 calc(600% / 12);
  width: calc(600% / 12);
  max-width: calc(600% / 12);
}

.fr-col-offset-6:not(.fr-col-offset-1--right) {
  margin-left: calc(600% / 12);
}

.fr-col-offset-6--right {
  margin-right: calc(600% / 12);
}

.fr-col-7 {
  flex: 0 0 calc(700% / 12);
  width: calc(700% / 12);
  max-width: calc(700% / 12);
}

.fr-col-offset-7:not(.fr-col-offset-1--right) {
  margin-left: calc(700% / 12);
}

.fr-col-offset-7--right {
  margin-right: calc(700% / 12);
}

.fr-col-8 {
  flex: 0 0 calc(800% / 12);
  width: calc(800% / 12);
  max-width: calc(800% / 12);
}

.fr-col-offset-8:not(.fr-col-offset-1--right) {
  margin-left: calc(800% / 12);
}

.fr-col-offset-8--right {
  margin-right: calc(800% / 12);
}

.fr-col-9 {
  flex: 0 0 calc(900% / 12);
  width: calc(900% / 12);
  max-width: calc(900% / 12);
}

.fr-col-offset-9:not(.fr-col-offset-1--right) {
  margin-left: calc(900% / 12);
}

.fr-col-offset-9--right {
  margin-right: calc(900% / 12);
}

.fr-col-10 {
  flex: 0 0 calc(1000% / 12);
  width: calc(1000% / 12);
  max-width: calc(1000% / 12);
}

.fr-col-offset-10:not(.fr-col-offset-1--right) {
  margin-left: calc(1000% / 12);
}

.fr-col-offset-10--right {
  margin-right: calc(1000% / 12);
}

.fr-col-11 {
  flex: 0 0 calc(1100% / 12);
  width: calc(1100% / 12);
  max-width: calc(1100% / 12);
}

.fr-col-offset-11:not(.fr-col-offset-1--right) {
  margin-left: calc(1100% / 12);
}

.fr-col-offset-11--right {
  margin-right: calc(1100% / 12);
}

.fr-col-12 {
  flex: 0 0 calc(1200% / 12);
  width: calc(1200% / 12);
  max-width: calc(1200% / 12);
}

.fr-col-offset-12:not(.fr-col-offset-1--right) {
  margin-left: calc(1200% / 12);
}

.fr-col-offset-12--right {
  margin-right: calc(1200% / 12);
}

@media (min-width: 36em) {
  .fr-container-sm {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .fr-container-sm--fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
  .fr-grid-row-sm--gutters {
    margin: -0.5rem;
  }
  .fr-grid-row-sm--gutters > [class^="fr-col-"], .fr-grid-row-sm--gutters > [class*=" fr-col-"],
  .fr-grid-row-sm--gutters > .fr-col {
    padding: 0.5rem;
  }
  .fr-grid-row-sm--no-gutters {
    margin: 0;
  }
  .fr-grid-row-sm--no-gutters > [class^="fr-col-"], .fr-grid-row-sm--no-gutters > [class*=" fr-col-"],
  .fr-grid-row-sm--no-gutters > .fr-col {
    padding: 0;
  }
  .fr-col-sm {
    flex: 1;
  }
  .fr-col-sm-1 {
    flex: 0 0 calc(100% / 12);
    width: calc(100% / 12);
    max-width: calc(100% / 12);
  }
  .fr-col-offset-sm-1:not(.fr-col-offset-1--right) {
    margin-left: calc(100% / 12);
  }
  .fr-col-offset-sm-1--right {
    margin-right: calc(100% / 12);
  }
  .fr-col-sm-2 {
    flex: 0 0 calc(200% / 12);
    width: calc(200% / 12);
    max-width: calc(200% / 12);
  }
  .fr-col-offset-sm-2:not(.fr-col-offset-1--right) {
    margin-left: calc(200% / 12);
  }
  .fr-col-offset-sm-2--right {
    margin-right: calc(200% / 12);
  }
  .fr-col-sm-3 {
    flex: 0 0 calc(300% / 12);
    width: calc(300% / 12);
    max-width: calc(300% / 12);
  }
  .fr-col-offset-sm-3:not(.fr-col-offset-1--right) {
    margin-left: calc(300% / 12);
  }
  .fr-col-offset-sm-3--right {
    margin-right: calc(300% / 12);
  }
  .fr-col-sm-4 {
    flex: 0 0 calc(400% / 12);
    width: calc(400% / 12);
    max-width: calc(400% / 12);
  }
  .fr-col-offset-sm-4:not(.fr-col-offset-1--right) {
    margin-left: calc(400% / 12);
  }
  .fr-col-offset-sm-4--right {
    margin-right: calc(400% / 12);
  }
  .fr-col-sm-5 {
    flex: 0 0 calc(500% / 12);
    width: calc(500% / 12);
    max-width: calc(500% / 12);
  }
  .fr-col-offset-sm-5:not(.fr-col-offset-1--right) {
    margin-left: calc(500% / 12);
  }
  .fr-col-offset-sm-5--right {
    margin-right: calc(500% / 12);
  }
  .fr-col-sm-6 {
    flex: 0 0 calc(600% / 12);
    width: calc(600% / 12);
    max-width: calc(600% / 12);
  }
  .fr-col-offset-sm-6:not(.fr-col-offset-1--right) {
    margin-left: calc(600% / 12);
  }
  .fr-col-offset-sm-6--right {
    margin-right: calc(600% / 12);
  }
  .fr-col-sm-7 {
    flex: 0 0 calc(700% / 12);
    width: calc(700% / 12);
    max-width: calc(700% / 12);
  }
  .fr-col-offset-sm-7:not(.fr-col-offset-1--right) {
    margin-left: calc(700% / 12);
  }
  .fr-col-offset-sm-7--right {
    margin-right: calc(700% / 12);
  }
  .fr-col-sm-8 {
    flex: 0 0 calc(800% / 12);
    width: calc(800% / 12);
    max-width: calc(800% / 12);
  }
  .fr-col-offset-sm-8:not(.fr-col-offset-1--right) {
    margin-left: calc(800% / 12);
  }
  .fr-col-offset-sm-8--right {
    margin-right: calc(800% / 12);
  }
  .fr-col-sm-9 {
    flex: 0 0 calc(900% / 12);
    width: calc(900% / 12);
    max-width: calc(900% / 12);
  }
  .fr-col-offset-sm-9:not(.fr-col-offset-1--right) {
    margin-left: calc(900% / 12);
  }
  .fr-col-offset-sm-9--right {
    margin-right: calc(900% / 12);
  }
  .fr-col-sm-10 {
    flex: 0 0 calc(1000% / 12);
    width: calc(1000% / 12);
    max-width: calc(1000% / 12);
  }
  .fr-col-offset-sm-10:not(.fr-col-offset-1--right) {
    margin-left: calc(1000% / 12);
  }
  .fr-col-offset-sm-10--right {
    margin-right: calc(1000% / 12);
  }
  .fr-col-sm-11 {
    flex: 0 0 calc(1100% / 12);
    width: calc(1100% / 12);
    max-width: calc(1100% / 12);
  }
  .fr-col-offset-sm-11:not(.fr-col-offset-1--right) {
    margin-left: calc(1100% / 12);
  }
  .fr-col-offset-sm-11--right {
    margin-right: calc(1100% / 12);
  }
  .fr-col-sm-12 {
    flex: 0 0 calc(1200% / 12);
    width: calc(1200% / 12);
    max-width: calc(1200% / 12);
  }
  .fr-col-offset-sm-12:not(.fr-col-offset-1--right) {
    margin-left: calc(1200% / 12);
  }
  .fr-col-offset-sm-12--right {
    margin-right: calc(1200% / 12);
  }
}

@media (min-width: 48em) {
  .fr-container-md {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .fr-container-md--fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
  .fr-grid-row-md--gutters {
    margin: -0.5rem;
  }
  .fr-grid-row-md--gutters > [class^="fr-col-"], .fr-grid-row-md--gutters > [class*=" fr-col-"],
  .fr-grid-row-md--gutters > .fr-col {
    padding: 0.5rem;
  }
  .fr-grid-row-md--no-gutters {
    margin: 0;
  }
  .fr-grid-row-md--no-gutters > [class^="fr-col-"], .fr-grid-row-md--no-gutters > [class*=" fr-col-"],
  .fr-grid-row-md--no-gutters > .fr-col {
    padding: 0;
  }
  .fr-col-md {
    flex: 1;
  }
  .fr-col-md-1 {
    flex: 0 0 calc(100% / 12);
    width: calc(100% / 12);
    max-width: calc(100% / 12);
  }
  .fr-col-offset-md-1:not(.fr-col-offset-1--right) {
    margin-left: calc(100% / 12);
  }
  .fr-col-offset-md-1--right {
    margin-right: calc(100% / 12);
  }
  .fr-col-md-2 {
    flex: 0 0 calc(200% / 12);
    width: calc(200% / 12);
    max-width: calc(200% / 12);
  }
  .fr-col-offset-md-2:not(.fr-col-offset-1--right) {
    margin-left: calc(200% / 12);
  }
  .fr-col-offset-md-2--right {
    margin-right: calc(200% / 12);
  }
  .fr-col-md-3 {
    flex: 0 0 calc(300% / 12);
    width: calc(300% / 12);
    max-width: calc(300% / 12);
  }
  .fr-col-offset-md-3:not(.fr-col-offset-1--right) {
    margin-left: calc(300% / 12);
  }
  .fr-col-offset-md-3--right {
    margin-right: calc(300% / 12);
  }
  .fr-col-md-4 {
    flex: 0 0 calc(400% / 12);
    width: calc(400% / 12);
    max-width: calc(400% / 12);
  }
  .fr-col-offset-md-4:not(.fr-col-offset-1--right) {
    margin-left: calc(400% / 12);
  }
  .fr-col-offset-md-4--right {
    margin-right: calc(400% / 12);
  }
  .fr-col-md-5 {
    flex: 0 0 calc(500% / 12);
    width: calc(500% / 12);
    max-width: calc(500% / 12);
  }
  .fr-col-offset-md-5:not(.fr-col-offset-1--right) {
    margin-left: calc(500% / 12);
  }
  .fr-col-offset-md-5--right {
    margin-right: calc(500% / 12);
  }
  .fr-col-md-6 {
    flex: 0 0 calc(600% / 12);
    width: calc(600% / 12);
    max-width: calc(600% / 12);
  }
  .fr-col-offset-md-6:not(.fr-col-offset-1--right) {
    margin-left: calc(600% / 12);
  }
  .fr-col-offset-md-6--right {
    margin-right: calc(600% / 12);
  }
  .fr-col-md-7 {
    flex: 0 0 calc(700% / 12);
    width: calc(700% / 12);
    max-width: calc(700% / 12);
  }
  .fr-col-offset-md-7:not(.fr-col-offset-1--right) {
    margin-left: calc(700% / 12);
  }
  .fr-col-offset-md-7--right {
    margin-right: calc(700% / 12);
  }
  .fr-col-md-8 {
    flex: 0 0 calc(800% / 12);
    width: calc(800% / 12);
    max-width: calc(800% / 12);
  }
  .fr-col-offset-md-8:not(.fr-col-offset-1--right) {
    margin-left: calc(800% / 12);
  }
  .fr-col-offset-md-8--right {
    margin-right: calc(800% / 12);
  }
  .fr-col-md-9 {
    flex: 0 0 calc(900% / 12);
    width: calc(900% / 12);
    max-width: calc(900% / 12);
  }
  .fr-col-offset-md-9:not(.fr-col-offset-1--right) {
    margin-left: calc(900% / 12);
  }
  .fr-col-offset-md-9--right {
    margin-right: calc(900% / 12);
  }
  .fr-col-md-10 {
    flex: 0 0 calc(1000% / 12);
    width: calc(1000% / 12);
    max-width: calc(1000% / 12);
  }
  .fr-col-offset-md-10:not(.fr-col-offset-1--right) {
    margin-left: calc(1000% / 12);
  }
  .fr-col-offset-md-10--right {
    margin-right: calc(1000% / 12);
  }
  .fr-col-md-11 {
    flex: 0 0 calc(1100% / 12);
    width: calc(1100% / 12);
    max-width: calc(1100% / 12);
  }
  .fr-col-offset-md-11:not(.fr-col-offset-1--right) {
    margin-left: calc(1100% / 12);
  }
  .fr-col-offset-md-11--right {
    margin-right: calc(1100% / 12);
  }
  .fr-col-md-12 {
    flex: 0 0 calc(1200% / 12);
    width: calc(1200% / 12);
    max-width: calc(1200% / 12);
  }
  .fr-col-offset-md-12:not(.fr-col-offset-1--right) {
    margin-left: calc(1200% / 12);
  }
  .fr-col-offset-md-12--right {
    margin-right: calc(1200% / 12);
  }
}

@media (min-width: 62em) {
  .fr-container, .fr-container-sm, .fr-container-md {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .fr-container-lg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .fr-container-lg--fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
  .fr-grid-row--gutters, .fr-grid-row-sm--gutters, .fr-grid-row-md--gutters {
    margin: -0.75rem;
  }
  .fr-grid-row--gutters > [class^="fr-col-"], .fr-grid-row--gutters > [class*=" fr-col-"],
  .fr-grid-row--gutters > .fr-col, .fr-grid-row-sm--gutters > [class^="fr-col-"], .fr-grid-row-sm--gutters > [class*=" fr-col-"],
  .fr-grid-row-sm--gutters > .fr-col, .fr-grid-row-md--gutters > [class^="fr-col-"], .fr-grid-row-md--gutters > [class*=" fr-col-"],
  .fr-grid-row-md--gutters > .fr-col {
    padding: 0.75rem;
  }
  .fr-grid-row-lg--gutters {
    margin: -0.75rem;
  }
  .fr-grid-row-lg--gutters > [class^="fr-col-"], .fr-grid-row-lg--gutters > [class*=" fr-col-"],
  .fr-grid-row-lg--gutters > .fr-col {
    padding: 0.75rem;
  }
  .fr-grid-row-lg--no-gutters {
    margin: 0;
  }
  .fr-grid-row-lg--no-gutters > [class^="fr-col-"], .fr-grid-row-lg--no-gutters > [class*=" fr-col-"],
  .fr-grid-row-lg--no-gutters > .fr-col {
    padding: 0;
  }
  .fr-col-lg {
    flex: 1;
  }
  .fr-col-lg-1 {
    flex: 0 0 calc(100% / 12);
    width: calc(100% / 12);
    max-width: calc(100% / 12);
  }
  .fr-col-offset-lg-1:not(.fr-col-offset-1--right) {
    margin-left: calc(100% / 12);
  }
  .fr-col-offset-lg-1--right {
    margin-right: calc(100% / 12);
  }
  .fr-col-lg-2 {
    flex: 0 0 calc(200% / 12);
    width: calc(200% / 12);
    max-width: calc(200% / 12);
  }
  .fr-col-offset-lg-2:not(.fr-col-offset-1--right) {
    margin-left: calc(200% / 12);
  }
  .fr-col-offset-lg-2--right {
    margin-right: calc(200% / 12);
  }
  .fr-col-lg-3 {
    flex: 0 0 calc(300% / 12);
    width: calc(300% / 12);
    max-width: calc(300% / 12);
  }
  .fr-col-offset-lg-3:not(.fr-col-offset-1--right) {
    margin-left: calc(300% / 12);
  }
  .fr-col-offset-lg-3--right {
    margin-right: calc(300% / 12);
  }
  .fr-col-lg-4 {
    flex: 0 0 calc(400% / 12);
    width: calc(400% / 12);
    max-width: calc(400% / 12);
  }
  .fr-col-offset-lg-4:not(.fr-col-offset-1--right) {
    margin-left: calc(400% / 12);
  }
  .fr-col-offset-lg-4--right {
    margin-right: calc(400% / 12);
  }
  .fr-col-lg-5 {
    flex: 0 0 calc(500% / 12);
    width: calc(500% / 12);
    max-width: calc(500% / 12);
  }
  .fr-col-offset-lg-5:not(.fr-col-offset-1--right) {
    margin-left: calc(500% / 12);
  }
  .fr-col-offset-lg-5--right {
    margin-right: calc(500% / 12);
  }
  .fr-col-lg-6 {
    flex: 0 0 calc(600% / 12);
    width: calc(600% / 12);
    max-width: calc(600% / 12);
  }
  .fr-col-offset-lg-6:not(.fr-col-offset-1--right) {
    margin-left: calc(600% / 12);
  }
  .fr-col-offset-lg-6--right {
    margin-right: calc(600% / 12);
  }
  .fr-col-lg-7 {
    flex: 0 0 calc(700% / 12);
    width: calc(700% / 12);
    max-width: calc(700% / 12);
  }
  .fr-col-offset-lg-7:not(.fr-col-offset-1--right) {
    margin-left: calc(700% / 12);
  }
  .fr-col-offset-lg-7--right {
    margin-right: calc(700% / 12);
  }
  .fr-col-lg-8 {
    flex: 0 0 calc(800% / 12);
    width: calc(800% / 12);
    max-width: calc(800% / 12);
  }
  .fr-col-offset-lg-8:not(.fr-col-offset-1--right) {
    margin-left: calc(800% / 12);
  }
  .fr-col-offset-lg-8--right {
    margin-right: calc(800% / 12);
  }
  .fr-col-lg-9 {
    flex: 0 0 calc(900% / 12);
    width: calc(900% / 12);
    max-width: calc(900% / 12);
  }
  .fr-col-offset-lg-9:not(.fr-col-offset-1--right) {
    margin-left: calc(900% / 12);
  }
  .fr-col-offset-lg-9--right {
    margin-right: calc(900% / 12);
  }
  .fr-col-lg-10 {
    flex: 0 0 calc(1000% / 12);
    width: calc(1000% / 12);
    max-width: calc(1000% / 12);
  }
  .fr-col-offset-lg-10:not(.fr-col-offset-1--right) {
    margin-left: calc(1000% / 12);
  }
  .fr-col-offset-lg-10--right {
    margin-right: calc(1000% / 12);
  }
  .fr-col-lg-11 {
    flex: 0 0 calc(1100% / 12);
    width: calc(1100% / 12);
    max-width: calc(1100% / 12);
  }
  .fr-col-offset-lg-11:not(.fr-col-offset-1--right) {
    margin-left: calc(1100% / 12);
  }
  .fr-col-offset-lg-11--right {
    margin-right: calc(1100% / 12);
  }
  .fr-col-lg-12 {
    flex: 0 0 calc(1200% / 12);
    width: calc(1200% / 12);
    max-width: calc(1200% / 12);
  }
  .fr-col-offset-lg-12:not(.fr-col-offset-1--right) {
    margin-left: calc(1200% / 12);
  }
  .fr-col-offset-lg-12--right {
    margin-right: calc(1200% / 12);
  }
}

@media (min-width: 78em) {
  .fr-container, .fr-container-sm, .fr-container-md, .fr-container-lg {
    max-width: 78rem;
  }
  .fr-container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 78rem;
  }
  .fr-container-xl--fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
  .fr-grid-row-xl--gutters {
    margin: -0.75rem;
  }
  .fr-grid-row-xl--gutters > [class^="fr-col-"], .fr-grid-row-xl--gutters > [class*=" fr-col-"],
  .fr-grid-row-xl--gutters > .fr-col {
    padding: 0.75rem;
  }
  .fr-grid-row-xl--no-gutters {
    margin: 0;
  }
  .fr-grid-row-xl--no-gutters > [class^="fr-col-"], .fr-grid-row-xl--no-gutters > [class*=" fr-col-"],
  .fr-grid-row-xl--no-gutters > .fr-col {
    padding: 0;
  }
  .fr-col-xl {
    flex: 1;
  }
  .fr-col-xl-1 {
    flex: 0 0 calc(100% / 12);
    width: calc(100% / 12);
    max-width: calc(100% / 12);
  }
  .fr-col-offset-xl-1:not(.fr-col-offset-1--right) {
    margin-left: calc(100% / 12);
  }
  .fr-col-offset-xl-1--right {
    margin-right: calc(100% / 12);
  }
  .fr-col-xl-2 {
    flex: 0 0 calc(200% / 12);
    width: calc(200% / 12);
    max-width: calc(200% / 12);
  }
  .fr-col-offset-xl-2:not(.fr-col-offset-1--right) {
    margin-left: calc(200% / 12);
  }
  .fr-col-offset-xl-2--right {
    margin-right: calc(200% / 12);
  }
  .fr-col-xl-3 {
    flex: 0 0 calc(300% / 12);
    width: calc(300% / 12);
    max-width: calc(300% / 12);
  }
  .fr-col-offset-xl-3:not(.fr-col-offset-1--right) {
    margin-left: calc(300% / 12);
  }
  .fr-col-offset-xl-3--right {
    margin-right: calc(300% / 12);
  }
  .fr-col-xl-4 {
    flex: 0 0 calc(400% / 12);
    width: calc(400% / 12);
    max-width: calc(400% / 12);
  }
  .fr-col-offset-xl-4:not(.fr-col-offset-1--right) {
    margin-left: calc(400% / 12);
  }
  .fr-col-offset-xl-4--right {
    margin-right: calc(400% / 12);
  }
  .fr-col-xl-5 {
    flex: 0 0 calc(500% / 12);
    width: calc(500% / 12);
    max-width: calc(500% / 12);
  }
  .fr-col-offset-xl-5:not(.fr-col-offset-1--right) {
    margin-left: calc(500% / 12);
  }
  .fr-col-offset-xl-5--right {
    margin-right: calc(500% / 12);
  }
  .fr-col-xl-6 {
    flex: 0 0 calc(600% / 12);
    width: calc(600% / 12);
    max-width: calc(600% / 12);
  }
  .fr-col-offset-xl-6:not(.fr-col-offset-1--right) {
    margin-left: calc(600% / 12);
  }
  .fr-col-offset-xl-6--right {
    margin-right: calc(600% / 12);
  }
  .fr-col-xl-7 {
    flex: 0 0 calc(700% / 12);
    width: calc(700% / 12);
    max-width: calc(700% / 12);
  }
  .fr-col-offset-xl-7:not(.fr-col-offset-1--right) {
    margin-left: calc(700% / 12);
  }
  .fr-col-offset-xl-7--right {
    margin-right: calc(700% / 12);
  }
  .fr-col-xl-8 {
    flex: 0 0 calc(800% / 12);
    width: calc(800% / 12);
    max-width: calc(800% / 12);
  }
  .fr-col-offset-xl-8:not(.fr-col-offset-1--right) {
    margin-left: calc(800% / 12);
  }
  .fr-col-offset-xl-8--right {
    margin-right: calc(800% / 12);
  }
  .fr-col-xl-9 {
    flex: 0 0 calc(900% / 12);
    width: calc(900% / 12);
    max-width: calc(900% / 12);
  }
  .fr-col-offset-xl-9:not(.fr-col-offset-1--right) {
    margin-left: calc(900% / 12);
  }
  .fr-col-offset-xl-9--right {
    margin-right: calc(900% / 12);
  }
  .fr-col-xl-10 {
    flex: 0 0 calc(1000% / 12);
    width: calc(1000% / 12);
    max-width: calc(1000% / 12);
  }
  .fr-col-offset-xl-10:not(.fr-col-offset-1--right) {
    margin-left: calc(1000% / 12);
  }
  .fr-col-offset-xl-10--right {
    margin-right: calc(1000% / 12);
  }
  .fr-col-xl-11 {
    flex: 0 0 calc(1100% / 12);
    width: calc(1100% / 12);
    max-width: calc(1100% / 12);
  }
  .fr-col-offset-xl-11:not(.fr-col-offset-1--right) {
    margin-left: calc(1100% / 12);
  }
  .fr-col-offset-xl-11--right {
    margin-right: calc(1100% / 12);
  }
  .fr-col-xl-12 {
    flex: 0 0 calc(1200% / 12);
    width: calc(1200% / 12);
    max-width: calc(1200% / 12);
  }
  .fr-col-offset-xl-12:not(.fr-col-offset-1--right) {
    margin-left: calc(1200% / 12);
  }
  .fr-col-offset-xl-12--right {
    margin-right: calc(1200% / 12);
  }
}

.fr-no-before::before {
  content: none;
}

.fr-no-after::after {
  content: none;
}

.fr-collapse {
  --collapse-max-height: 0;
  --collapse: -99999px;
  --collapser: '';
  overflow: hidden;
  transition: visibility 0.3s;
  max-height: 0;
  max-height: var(--collapse-max-height);
}

.fr-collapse::before {
  display: block;
  content: '';
  content: var(--collapser);
  transition: margin-top 0.3s;
  margin-top: 0;
}

.fr-collapse:not(.fr-collapse--expanded) {
  visibility: hidden;
}

.fr-collapse:not(.fr-collapse--expanded)::before {
  margin-top: -99999px;
  margin-top: var(--collapse);
}

.fr-m-n8v, .fr-m-n4w {
  margin: -2rem !important;
}

.fr-ml-n8v, .fr-ml-n4w,
.fr-mx-n8v, .fr-mx-n4w {
  margin-left: -2rem !important;
}

.fr-mr-n8v, .fr-mr-n4w,
.fr-mx-n8v, .fr-mx-n4w {
  margin-right: -2rem !important;
}

.fr-mt-n8v, .fr-mt-n4w,
.fr-my-n8v, .fr-my-n4w {
  margin-top: -2rem !important;
}

.fr-mb-n8v, .fr-mb-n4w,
.fr-my-n8v, .fr-my-n4w {
  margin-bottom: -2rem !important;
}

.fr-m-n7v {
  margin: -1.75rem !important;
}

.fr-ml-n7v,
.fr-mx-n7v {
  margin-left: -1.75rem !important;
}

.fr-mr-n7v,
.fr-mx-n7v {
  margin-right: -1.75rem !important;
}

.fr-mt-n7v,
.fr-my-n7v {
  margin-top: -1.75rem !important;
}

.fr-mb-n7v,
.fr-my-n7v {
  margin-bottom: -1.75rem !important;
}

.fr-m-n6v, .fr-m-n3w {
  margin: -1.5rem !important;
}

.fr-ml-n6v, .fr-ml-n3w,
.fr-mx-n6v, .fr-mx-n3w {
  margin-left: -1.5rem !important;
}

.fr-mr-n6v, .fr-mr-n3w,
.fr-mx-n6v, .fr-mx-n3w {
  margin-right: -1.5rem !important;
}

.fr-mt-n6v, .fr-mt-n3w,
.fr-my-n6v, .fr-my-n3w {
  margin-top: -1.5rem !important;
}

.fr-mb-n6v, .fr-mb-n3w,
.fr-my-n6v, .fr-my-n3w {
  margin-bottom: -1.5rem !important;
}

.fr-m-n5v {
  margin: -1.25rem !important;
}

.fr-ml-n5v,
.fr-mx-n5v {
  margin-left: -1.25rem !important;
}

.fr-mr-n5v,
.fr-mx-n5v {
  margin-right: -1.25rem !important;
}

.fr-mt-n5v,
.fr-my-n5v {
  margin-top: -1.25rem !important;
}

.fr-mb-n5v,
.fr-my-n5v {
  margin-bottom: -1.25rem !important;
}

.fr-m-n4v, .fr-m-n2w {
  margin: -1rem !important;
}

.fr-ml-n4v, .fr-ml-n2w,
.fr-mx-n4v, .fr-mx-n2w {
  margin-left: -1rem !important;
}

.fr-mr-n4v, .fr-mr-n2w,
.fr-mx-n4v, .fr-mx-n2w {
  margin-right: -1rem !important;
}

.fr-mt-n4v, .fr-mt-n2w,
.fr-my-n4v, .fr-my-n2w {
  margin-top: -1rem !important;
}

.fr-mb-n4v, .fr-mb-n2w,
.fr-my-n4v, .fr-my-n2w {
  margin-bottom: -1rem !important;
}

.fr-m-n3v {
  margin: -0.75rem !important;
}

.fr-ml-n3v,
.fr-mx-n3v {
  margin-left: -0.75rem !important;
}

.fr-mr-n3v,
.fr-mx-n3v {
  margin-right: -0.75rem !important;
}

.fr-mt-n3v,
.fr-my-n3v {
  margin-top: -0.75rem !important;
}

.fr-mb-n3v,
.fr-my-n3v {
  margin-bottom: -0.75rem !important;
}

.fr-m-n2v, .fr-m-n1w {
  margin: -0.5rem !important;
}

.fr-ml-n2v, .fr-ml-n1w,
.fr-mx-n2v, .fr-mx-n1w {
  margin-left: -0.5rem !important;
}

.fr-mr-n2v, .fr-mr-n1w,
.fr-mx-n2v, .fr-mx-n1w {
  margin-right: -0.5rem !important;
}

.fr-mt-n2v, .fr-mt-n1w,
.fr-my-n2v, .fr-my-n1w {
  margin-top: -0.5rem !important;
}

.fr-mb-n2v, .fr-mb-n1w,
.fr-my-n2v, .fr-my-n1w {
  margin-bottom: -0.5rem !important;
}

.fr-m-n1v {
  margin: -0.25rem !important;
}

.fr-ml-n1v,
.fr-mx-n1v {
  margin-left: -0.25rem !important;
}

.fr-mr-n1v,
.fr-mx-n1v {
  margin-right: -0.25rem !important;
}

.fr-mt-n1v,
.fr-my-n1v {
  margin-top: -0.25rem !important;
}

.fr-mb-n1v,
.fr-my-n1v {
  margin-bottom: -0.25rem !important;
}

.fr-m-n1-5v {
  margin: -0.375rem !important;
}

.fr-ml-n1-5v,
.fr-mx-n1-5v {
  margin-left: -0.375rem !important;
}

.fr-mr-n1-5v,
.fr-mx-n1-5v {
  margin-right: -0.375rem !important;
}

.fr-mt-n1-5v,
.fr-my-n1-5v {
  margin-top: -0.375rem !important;
}

.fr-mb-n1-5v,
.fr-my-n1-5v {
  margin-bottom: -0.375rem !important;
}

.fr-m-0 {
  margin: 0rem !important;
}

.fr-ml-0,
.fr-mx-0 {
  margin-left: 0rem !important;
}

.fr-mr-0,
.fr-mx-0 {
  margin-right: 0rem !important;
}

.fr-mt-0,
.fr-my-0 {
  margin-top: 0rem !important;
}

.fr-mb-0,
.fr-my-0 {
  margin-bottom: 0rem !important;
}

.fr-m-n0-5v {
  margin: -0.125rem !important;
}

.fr-ml-n0-5v,
.fr-mx-n0-5v {
  margin-left: -0.125rem !important;
}

.fr-mr-n0-5v,
.fr-mx-n0-5v {
  margin-right: -0.125rem !important;
}

.fr-mt-n0-5v,
.fr-my-n0-5v {
  margin-top: -0.125rem !important;
}

.fr-mb-n0-5v,
.fr-my-n0-5v {
  margin-bottom: -0.125rem !important;
}

.fr-m-0-5v {
  margin: 0.125rem !important;
}

.fr-ml-0-5v,
.fr-mx-0-5v {
  margin-left: 0.125rem !important;
}

.fr-mr-0-5v,
.fr-mx-0-5v {
  margin-right: 0.125rem !important;
}

.fr-mt-0-5v,
.fr-my-0-5v {
  margin-top: 0.125rem !important;
}

.fr-mb-0-5v,
.fr-my-0-5v {
  margin-bottom: 0.125rem !important;
}

.fr-m-1v {
  margin: 0.25rem !important;
}

.fr-ml-1v,
.fr-mx-1v {
  margin-left: 0.25rem !important;
}

.fr-mr-1v,
.fr-mx-1v {
  margin-right: 0.25rem !important;
}

.fr-mt-1v,
.fr-my-1v {
  margin-top: 0.25rem !important;
}

.fr-mb-1v,
.fr-my-1v {
  margin-bottom: 0.25rem !important;
}

.fr-m-1-5v {
  margin: 0.375rem !important;
}

.fr-ml-1-5v,
.fr-mx-1-5v {
  margin-left: 0.375rem !important;
}

.fr-mr-1-5v,
.fr-mx-1-5v {
  margin-right: 0.375rem !important;
}

.fr-mt-1-5v,
.fr-my-1-5v {
  margin-top: 0.375rem !important;
}

.fr-mb-1-5v,
.fr-my-1-5v {
  margin-bottom: 0.375rem !important;
}

.fr-m-2v, .fr-m-1w {
  margin: 0.5rem !important;
}

.fr-ml-2v, .fr-ml-1w,
.fr-mx-2v, .fr-mx-1w {
  margin-left: 0.5rem !important;
}

.fr-mr-2v, .fr-mr-1w,
.fr-mx-2v, .fr-mx-1w {
  margin-right: 0.5rem !important;
}

.fr-mt-2v, .fr-mt-1w,
.fr-my-2v, .fr-my-1w {
  margin-top: 0.5rem !important;
}

.fr-mb-2v, .fr-mb-1w,
.fr-my-2v, .fr-my-1w {
  margin-bottom: 0.5rem !important;
}

.fr-m-3v {
  margin: 0.75rem !important;
}

.fr-ml-3v,
.fr-mx-3v {
  margin-left: 0.75rem !important;
}

.fr-mr-3v,
.fr-mx-3v {
  margin-right: 0.75rem !important;
}

.fr-mt-3v,
.fr-my-3v {
  margin-top: 0.75rem !important;
}

.fr-mb-3v,
.fr-my-3v {
  margin-bottom: 0.75rem !important;
}

.fr-m-4v, .fr-m-2w {
  margin: 1rem !important;
}

.fr-ml-4v, .fr-ml-2w,
.fr-mx-4v, .fr-mx-2w {
  margin-left: 1rem !important;
}

.fr-mr-4v, .fr-mr-2w,
.fr-mx-4v, .fr-mx-2w {
  margin-right: 1rem !important;
}

.fr-mt-4v, .fr-mt-2w,
.fr-my-4v, .fr-my-2w {
  margin-top: 1rem !important;
}

.fr-mb-4v, .fr-mb-2w,
.fr-my-4v, .fr-my-2w {
  margin-bottom: 1rem !important;
}

.fr-m-5v {
  margin: 1.25rem !important;
}

.fr-ml-5v,
.fr-mx-5v {
  margin-left: 1.25rem !important;
}

.fr-mr-5v,
.fr-mx-5v {
  margin-right: 1.25rem !important;
}

.fr-mt-5v,
.fr-my-5v {
  margin-top: 1.25rem !important;
}

.fr-mb-5v,
.fr-my-5v {
  margin-bottom: 1.25rem !important;
}

.fr-m-6v, .fr-m-3w {
  margin: 1.5rem !important;
}

.fr-ml-6v, .fr-ml-3w,
.fr-mx-6v, .fr-mx-3w {
  margin-left: 1.5rem !important;
}

.fr-mr-6v, .fr-mr-3w,
.fr-mx-6v, .fr-mx-3w {
  margin-right: 1.5rem !important;
}

.fr-mt-6v, .fr-mt-3w,
.fr-my-6v, .fr-my-3w {
  margin-top: 1.5rem !important;
}

.fr-mb-6v, .fr-mb-3w,
.fr-my-6v, .fr-my-3w {
  margin-bottom: 1.5rem !important;
}

.fr-m-7v {
  margin: 1.75rem !important;
}

.fr-ml-7v,
.fr-mx-7v {
  margin-left: 1.75rem !important;
}

.fr-mr-7v,
.fr-mx-7v {
  margin-right: 1.75rem !important;
}

.fr-mt-7v,
.fr-my-7v {
  margin-top: 1.75rem !important;
}

.fr-mb-7v,
.fr-my-7v {
  margin-bottom: 1.75rem !important;
}

.fr-m-8v, .fr-m-4w {
  margin: 2rem !important;
}

.fr-ml-8v, .fr-ml-4w,
.fr-mx-8v, .fr-mx-4w {
  margin-left: 2rem !important;
}

.fr-mr-8v, .fr-mr-4w,
.fr-mx-8v, .fr-mx-4w {
  margin-right: 2rem !important;
}

.fr-mt-8v, .fr-mt-4w,
.fr-my-8v, .fr-my-4w {
  margin-top: 2rem !important;
}

.fr-mb-8v, .fr-mb-4w,
.fr-my-8v, .fr-my-4w {
  margin-bottom: 2rem !important;
}

.fr-m-9v {
  margin: 2.25rem !important;
}

.fr-ml-9v,
.fr-mx-9v {
  margin-left: 2.25rem !important;
}

.fr-mr-9v,
.fr-mx-9v {
  margin-right: 2.25rem !important;
}

.fr-mt-9v,
.fr-my-9v {
  margin-top: 2.25rem !important;
}

.fr-mb-9v,
.fr-my-9v {
  margin-bottom: 2.25rem !important;
}

.fr-m-10v, .fr-m-5w {
  margin: 2.5rem !important;
}

.fr-ml-10v, .fr-ml-5w,
.fr-mx-10v, .fr-mx-5w {
  margin-left: 2.5rem !important;
}

.fr-mr-10v, .fr-mr-5w,
.fr-mx-10v, .fr-mx-5w {
  margin-right: 2.5rem !important;
}

.fr-mt-10v, .fr-mt-5w,
.fr-my-10v, .fr-my-5w {
  margin-top: 2.5rem !important;
}

.fr-mb-10v, .fr-mb-5w,
.fr-my-10v, .fr-my-5w {
  margin-bottom: 2.5rem !important;
}

.fr-m-11v {
  margin: 2.75rem !important;
}

.fr-ml-11v,
.fr-mx-11v {
  margin-left: 2.75rem !important;
}

.fr-mr-11v,
.fr-mx-11v {
  margin-right: 2.75rem !important;
}

.fr-mt-11v,
.fr-my-11v {
  margin-top: 2.75rem !important;
}

.fr-mb-11v,
.fr-my-11v {
  margin-bottom: 2.75rem !important;
}

.fr-m-12v, .fr-m-6w {
  margin: 3rem !important;
}

.fr-ml-12v, .fr-ml-6w,
.fr-mx-12v, .fr-mx-6w {
  margin-left: 3rem !important;
}

.fr-mr-12v, .fr-mr-6w,
.fr-mx-12v, .fr-mx-6w {
  margin-right: 3rem !important;
}

.fr-mt-12v, .fr-mt-6w,
.fr-my-12v, .fr-my-6w {
  margin-top: 3rem !important;
}

.fr-mb-12v, .fr-mb-6w,
.fr-my-12v, .fr-my-6w {
  margin-bottom: 3rem !important;
}

.fr-m-13v {
  margin: 3.25rem !important;
}

.fr-ml-13v,
.fr-mx-13v {
  margin-left: 3.25rem !important;
}

.fr-mr-13v,
.fr-mx-13v {
  margin-right: 3.25rem !important;
}

.fr-mt-13v,
.fr-my-13v {
  margin-top: 3.25rem !important;
}

.fr-mb-13v,
.fr-my-13v {
  margin-bottom: 3.25rem !important;
}

.fr-m-14v, .fr-m-7w {
  margin: 3.5rem !important;
}

.fr-ml-14v, .fr-ml-7w,
.fr-mx-14v, .fr-mx-7w {
  margin-left: 3.5rem !important;
}

.fr-mr-14v, .fr-mr-7w,
.fr-mx-14v, .fr-mx-7w {
  margin-right: 3.5rem !important;
}

.fr-mt-14v, .fr-mt-7w,
.fr-my-14v, .fr-my-7w {
  margin-top: 3.5rem !important;
}

.fr-mb-14v, .fr-mb-7w,
.fr-my-14v, .fr-my-7w {
  margin-bottom: 3.5rem !important;
}

.fr-m-15v {
  margin: 3.75rem !important;
}

.fr-ml-15v,
.fr-mx-15v {
  margin-left: 3.75rem !important;
}

.fr-mr-15v,
.fr-mx-15v {
  margin-right: 3.75rem !important;
}

.fr-mt-15v,
.fr-my-15v {
  margin-top: 3.75rem !important;
}

.fr-mb-15v,
.fr-my-15v {
  margin-bottom: 3.75rem !important;
}

.fr-m-16v, .fr-m-8w {
  margin: 4rem !important;
}

.fr-ml-16v, .fr-ml-8w,
.fr-mx-16v, .fr-mx-8w {
  margin-left: 4rem !important;
}

.fr-mr-16v, .fr-mr-8w,
.fr-mx-16v, .fr-mx-8w {
  margin-right: 4rem !important;
}

.fr-mt-16v, .fr-mt-8w,
.fr-my-16v, .fr-my-8w {
  margin-top: 4rem !important;
}

.fr-mb-16v, .fr-mb-8w,
.fr-my-16v, .fr-my-8w {
  margin-bottom: 4rem !important;
}

.fr-m-17v {
  margin: 4.25rem !important;
}

.fr-ml-17v,
.fr-mx-17v {
  margin-left: 4.25rem !important;
}

.fr-mr-17v,
.fr-mx-17v {
  margin-right: 4.25rem !important;
}

.fr-mt-17v,
.fr-my-17v {
  margin-top: 4.25rem !important;
}

.fr-mb-17v,
.fr-my-17v {
  margin-bottom: 4.25rem !important;
}

.fr-m-18v, .fr-m-9w {
  margin: 4.5rem !important;
}

.fr-ml-18v, .fr-ml-9w,
.fr-mx-18v, .fr-mx-9w {
  margin-left: 4.5rem !important;
}

.fr-mr-18v, .fr-mr-9w,
.fr-mx-18v, .fr-mx-9w {
  margin-right: 4.5rem !important;
}

.fr-mt-18v, .fr-mt-9w,
.fr-my-18v, .fr-my-9w {
  margin-top: 4.5rem !important;
}

.fr-mb-18v, .fr-mb-9w,
.fr-my-18v, .fr-my-9w {
  margin-bottom: 4.5rem !important;
}

.fr-m-19v {
  margin: 4.75rem !important;
}

.fr-ml-19v,
.fr-mx-19v {
  margin-left: 4.75rem !important;
}

.fr-mr-19v,
.fr-mx-19v {
  margin-right: 4.75rem !important;
}

.fr-mt-19v,
.fr-my-19v {
  margin-top: 4.75rem !important;
}

.fr-mb-19v,
.fr-my-19v {
  margin-bottom: 4.75rem !important;
}

.fr-m-20v, .fr-m-10w {
  margin: 5rem !important;
}

.fr-ml-20v, .fr-ml-10w,
.fr-mx-20v, .fr-mx-10w {
  margin-left: 5rem !important;
}

.fr-mr-20v, .fr-mr-10w,
.fr-mx-20v, .fr-mx-10w {
  margin-right: 5rem !important;
}

.fr-mt-20v, .fr-mt-10w,
.fr-my-20v, .fr-my-10w {
  margin-top: 5rem !important;
}

.fr-mb-20v, .fr-mb-10w,
.fr-my-20v, .fr-my-10w {
  margin-bottom: 5rem !important;
}

.fr-m-21v {
  margin: 5.25rem !important;
}

.fr-ml-21v,
.fr-mx-21v {
  margin-left: 5.25rem !important;
}

.fr-mr-21v,
.fr-mx-21v {
  margin-right: 5.25rem !important;
}

.fr-mt-21v,
.fr-my-21v {
  margin-top: 5.25rem !important;
}

.fr-mb-21v,
.fr-my-21v {
  margin-bottom: 5.25rem !important;
}

.fr-m-22v, .fr-m-11w {
  margin: 5.5rem !important;
}

.fr-ml-22v, .fr-ml-11w,
.fr-mx-22v, .fr-mx-11w {
  margin-left: 5.5rem !important;
}

.fr-mr-22v, .fr-mr-11w,
.fr-mx-22v, .fr-mx-11w {
  margin-right: 5.5rem !important;
}

.fr-mt-22v, .fr-mt-11w,
.fr-my-22v, .fr-my-11w {
  margin-top: 5.5rem !important;
}

.fr-mb-22v, .fr-mb-11w,
.fr-my-22v, .fr-my-11w {
  margin-bottom: 5.5rem !important;
}

.fr-m-23v {
  margin: 5.75rem !important;
}

.fr-ml-23v,
.fr-mx-23v {
  margin-left: 5.75rem !important;
}

.fr-mr-23v,
.fr-mx-23v {
  margin-right: 5.75rem !important;
}

.fr-mt-23v,
.fr-my-23v {
  margin-top: 5.75rem !important;
}

.fr-mb-23v,
.fr-my-23v {
  margin-bottom: 5.75rem !important;
}

.fr-m-24v, .fr-m-12w {
  margin: 6rem !important;
}

.fr-ml-24v, .fr-ml-12w,
.fr-mx-24v, .fr-mx-12w {
  margin-left: 6rem !important;
}

.fr-mr-24v, .fr-mr-12w,
.fr-mx-24v, .fr-mx-12w {
  margin-right: 6rem !important;
}

.fr-mt-24v, .fr-mt-12w,
.fr-my-24v, .fr-my-12w {
  margin-top: 6rem !important;
}

.fr-mb-24v, .fr-mb-12w,
.fr-my-24v, .fr-my-12w {
  margin-bottom: 6rem !important;
}

.fr-m-25v {
  margin: 6.25rem !important;
}

.fr-ml-25v,
.fr-mx-25v {
  margin-left: 6.25rem !important;
}

.fr-mr-25v,
.fr-mx-25v {
  margin-right: 6.25rem !important;
}

.fr-mt-25v,
.fr-my-25v {
  margin-top: 6.25rem !important;
}

.fr-mb-25v,
.fr-my-25v {
  margin-bottom: 6.25rem !important;
}

.fr-m-26v, .fr-m-13w {
  margin: 6.5rem !important;
}

.fr-ml-26v, .fr-ml-13w,
.fr-mx-26v, .fr-mx-13w {
  margin-left: 6.5rem !important;
}

.fr-mr-26v, .fr-mr-13w,
.fr-mx-26v, .fr-mx-13w {
  margin-right: 6.5rem !important;
}

.fr-mt-26v, .fr-mt-13w,
.fr-my-26v, .fr-my-13w {
  margin-top: 6.5rem !important;
}

.fr-mb-26v, .fr-mb-13w,
.fr-my-26v, .fr-my-13w {
  margin-bottom: 6.5rem !important;
}

.fr-m-27v {
  margin: 6.75rem !important;
}

.fr-ml-27v,
.fr-mx-27v {
  margin-left: 6.75rem !important;
}

.fr-mr-27v,
.fr-mx-27v {
  margin-right: 6.75rem !important;
}

.fr-mt-27v,
.fr-my-27v {
  margin-top: 6.75rem !important;
}

.fr-mb-27v,
.fr-my-27v {
  margin-bottom: 6.75rem !important;
}

.fr-m-28v, .fr-m-14w {
  margin: 7rem !important;
}

.fr-ml-28v, .fr-ml-14w,
.fr-mx-28v, .fr-mx-14w {
  margin-left: 7rem !important;
}

.fr-mr-28v, .fr-mr-14w,
.fr-mx-28v, .fr-mx-14w {
  margin-right: 7rem !important;
}

.fr-mt-28v, .fr-mt-14w,
.fr-my-28v, .fr-my-14w {
  margin-top: 7rem !important;
}

.fr-mb-28v, .fr-mb-14w,
.fr-my-28v, .fr-my-14w {
  margin-bottom: 7rem !important;
}

.fr-m-29v {
  margin: 7.25rem !important;
}

.fr-ml-29v,
.fr-mx-29v {
  margin-left: 7.25rem !important;
}

.fr-mr-29v,
.fr-mx-29v {
  margin-right: 7.25rem !important;
}

.fr-mt-29v,
.fr-my-29v {
  margin-top: 7.25rem !important;
}

.fr-mb-29v,
.fr-my-29v {
  margin-bottom: 7.25rem !important;
}

.fr-m-30v, .fr-m-15w {
  margin: 7.5rem !important;
}

.fr-ml-30v, .fr-ml-15w,
.fr-mx-30v, .fr-mx-15w {
  margin-left: 7.5rem !important;
}

.fr-mr-30v, .fr-mr-15w,
.fr-mx-30v, .fr-mx-15w {
  margin-right: 7.5rem !important;
}

.fr-mt-30v, .fr-mt-15w,
.fr-my-30v, .fr-my-15w {
  margin-top: 7.5rem !important;
}

.fr-mb-30v, .fr-mb-15w,
.fr-my-30v, .fr-my-15w {
  margin-bottom: 7.5rem !important;
}

.fr-m-31v {
  margin: 7.75rem !important;
}

.fr-ml-31v,
.fr-mx-31v {
  margin-left: 7.75rem !important;
}

.fr-mr-31v,
.fr-mx-31v {
  margin-right: 7.75rem !important;
}

.fr-mt-31v,
.fr-my-31v {
  margin-top: 7.75rem !important;
}

.fr-mb-31v,
.fr-my-31v {
  margin-bottom: 7.75rem !important;
}

.fr-m-32v, .fr-m-16w {
  margin: 8rem !important;
}

.fr-ml-32v, .fr-ml-16w,
.fr-mx-32v, .fr-mx-16w {
  margin-left: 8rem !important;
}

.fr-mr-32v, .fr-mr-16w,
.fr-mx-32v, .fr-mx-16w {
  margin-right: 8rem !important;
}

.fr-mt-32v, .fr-mt-16w,
.fr-my-32v, .fr-my-16w {
  margin-top: 8rem !important;
}

.fr-mb-32v, .fr-mb-16w,
.fr-my-32v, .fr-my-16w {
  margin-bottom: 8rem !important;
}

.fr-m-auto {
  margin: auto;
}

.fr-ml-auto,
.fr-mx-auto {
  margin-left: auto;
}

.fr-mr-auto,
.fr-mx-auto {
  margin-right: auto;
}

.fr-mt-auto,
.fr-my-auto {
  margin-top: auto;
}

.fr-mb-auto,
.fr-my-auto {
  margin-bottom: auto;
}

.fr-m-first-n8v, .fr-m-first-n4w {
  margin: -2rem !important;
}

.fr-ml-first-n8v, .fr-ml-first-n4w,
.fr-mx-first-n8v, .fr-mx-first-n4w {
  margin-left: -2rem !important;
}

.fr-mr-first-n8v, .fr-mr-first-n4w,
.fr-mx-first-n8v, .fr-mx-first-n4w {
  margin-right: -2rem !important;
}

.fr-mt-first-n8v, .fr-mt-first-n4w,
.fr-my-first-n8v, .fr-my-first-n4w {
  margin-top: -2rem !important;
}

.fr-mb-first-n8v, .fr-mb-first-n4w,
.fr-my-first-n8v, .fr-my-first-n4w {
  margin-bottom: -2rem !important;
}

.fr-m-first-n7v {
  margin: -1.75rem !important;
}

.fr-ml-first-n7v,
.fr-mx-first-n7v {
  margin-left: -1.75rem !important;
}

.fr-mr-first-n7v,
.fr-mx-first-n7v {
  margin-right: -1.75rem !important;
}

.fr-mt-first-n7v,
.fr-my-first-n7v {
  margin-top: -1.75rem !important;
}

.fr-mb-first-n7v,
.fr-my-first-n7v {
  margin-bottom: -1.75rem !important;
}

.fr-m-first-n6v, .fr-m-first-n3w {
  margin: -1.5rem !important;
}

.fr-ml-first-n6v, .fr-ml-first-n3w,
.fr-mx-first-n6v, .fr-mx-first-n3w {
  margin-left: -1.5rem !important;
}

.fr-mr-first-n6v, .fr-mr-first-n3w,
.fr-mx-first-n6v, .fr-mx-first-n3w {
  margin-right: -1.5rem !important;
}

.fr-mt-first-n6v, .fr-mt-first-n3w,
.fr-my-first-n6v, .fr-my-first-n3w {
  margin-top: -1.5rem !important;
}

.fr-mb-first-n6v, .fr-mb-first-n3w,
.fr-my-first-n6v, .fr-my-first-n3w {
  margin-bottom: -1.5rem !important;
}

.fr-m-first-n5v {
  margin: -1.25rem !important;
}

.fr-ml-first-n5v,
.fr-mx-first-n5v {
  margin-left: -1.25rem !important;
}

.fr-mr-first-n5v,
.fr-mx-first-n5v {
  margin-right: -1.25rem !important;
}

.fr-mt-first-n5v,
.fr-my-first-n5v {
  margin-top: -1.25rem !important;
}

.fr-mb-first-n5v,
.fr-my-first-n5v {
  margin-bottom: -1.25rem !important;
}

.fr-m-first-n4v, .fr-m-first-n2w {
  margin: -1rem !important;
}

.fr-ml-first-n4v, .fr-ml-first-n2w,
.fr-mx-first-n4v, .fr-mx-first-n2w {
  margin-left: -1rem !important;
}

.fr-mr-first-n4v, .fr-mr-first-n2w,
.fr-mx-first-n4v, .fr-mx-first-n2w {
  margin-right: -1rem !important;
}

.fr-mt-first-n4v, .fr-mt-first-n2w,
.fr-my-first-n4v, .fr-my-first-n2w {
  margin-top: -1rem !important;
}

.fr-mb-first-n4v, .fr-mb-first-n2w,
.fr-my-first-n4v, .fr-my-first-n2w {
  margin-bottom: -1rem !important;
}

.fr-m-first-n3v {
  margin: -0.75rem !important;
}

.fr-ml-first-n3v,
.fr-mx-first-n3v {
  margin-left: -0.75rem !important;
}

.fr-mr-first-n3v,
.fr-mx-first-n3v {
  margin-right: -0.75rem !important;
}

.fr-mt-first-n3v,
.fr-my-first-n3v {
  margin-top: -0.75rem !important;
}

.fr-mb-first-n3v,
.fr-my-first-n3v {
  margin-bottom: -0.75rem !important;
}

.fr-m-first-n2v, .fr-m-first-n1w {
  margin: -0.5rem !important;
}

.fr-ml-first-n2v, .fr-ml-first-n1w,
.fr-mx-first-n2v, .fr-mx-first-n1w {
  margin-left: -0.5rem !important;
}

.fr-mr-first-n2v, .fr-mr-first-n1w,
.fr-mx-first-n2v, .fr-mx-first-n1w {
  margin-right: -0.5rem !important;
}

.fr-mt-first-n2v, .fr-mt-first-n1w,
.fr-my-first-n2v, .fr-my-first-n1w {
  margin-top: -0.5rem !important;
}

.fr-mb-first-n2v, .fr-mb-first-n1w,
.fr-my-first-n2v, .fr-my-first-n1w {
  margin-bottom: -0.5rem !important;
}

.fr-m-first-n1v {
  margin: -0.25rem !important;
}

.fr-ml-first-n1v,
.fr-mx-first-n1v {
  margin-left: -0.25rem !important;
}

.fr-mr-first-n1v,
.fr-mx-first-n1v {
  margin-right: -0.25rem !important;
}

.fr-mt-first-n1v,
.fr-my-first-n1v {
  margin-top: -0.25rem !important;
}

.fr-mb-first-n1v,
.fr-my-first-n1v {
  margin-bottom: -0.25rem !important;
}

.fr-m-first-n1-5v {
  margin: -0.375rem !important;
}

.fr-ml-first-n1-5v,
.fr-mx-first-n1-5v {
  margin-left: -0.375rem !important;
}

.fr-mr-first-n1-5v,
.fr-mx-first-n1-5v {
  margin-right: -0.375rem !important;
}

.fr-mt-first-n1-5v,
.fr-my-first-n1-5v {
  margin-top: -0.375rem !important;
}

.fr-mb-first-n1-5v,
.fr-my-first-n1-5v {
  margin-bottom: -0.375rem !important;
}

.fr-m-first-0 {
  margin: 0rem !important;
}

.fr-ml-first-0,
.fr-mx-first-0 {
  margin-left: 0rem !important;
}

.fr-mr-first-0,
.fr-mx-first-0 {
  margin-right: 0rem !important;
}

.fr-mt-first-0,
.fr-my-first-0 {
  margin-top: 0rem !important;
}

.fr-mb-first-0,
.fr-my-first-0 {
  margin-bottom: 0rem !important;
}

.fr-m-first-n0-5v {
  margin: -0.125rem !important;
}

.fr-ml-first-n0-5v,
.fr-mx-first-n0-5v {
  margin-left: -0.125rem !important;
}

.fr-mr-first-n0-5v,
.fr-mx-first-n0-5v {
  margin-right: -0.125rem !important;
}

.fr-mt-first-n0-5v,
.fr-my-first-n0-5v {
  margin-top: -0.125rem !important;
}

.fr-mb-first-n0-5v,
.fr-my-first-n0-5v {
  margin-bottom: -0.125rem !important;
}

.fr-m-first-0-5v {
  margin: 0.125rem !important;
}

.fr-ml-first-0-5v,
.fr-mx-first-0-5v {
  margin-left: 0.125rem !important;
}

.fr-mr-first-0-5v,
.fr-mx-first-0-5v {
  margin-right: 0.125rem !important;
}

.fr-mt-first-0-5v,
.fr-my-first-0-5v {
  margin-top: 0.125rem !important;
}

.fr-mb-first-0-5v,
.fr-my-first-0-5v {
  margin-bottom: 0.125rem !important;
}

.fr-m-first-1v {
  margin: 0.25rem !important;
}

.fr-ml-first-1v,
.fr-mx-first-1v {
  margin-left: 0.25rem !important;
}

.fr-mr-first-1v,
.fr-mx-first-1v {
  margin-right: 0.25rem !important;
}

.fr-mt-first-1v,
.fr-my-first-1v {
  margin-top: 0.25rem !important;
}

.fr-mb-first-1v,
.fr-my-first-1v {
  margin-bottom: 0.25rem !important;
}

.fr-m-first-1-5v {
  margin: 0.375rem !important;
}

.fr-ml-first-1-5v,
.fr-mx-first-1-5v {
  margin-left: 0.375rem !important;
}

.fr-mr-first-1-5v,
.fr-mx-first-1-5v {
  margin-right: 0.375rem !important;
}

.fr-mt-first-1-5v,
.fr-my-first-1-5v {
  margin-top: 0.375rem !important;
}

.fr-mb-first-1-5v,
.fr-my-first-1-5v {
  margin-bottom: 0.375rem !important;
}

.fr-m-first-2v, .fr-m-first-1w {
  margin: 0.5rem !important;
}

.fr-ml-first-2v, .fr-ml-first-1w,
.fr-mx-first-2v, .fr-mx-first-1w {
  margin-left: 0.5rem !important;
}

.fr-mr-first-2v, .fr-mr-first-1w,
.fr-mx-first-2v, .fr-mx-first-1w {
  margin-right: 0.5rem !important;
}

.fr-mt-first-2v, .fr-mt-first-1w,
.fr-my-first-2v, .fr-my-first-1w {
  margin-top: 0.5rem !important;
}

.fr-mb-first-2v, .fr-mb-first-1w,
.fr-my-first-2v, .fr-my-first-1w {
  margin-bottom: 0.5rem !important;
}

.fr-m-first-3v {
  margin: 0.75rem !important;
}

.fr-ml-first-3v,
.fr-mx-first-3v {
  margin-left: 0.75rem !important;
}

.fr-mr-first-3v,
.fr-mx-first-3v {
  margin-right: 0.75rem !important;
}

.fr-mt-first-3v,
.fr-my-first-3v {
  margin-top: 0.75rem !important;
}

.fr-mb-first-3v,
.fr-my-first-3v {
  margin-bottom: 0.75rem !important;
}

.fr-m-first-4v, .fr-m-first-2w {
  margin: 1rem !important;
}

.fr-ml-first-4v, .fr-ml-first-2w,
.fr-mx-first-4v, .fr-mx-first-2w {
  margin-left: 1rem !important;
}

.fr-mr-first-4v, .fr-mr-first-2w,
.fr-mx-first-4v, .fr-mx-first-2w {
  margin-right: 1rem !important;
}

.fr-mt-first-4v, .fr-mt-first-2w,
.fr-my-first-4v, .fr-my-first-2w {
  margin-top: 1rem !important;
}

.fr-mb-first-4v, .fr-mb-first-2w,
.fr-my-first-4v, .fr-my-first-2w {
  margin-bottom: 1rem !important;
}

.fr-m-first-5v {
  margin: 1.25rem !important;
}

.fr-ml-first-5v,
.fr-mx-first-5v {
  margin-left: 1.25rem !important;
}

.fr-mr-first-5v,
.fr-mx-first-5v {
  margin-right: 1.25rem !important;
}

.fr-mt-first-5v,
.fr-my-first-5v {
  margin-top: 1.25rem !important;
}

.fr-mb-first-5v,
.fr-my-first-5v {
  margin-bottom: 1.25rem !important;
}

.fr-m-first-6v, .fr-m-first-3w {
  margin: 1.5rem !important;
}

.fr-ml-first-6v, .fr-ml-first-3w,
.fr-mx-first-6v, .fr-mx-first-3w {
  margin-left: 1.5rem !important;
}

.fr-mr-first-6v, .fr-mr-first-3w,
.fr-mx-first-6v, .fr-mx-first-3w {
  margin-right: 1.5rem !important;
}

.fr-mt-first-6v, .fr-mt-first-3w,
.fr-my-first-6v, .fr-my-first-3w {
  margin-top: 1.5rem !important;
}

.fr-mb-first-6v, .fr-mb-first-3w,
.fr-my-first-6v, .fr-my-first-3w {
  margin-bottom: 1.5rem !important;
}

.fr-m-first-7v {
  margin: 1.75rem !important;
}

.fr-ml-first-7v,
.fr-mx-first-7v {
  margin-left: 1.75rem !important;
}

.fr-mr-first-7v,
.fr-mx-first-7v {
  margin-right: 1.75rem !important;
}

.fr-mt-first-7v,
.fr-my-first-7v {
  margin-top: 1.75rem !important;
}

.fr-mb-first-7v,
.fr-my-first-7v {
  margin-bottom: 1.75rem !important;
}

.fr-m-first-8v, .fr-m-first-4w {
  margin: 2rem !important;
}

.fr-ml-first-8v, .fr-ml-first-4w,
.fr-mx-first-8v, .fr-mx-first-4w {
  margin-left: 2rem !important;
}

.fr-mr-first-8v, .fr-mr-first-4w,
.fr-mx-first-8v, .fr-mx-first-4w {
  margin-right: 2rem !important;
}

.fr-mt-first-8v, .fr-mt-first-4w,
.fr-my-first-8v, .fr-my-first-4w {
  margin-top: 2rem !important;
}

.fr-mb-first-8v, .fr-mb-first-4w,
.fr-my-first-8v, .fr-my-first-4w {
  margin-bottom: 2rem !important;
}

.fr-m-first-9v {
  margin: 2.25rem !important;
}

.fr-ml-first-9v,
.fr-mx-first-9v {
  margin-left: 2.25rem !important;
}

.fr-mr-first-9v,
.fr-mx-first-9v {
  margin-right: 2.25rem !important;
}

.fr-mt-first-9v,
.fr-my-first-9v {
  margin-top: 2.25rem !important;
}

.fr-mb-first-9v,
.fr-my-first-9v {
  margin-bottom: 2.25rem !important;
}

.fr-m-first-10v, .fr-m-first-5w {
  margin: 2.5rem !important;
}

.fr-ml-first-10v, .fr-ml-first-5w,
.fr-mx-first-10v, .fr-mx-first-5w {
  margin-left: 2.5rem !important;
}

.fr-mr-first-10v, .fr-mr-first-5w,
.fr-mx-first-10v, .fr-mx-first-5w {
  margin-right: 2.5rem !important;
}

.fr-mt-first-10v, .fr-mt-first-5w,
.fr-my-first-10v, .fr-my-first-5w {
  margin-top: 2.5rem !important;
}

.fr-mb-first-10v, .fr-mb-first-5w,
.fr-my-first-10v, .fr-my-first-5w {
  margin-bottom: 2.5rem !important;
}

.fr-m-first-11v {
  margin: 2.75rem !important;
}

.fr-ml-first-11v,
.fr-mx-first-11v {
  margin-left: 2.75rem !important;
}

.fr-mr-first-11v,
.fr-mx-first-11v {
  margin-right: 2.75rem !important;
}

.fr-mt-first-11v,
.fr-my-first-11v {
  margin-top: 2.75rem !important;
}

.fr-mb-first-11v,
.fr-my-first-11v {
  margin-bottom: 2.75rem !important;
}

.fr-m-first-12v, .fr-m-first-6w {
  margin: 3rem !important;
}

.fr-ml-first-12v, .fr-ml-first-6w,
.fr-mx-first-12v, .fr-mx-first-6w {
  margin-left: 3rem !important;
}

.fr-mr-first-12v, .fr-mr-first-6w,
.fr-mx-first-12v, .fr-mx-first-6w {
  margin-right: 3rem !important;
}

.fr-mt-first-12v, .fr-mt-first-6w,
.fr-my-first-12v, .fr-my-first-6w {
  margin-top: 3rem !important;
}

.fr-mb-first-12v, .fr-mb-first-6w,
.fr-my-first-12v, .fr-my-first-6w {
  margin-bottom: 3rem !important;
}

.fr-m-first-13v {
  margin: 3.25rem !important;
}

.fr-ml-first-13v,
.fr-mx-first-13v {
  margin-left: 3.25rem !important;
}

.fr-mr-first-13v,
.fr-mx-first-13v {
  margin-right: 3.25rem !important;
}

.fr-mt-first-13v,
.fr-my-first-13v {
  margin-top: 3.25rem !important;
}

.fr-mb-first-13v,
.fr-my-first-13v {
  margin-bottom: 3.25rem !important;
}

.fr-m-first-14v, .fr-m-first-7w {
  margin: 3.5rem !important;
}

.fr-ml-first-14v, .fr-ml-first-7w,
.fr-mx-first-14v, .fr-mx-first-7w {
  margin-left: 3.5rem !important;
}

.fr-mr-first-14v, .fr-mr-first-7w,
.fr-mx-first-14v, .fr-mx-first-7w {
  margin-right: 3.5rem !important;
}

.fr-mt-first-14v, .fr-mt-first-7w,
.fr-my-first-14v, .fr-my-first-7w {
  margin-top: 3.5rem !important;
}

.fr-mb-first-14v, .fr-mb-first-7w,
.fr-my-first-14v, .fr-my-first-7w {
  margin-bottom: 3.5rem !important;
}

.fr-m-first-15v {
  margin: 3.75rem !important;
}

.fr-ml-first-15v,
.fr-mx-first-15v {
  margin-left: 3.75rem !important;
}

.fr-mr-first-15v,
.fr-mx-first-15v {
  margin-right: 3.75rem !important;
}

.fr-mt-first-15v,
.fr-my-first-15v {
  margin-top: 3.75rem !important;
}

.fr-mb-first-15v,
.fr-my-first-15v {
  margin-bottom: 3.75rem !important;
}

.fr-m-first-16v, .fr-m-first-8w {
  margin: 4rem !important;
}

.fr-ml-first-16v, .fr-ml-first-8w,
.fr-mx-first-16v, .fr-mx-first-8w {
  margin-left: 4rem !important;
}

.fr-mr-first-16v, .fr-mr-first-8w,
.fr-mx-first-16v, .fr-mx-first-8w {
  margin-right: 4rem !important;
}

.fr-mt-first-16v, .fr-mt-first-8w,
.fr-my-first-16v, .fr-my-first-8w {
  margin-top: 4rem !important;
}

.fr-mb-first-16v, .fr-mb-first-8w,
.fr-my-first-16v, .fr-my-first-8w {
  margin-bottom: 4rem !important;
}

.fr-m-first-17v {
  margin: 4.25rem !important;
}

.fr-ml-first-17v,
.fr-mx-first-17v {
  margin-left: 4.25rem !important;
}

.fr-mr-first-17v,
.fr-mx-first-17v {
  margin-right: 4.25rem !important;
}

.fr-mt-first-17v,
.fr-my-first-17v {
  margin-top: 4.25rem !important;
}

.fr-mb-first-17v,
.fr-my-first-17v {
  margin-bottom: 4.25rem !important;
}

.fr-m-first-18v, .fr-m-first-9w {
  margin: 4.5rem !important;
}

.fr-ml-first-18v, .fr-ml-first-9w,
.fr-mx-first-18v, .fr-mx-first-9w {
  margin-left: 4.5rem !important;
}

.fr-mr-first-18v, .fr-mr-first-9w,
.fr-mx-first-18v, .fr-mx-first-9w {
  margin-right: 4.5rem !important;
}

.fr-mt-first-18v, .fr-mt-first-9w,
.fr-my-first-18v, .fr-my-first-9w {
  margin-top: 4.5rem !important;
}

.fr-mb-first-18v, .fr-mb-first-9w,
.fr-my-first-18v, .fr-my-first-9w {
  margin-bottom: 4.5rem !important;
}

.fr-m-first-19v {
  margin: 4.75rem !important;
}

.fr-ml-first-19v,
.fr-mx-first-19v {
  margin-left: 4.75rem !important;
}

.fr-mr-first-19v,
.fr-mx-first-19v {
  margin-right: 4.75rem !important;
}

.fr-mt-first-19v,
.fr-my-first-19v {
  margin-top: 4.75rem !important;
}

.fr-mb-first-19v,
.fr-my-first-19v {
  margin-bottom: 4.75rem !important;
}

.fr-m-first-20v, .fr-m-first-10w {
  margin: 5rem !important;
}

.fr-ml-first-20v, .fr-ml-first-10w,
.fr-mx-first-20v, .fr-mx-first-10w {
  margin-left: 5rem !important;
}

.fr-mr-first-20v, .fr-mr-first-10w,
.fr-mx-first-20v, .fr-mx-first-10w {
  margin-right: 5rem !important;
}

.fr-mt-first-20v, .fr-mt-first-10w,
.fr-my-first-20v, .fr-my-first-10w {
  margin-top: 5rem !important;
}

.fr-mb-first-20v, .fr-mb-first-10w,
.fr-my-first-20v, .fr-my-first-10w {
  margin-bottom: 5rem !important;
}

.fr-m-first-21v {
  margin: 5.25rem !important;
}

.fr-ml-first-21v,
.fr-mx-first-21v {
  margin-left: 5.25rem !important;
}

.fr-mr-first-21v,
.fr-mx-first-21v {
  margin-right: 5.25rem !important;
}

.fr-mt-first-21v,
.fr-my-first-21v {
  margin-top: 5.25rem !important;
}

.fr-mb-first-21v,
.fr-my-first-21v {
  margin-bottom: 5.25rem !important;
}

.fr-m-first-22v, .fr-m-first-11w {
  margin: 5.5rem !important;
}

.fr-ml-first-22v, .fr-ml-first-11w,
.fr-mx-first-22v, .fr-mx-first-11w {
  margin-left: 5.5rem !important;
}

.fr-mr-first-22v, .fr-mr-first-11w,
.fr-mx-first-22v, .fr-mx-first-11w {
  margin-right: 5.5rem !important;
}

.fr-mt-first-22v, .fr-mt-first-11w,
.fr-my-first-22v, .fr-my-first-11w {
  margin-top: 5.5rem !important;
}

.fr-mb-first-22v, .fr-mb-first-11w,
.fr-my-first-22v, .fr-my-first-11w {
  margin-bottom: 5.5rem !important;
}

.fr-m-first-23v {
  margin: 5.75rem !important;
}

.fr-ml-first-23v,
.fr-mx-first-23v {
  margin-left: 5.75rem !important;
}

.fr-mr-first-23v,
.fr-mx-first-23v {
  margin-right: 5.75rem !important;
}

.fr-mt-first-23v,
.fr-my-first-23v {
  margin-top: 5.75rem !important;
}

.fr-mb-first-23v,
.fr-my-first-23v {
  margin-bottom: 5.75rem !important;
}

.fr-m-first-24v, .fr-m-first-12w {
  margin: 6rem !important;
}

.fr-ml-first-24v, .fr-ml-first-12w,
.fr-mx-first-24v, .fr-mx-first-12w {
  margin-left: 6rem !important;
}

.fr-mr-first-24v, .fr-mr-first-12w,
.fr-mx-first-24v, .fr-mx-first-12w {
  margin-right: 6rem !important;
}

.fr-mt-first-24v, .fr-mt-first-12w,
.fr-my-first-24v, .fr-my-first-12w {
  margin-top: 6rem !important;
}

.fr-mb-first-24v, .fr-mb-first-12w,
.fr-my-first-24v, .fr-my-first-12w {
  margin-bottom: 6rem !important;
}

.fr-m-first-25v {
  margin: 6.25rem !important;
}

.fr-ml-first-25v,
.fr-mx-first-25v {
  margin-left: 6.25rem !important;
}

.fr-mr-first-25v,
.fr-mx-first-25v {
  margin-right: 6.25rem !important;
}

.fr-mt-first-25v,
.fr-my-first-25v {
  margin-top: 6.25rem !important;
}

.fr-mb-first-25v,
.fr-my-first-25v {
  margin-bottom: 6.25rem !important;
}

.fr-m-first-26v, .fr-m-first-13w {
  margin: 6.5rem !important;
}

.fr-ml-first-26v, .fr-ml-first-13w,
.fr-mx-first-26v, .fr-mx-first-13w {
  margin-left: 6.5rem !important;
}

.fr-mr-first-26v, .fr-mr-first-13w,
.fr-mx-first-26v, .fr-mx-first-13w {
  margin-right: 6.5rem !important;
}

.fr-mt-first-26v, .fr-mt-first-13w,
.fr-my-first-26v, .fr-my-first-13w {
  margin-top: 6.5rem !important;
}

.fr-mb-first-26v, .fr-mb-first-13w,
.fr-my-first-26v, .fr-my-first-13w {
  margin-bottom: 6.5rem !important;
}

.fr-m-first-27v {
  margin: 6.75rem !important;
}

.fr-ml-first-27v,
.fr-mx-first-27v {
  margin-left: 6.75rem !important;
}

.fr-mr-first-27v,
.fr-mx-first-27v {
  margin-right: 6.75rem !important;
}

.fr-mt-first-27v,
.fr-my-first-27v {
  margin-top: 6.75rem !important;
}

.fr-mb-first-27v,
.fr-my-first-27v {
  margin-bottom: 6.75rem !important;
}

.fr-m-first-28v, .fr-m-first-14w {
  margin: 7rem !important;
}

.fr-ml-first-28v, .fr-ml-first-14w,
.fr-mx-first-28v, .fr-mx-first-14w {
  margin-left: 7rem !important;
}

.fr-mr-first-28v, .fr-mr-first-14w,
.fr-mx-first-28v, .fr-mx-first-14w {
  margin-right: 7rem !important;
}

.fr-mt-first-28v, .fr-mt-first-14w,
.fr-my-first-28v, .fr-my-first-14w {
  margin-top: 7rem !important;
}

.fr-mb-first-28v, .fr-mb-first-14w,
.fr-my-first-28v, .fr-my-first-14w {
  margin-bottom: 7rem !important;
}

.fr-m-first-29v {
  margin: 7.25rem !important;
}

.fr-ml-first-29v,
.fr-mx-first-29v {
  margin-left: 7.25rem !important;
}

.fr-mr-first-29v,
.fr-mx-first-29v {
  margin-right: 7.25rem !important;
}

.fr-mt-first-29v,
.fr-my-first-29v {
  margin-top: 7.25rem !important;
}

.fr-mb-first-29v,
.fr-my-first-29v {
  margin-bottom: 7.25rem !important;
}

.fr-m-first-30v, .fr-m-first-15w {
  margin: 7.5rem !important;
}

.fr-ml-first-30v, .fr-ml-first-15w,
.fr-mx-first-30v, .fr-mx-first-15w {
  margin-left: 7.5rem !important;
}

.fr-mr-first-30v, .fr-mr-first-15w,
.fr-mx-first-30v, .fr-mx-first-15w {
  margin-right: 7.5rem !important;
}

.fr-mt-first-30v, .fr-mt-first-15w,
.fr-my-first-30v, .fr-my-first-15w {
  margin-top: 7.5rem !important;
}

.fr-mb-first-30v, .fr-mb-first-15w,
.fr-my-first-30v, .fr-my-first-15w {
  margin-bottom: 7.5rem !important;
}

.fr-m-first-31v {
  margin: 7.75rem !important;
}

.fr-ml-first-31v,
.fr-mx-first-31v {
  margin-left: 7.75rem !important;
}

.fr-mr-first-31v,
.fr-mx-first-31v {
  margin-right: 7.75rem !important;
}

.fr-mt-first-31v,
.fr-my-first-31v {
  margin-top: 7.75rem !important;
}

.fr-mb-first-31v,
.fr-my-first-31v {
  margin-bottom: 7.75rem !important;
}

.fr-m-first-32v, .fr-m-first-16w {
  margin: 8rem !important;
}

.fr-ml-first-32v, .fr-ml-first-16w,
.fr-mx-first-32v, .fr-mx-first-16w {
  margin-left: 8rem !important;
}

.fr-mr-first-32v, .fr-mr-first-16w,
.fr-mx-first-32v, .fr-mx-first-16w {
  margin-right: 8rem !important;
}

.fr-mt-first-32v, .fr-mt-first-16w,
.fr-my-first-32v, .fr-my-first-16w {
  margin-top: 8rem !important;
}

.fr-mb-first-32v, .fr-mb-first-16w,
.fr-my-first-32v, .fr-my-first-16w {
  margin-bottom: 8rem !important;
}

.fr-m-first-auto {
  margin: auto;
}

.fr-ml-first-auto,
.fr-mx-first-auto {
  margin-left: auto;
}

.fr-mr-first-auto,
.fr-mx-first-auto {
  margin-right: auto;
}

.fr-mt-first-auto,
.fr-my-first-auto {
  margin-top: auto;
}

.fr-mb-first-auto,
.fr-my-first-auto {
  margin-bottom: auto;
}

@media (min-width: 48em) {
  .fr-m-md-n8v, .fr-m-md-n4w {
    margin: -2rem !important;
  }
  .fr-ml-md-n8v, .fr-ml-md-n4w,
  .fr-mx-md-n8v, .fr-mx-md-n4w {
    margin-left: -2rem !important;
  }
  .fr-mr-md-n8v, .fr-mr-md-n4w,
  .fr-mx-md-n8v, .fr-mx-md-n4w {
    margin-right: -2rem !important;
  }
  .fr-mt-md-n8v, .fr-mt-md-n4w,
  .fr-my-md-n8v, .fr-my-md-n4w {
    margin-top: -2rem !important;
  }
  .fr-mb-md-n8v, .fr-mb-md-n4w,
  .fr-my-md-n8v, .fr-my-md-n4w {
    margin-bottom: -2rem !important;
  }
  .fr-m-md-n7v {
    margin: -1.75rem !important;
  }
  .fr-ml-md-n7v,
  .fr-mx-md-n7v {
    margin-left: -1.75rem !important;
  }
  .fr-mr-md-n7v,
  .fr-mx-md-n7v {
    margin-right: -1.75rem !important;
  }
  .fr-mt-md-n7v,
  .fr-my-md-n7v {
    margin-top: -1.75rem !important;
  }
  .fr-mb-md-n7v,
  .fr-my-md-n7v {
    margin-bottom: -1.75rem !important;
  }
  .fr-m-md-n6v, .fr-m-md-n3w {
    margin: -1.5rem !important;
  }
  .fr-ml-md-n6v, .fr-ml-md-n3w,
  .fr-mx-md-n6v, .fr-mx-md-n3w {
    margin-left: -1.5rem !important;
  }
  .fr-mr-md-n6v, .fr-mr-md-n3w,
  .fr-mx-md-n6v, .fr-mx-md-n3w {
    margin-right: -1.5rem !important;
  }
  .fr-mt-md-n6v, .fr-mt-md-n3w,
  .fr-my-md-n6v, .fr-my-md-n3w {
    margin-top: -1.5rem !important;
  }
  .fr-mb-md-n6v, .fr-mb-md-n3w,
  .fr-my-md-n6v, .fr-my-md-n3w {
    margin-bottom: -1.5rem !important;
  }
  .fr-m-md-n5v {
    margin: -1.25rem !important;
  }
  .fr-ml-md-n5v,
  .fr-mx-md-n5v {
    margin-left: -1.25rem !important;
  }
  .fr-mr-md-n5v,
  .fr-mx-md-n5v {
    margin-right: -1.25rem !important;
  }
  .fr-mt-md-n5v,
  .fr-my-md-n5v {
    margin-top: -1.25rem !important;
  }
  .fr-mb-md-n5v,
  .fr-my-md-n5v {
    margin-bottom: -1.25rem !important;
  }
  .fr-m-md-n4v, .fr-m-md-n2w {
    margin: -1rem !important;
  }
  .fr-ml-md-n4v, .fr-ml-md-n2w,
  .fr-mx-md-n4v, .fr-mx-md-n2w {
    margin-left: -1rem !important;
  }
  .fr-mr-md-n4v, .fr-mr-md-n2w,
  .fr-mx-md-n4v, .fr-mx-md-n2w {
    margin-right: -1rem !important;
  }
  .fr-mt-md-n4v, .fr-mt-md-n2w,
  .fr-my-md-n4v, .fr-my-md-n2w {
    margin-top: -1rem !important;
  }
  .fr-mb-md-n4v, .fr-mb-md-n2w,
  .fr-my-md-n4v, .fr-my-md-n2w {
    margin-bottom: -1rem !important;
  }
  .fr-m-md-n3v {
    margin: -0.75rem !important;
  }
  .fr-ml-md-n3v,
  .fr-mx-md-n3v {
    margin-left: -0.75rem !important;
  }
  .fr-mr-md-n3v,
  .fr-mx-md-n3v {
    margin-right: -0.75rem !important;
  }
  .fr-mt-md-n3v,
  .fr-my-md-n3v {
    margin-top: -0.75rem !important;
  }
  .fr-mb-md-n3v,
  .fr-my-md-n3v {
    margin-bottom: -0.75rem !important;
  }
  .fr-m-md-n2v, .fr-m-md-n1w {
    margin: -0.5rem !important;
  }
  .fr-ml-md-n2v, .fr-ml-md-n1w,
  .fr-mx-md-n2v, .fr-mx-md-n1w {
    margin-left: -0.5rem !important;
  }
  .fr-mr-md-n2v, .fr-mr-md-n1w,
  .fr-mx-md-n2v, .fr-mx-md-n1w {
    margin-right: -0.5rem !important;
  }
  .fr-mt-md-n2v, .fr-mt-md-n1w,
  .fr-my-md-n2v, .fr-my-md-n1w {
    margin-top: -0.5rem !important;
  }
  .fr-mb-md-n2v, .fr-mb-md-n1w,
  .fr-my-md-n2v, .fr-my-md-n1w {
    margin-bottom: -0.5rem !important;
  }
  .fr-m-md-n1v {
    margin: -0.25rem !important;
  }
  .fr-ml-md-n1v,
  .fr-mx-md-n1v {
    margin-left: -0.25rem !important;
  }
  .fr-mr-md-n1v,
  .fr-mx-md-n1v {
    margin-right: -0.25rem !important;
  }
  .fr-mt-md-n1v,
  .fr-my-md-n1v {
    margin-top: -0.25rem !important;
  }
  .fr-mb-md-n1v,
  .fr-my-md-n1v {
    margin-bottom: -0.25rem !important;
  }
  .fr-m-md-n1-5v {
    margin: -0.375rem !important;
  }
  .fr-ml-md-n1-5v,
  .fr-mx-md-n1-5v {
    margin-left: -0.375rem !important;
  }
  .fr-mr-md-n1-5v,
  .fr-mx-md-n1-5v {
    margin-right: -0.375rem !important;
  }
  .fr-mt-md-n1-5v,
  .fr-my-md-n1-5v {
    margin-top: -0.375rem !important;
  }
  .fr-mb-md-n1-5v,
  .fr-my-md-n1-5v {
    margin-bottom: -0.375rem !important;
  }
  .fr-m-md-0 {
    margin: 0rem !important;
  }
  .fr-ml-md-0,
  .fr-mx-md-0 {
    margin-left: 0rem !important;
  }
  .fr-mr-md-0,
  .fr-mx-md-0 {
    margin-right: 0rem !important;
  }
  .fr-mt-md-0,
  .fr-my-md-0 {
    margin-top: 0rem !important;
  }
  .fr-mb-md-0,
  .fr-my-md-0 {
    margin-bottom: 0rem !important;
  }
  .fr-m-md-n0-5v {
    margin: -0.125rem !important;
  }
  .fr-ml-md-n0-5v,
  .fr-mx-md-n0-5v {
    margin-left: -0.125rem !important;
  }
  .fr-mr-md-n0-5v,
  .fr-mx-md-n0-5v {
    margin-right: -0.125rem !important;
  }
  .fr-mt-md-n0-5v,
  .fr-my-md-n0-5v {
    margin-top: -0.125rem !important;
  }
  .fr-mb-md-n0-5v,
  .fr-my-md-n0-5v {
    margin-bottom: -0.125rem !important;
  }
  .fr-m-md-0-5v {
    margin: 0.125rem !important;
  }
  .fr-ml-md-0-5v,
  .fr-mx-md-0-5v {
    margin-left: 0.125rem !important;
  }
  .fr-mr-md-0-5v,
  .fr-mx-md-0-5v {
    margin-right: 0.125rem !important;
  }
  .fr-mt-md-0-5v,
  .fr-my-md-0-5v {
    margin-top: 0.125rem !important;
  }
  .fr-mb-md-0-5v,
  .fr-my-md-0-5v {
    margin-bottom: 0.125rem !important;
  }
  .fr-m-md-1v {
    margin: 0.25rem !important;
  }
  .fr-ml-md-1v,
  .fr-mx-md-1v {
    margin-left: 0.25rem !important;
  }
  .fr-mr-md-1v,
  .fr-mx-md-1v {
    margin-right: 0.25rem !important;
  }
  .fr-mt-md-1v,
  .fr-my-md-1v {
    margin-top: 0.25rem !important;
  }
  .fr-mb-md-1v,
  .fr-my-md-1v {
    margin-bottom: 0.25rem !important;
  }
  .fr-m-md-1-5v {
    margin: 0.375rem !important;
  }
  .fr-ml-md-1-5v,
  .fr-mx-md-1-5v {
    margin-left: 0.375rem !important;
  }
  .fr-mr-md-1-5v,
  .fr-mx-md-1-5v {
    margin-right: 0.375rem !important;
  }
  .fr-mt-md-1-5v,
  .fr-my-md-1-5v {
    margin-top: 0.375rem !important;
  }
  .fr-mb-md-1-5v,
  .fr-my-md-1-5v {
    margin-bottom: 0.375rem !important;
  }
  .fr-m-md-2v, .fr-m-md-1w {
    margin: 0.5rem !important;
  }
  .fr-ml-md-2v, .fr-ml-md-1w,
  .fr-mx-md-2v, .fr-mx-md-1w {
    margin-left: 0.5rem !important;
  }
  .fr-mr-md-2v, .fr-mr-md-1w,
  .fr-mx-md-2v, .fr-mx-md-1w {
    margin-right: 0.5rem !important;
  }
  .fr-mt-md-2v, .fr-mt-md-1w,
  .fr-my-md-2v, .fr-my-md-1w {
    margin-top: 0.5rem !important;
  }
  .fr-mb-md-2v, .fr-mb-md-1w,
  .fr-my-md-2v, .fr-my-md-1w {
    margin-bottom: 0.5rem !important;
  }
  .fr-m-md-3v {
    margin: 0.75rem !important;
  }
  .fr-ml-md-3v,
  .fr-mx-md-3v {
    margin-left: 0.75rem !important;
  }
  .fr-mr-md-3v,
  .fr-mx-md-3v {
    margin-right: 0.75rem !important;
  }
  .fr-mt-md-3v,
  .fr-my-md-3v {
    margin-top: 0.75rem !important;
  }
  .fr-mb-md-3v,
  .fr-my-md-3v {
    margin-bottom: 0.75rem !important;
  }
  .fr-m-md-4v, .fr-m-md-2w {
    margin: 1rem !important;
  }
  .fr-ml-md-4v, .fr-ml-md-2w,
  .fr-mx-md-4v, .fr-mx-md-2w {
    margin-left: 1rem !important;
  }
  .fr-mr-md-4v, .fr-mr-md-2w,
  .fr-mx-md-4v, .fr-mx-md-2w {
    margin-right: 1rem !important;
  }
  .fr-mt-md-4v, .fr-mt-md-2w,
  .fr-my-md-4v, .fr-my-md-2w {
    margin-top: 1rem !important;
  }
  .fr-mb-md-4v, .fr-mb-md-2w,
  .fr-my-md-4v, .fr-my-md-2w {
    margin-bottom: 1rem !important;
  }
  .fr-m-md-5v {
    margin: 1.25rem !important;
  }
  .fr-ml-md-5v,
  .fr-mx-md-5v {
    margin-left: 1.25rem !important;
  }
  .fr-mr-md-5v,
  .fr-mx-md-5v {
    margin-right: 1.25rem !important;
  }
  .fr-mt-md-5v,
  .fr-my-md-5v {
    margin-top: 1.25rem !important;
  }
  .fr-mb-md-5v,
  .fr-my-md-5v {
    margin-bottom: 1.25rem !important;
  }
  .fr-m-md-6v, .fr-m-md-3w {
    margin: 1.5rem !important;
  }
  .fr-ml-md-6v, .fr-ml-md-3w,
  .fr-mx-md-6v, .fr-mx-md-3w {
    margin-left: 1.5rem !important;
  }
  .fr-mr-md-6v, .fr-mr-md-3w,
  .fr-mx-md-6v, .fr-mx-md-3w {
    margin-right: 1.5rem !important;
  }
  .fr-mt-md-6v, .fr-mt-md-3w,
  .fr-my-md-6v, .fr-my-md-3w {
    margin-top: 1.5rem !important;
  }
  .fr-mb-md-6v, .fr-mb-md-3w,
  .fr-my-md-6v, .fr-my-md-3w {
    margin-bottom: 1.5rem !important;
  }
  .fr-m-md-7v {
    margin: 1.75rem !important;
  }
  .fr-ml-md-7v,
  .fr-mx-md-7v {
    margin-left: 1.75rem !important;
  }
  .fr-mr-md-7v,
  .fr-mx-md-7v {
    margin-right: 1.75rem !important;
  }
  .fr-mt-md-7v,
  .fr-my-md-7v {
    margin-top: 1.75rem !important;
  }
  .fr-mb-md-7v,
  .fr-my-md-7v {
    margin-bottom: 1.75rem !important;
  }
  .fr-m-md-8v, .fr-m-md-4w {
    margin: 2rem !important;
  }
  .fr-ml-md-8v, .fr-ml-md-4w,
  .fr-mx-md-8v, .fr-mx-md-4w {
    margin-left: 2rem !important;
  }
  .fr-mr-md-8v, .fr-mr-md-4w,
  .fr-mx-md-8v, .fr-mx-md-4w {
    margin-right: 2rem !important;
  }
  .fr-mt-md-8v, .fr-mt-md-4w,
  .fr-my-md-8v, .fr-my-md-4w {
    margin-top: 2rem !important;
  }
  .fr-mb-md-8v, .fr-mb-md-4w,
  .fr-my-md-8v, .fr-my-md-4w {
    margin-bottom: 2rem !important;
  }
  .fr-m-md-9v {
    margin: 2.25rem !important;
  }
  .fr-ml-md-9v,
  .fr-mx-md-9v {
    margin-left: 2.25rem !important;
  }
  .fr-mr-md-9v,
  .fr-mx-md-9v {
    margin-right: 2.25rem !important;
  }
  .fr-mt-md-9v,
  .fr-my-md-9v {
    margin-top: 2.25rem !important;
  }
  .fr-mb-md-9v,
  .fr-my-md-9v {
    margin-bottom: 2.25rem !important;
  }
  .fr-m-md-10v, .fr-m-md-5w {
    margin: 2.5rem !important;
  }
  .fr-ml-md-10v, .fr-ml-md-5w,
  .fr-mx-md-10v, .fr-mx-md-5w {
    margin-left: 2.5rem !important;
  }
  .fr-mr-md-10v, .fr-mr-md-5w,
  .fr-mx-md-10v, .fr-mx-md-5w {
    margin-right: 2.5rem !important;
  }
  .fr-mt-md-10v, .fr-mt-md-5w,
  .fr-my-md-10v, .fr-my-md-5w {
    margin-top: 2.5rem !important;
  }
  .fr-mb-md-10v, .fr-mb-md-5w,
  .fr-my-md-10v, .fr-my-md-5w {
    margin-bottom: 2.5rem !important;
  }
  .fr-m-md-11v {
    margin: 2.75rem !important;
  }
  .fr-ml-md-11v,
  .fr-mx-md-11v {
    margin-left: 2.75rem !important;
  }
  .fr-mr-md-11v,
  .fr-mx-md-11v {
    margin-right: 2.75rem !important;
  }
  .fr-mt-md-11v,
  .fr-my-md-11v {
    margin-top: 2.75rem !important;
  }
  .fr-mb-md-11v,
  .fr-my-md-11v {
    margin-bottom: 2.75rem !important;
  }
  .fr-m-md-12v, .fr-m-md-6w {
    margin: 3rem !important;
  }
  .fr-ml-md-12v, .fr-ml-md-6w,
  .fr-mx-md-12v, .fr-mx-md-6w {
    margin-left: 3rem !important;
  }
  .fr-mr-md-12v, .fr-mr-md-6w,
  .fr-mx-md-12v, .fr-mx-md-6w {
    margin-right: 3rem !important;
  }
  .fr-mt-md-12v, .fr-mt-md-6w,
  .fr-my-md-12v, .fr-my-md-6w {
    margin-top: 3rem !important;
  }
  .fr-mb-md-12v, .fr-mb-md-6w,
  .fr-my-md-12v, .fr-my-md-6w {
    margin-bottom: 3rem !important;
  }
  .fr-m-md-13v {
    margin: 3.25rem !important;
  }
  .fr-ml-md-13v,
  .fr-mx-md-13v {
    margin-left: 3.25rem !important;
  }
  .fr-mr-md-13v,
  .fr-mx-md-13v {
    margin-right: 3.25rem !important;
  }
  .fr-mt-md-13v,
  .fr-my-md-13v {
    margin-top: 3.25rem !important;
  }
  .fr-mb-md-13v,
  .fr-my-md-13v {
    margin-bottom: 3.25rem !important;
  }
  .fr-m-md-14v, .fr-m-md-7w {
    margin: 3.5rem !important;
  }
  .fr-ml-md-14v, .fr-ml-md-7w,
  .fr-mx-md-14v, .fr-mx-md-7w {
    margin-left: 3.5rem !important;
  }
  .fr-mr-md-14v, .fr-mr-md-7w,
  .fr-mx-md-14v, .fr-mx-md-7w {
    margin-right: 3.5rem !important;
  }
  .fr-mt-md-14v, .fr-mt-md-7w,
  .fr-my-md-14v, .fr-my-md-7w {
    margin-top: 3.5rem !important;
  }
  .fr-mb-md-14v, .fr-mb-md-7w,
  .fr-my-md-14v, .fr-my-md-7w {
    margin-bottom: 3.5rem !important;
  }
  .fr-m-md-15v {
    margin: 3.75rem !important;
  }
  .fr-ml-md-15v,
  .fr-mx-md-15v {
    margin-left: 3.75rem !important;
  }
  .fr-mr-md-15v,
  .fr-mx-md-15v {
    margin-right: 3.75rem !important;
  }
  .fr-mt-md-15v,
  .fr-my-md-15v {
    margin-top: 3.75rem !important;
  }
  .fr-mb-md-15v,
  .fr-my-md-15v {
    margin-bottom: 3.75rem !important;
  }
  .fr-m-md-16v, .fr-m-md-8w {
    margin: 4rem !important;
  }
  .fr-ml-md-16v, .fr-ml-md-8w,
  .fr-mx-md-16v, .fr-mx-md-8w {
    margin-left: 4rem !important;
  }
  .fr-mr-md-16v, .fr-mr-md-8w,
  .fr-mx-md-16v, .fr-mx-md-8w {
    margin-right: 4rem !important;
  }
  .fr-mt-md-16v, .fr-mt-md-8w,
  .fr-my-md-16v, .fr-my-md-8w {
    margin-top: 4rem !important;
  }
  .fr-mb-md-16v, .fr-mb-md-8w,
  .fr-my-md-16v, .fr-my-md-8w {
    margin-bottom: 4rem !important;
  }
  .fr-m-md-17v {
    margin: 4.25rem !important;
  }
  .fr-ml-md-17v,
  .fr-mx-md-17v {
    margin-left: 4.25rem !important;
  }
  .fr-mr-md-17v,
  .fr-mx-md-17v {
    margin-right: 4.25rem !important;
  }
  .fr-mt-md-17v,
  .fr-my-md-17v {
    margin-top: 4.25rem !important;
  }
  .fr-mb-md-17v,
  .fr-my-md-17v {
    margin-bottom: 4.25rem !important;
  }
  .fr-m-md-18v, .fr-m-md-9w {
    margin: 4.5rem !important;
  }
  .fr-ml-md-18v, .fr-ml-md-9w,
  .fr-mx-md-18v, .fr-mx-md-9w {
    margin-left: 4.5rem !important;
  }
  .fr-mr-md-18v, .fr-mr-md-9w,
  .fr-mx-md-18v, .fr-mx-md-9w {
    margin-right: 4.5rem !important;
  }
  .fr-mt-md-18v, .fr-mt-md-9w,
  .fr-my-md-18v, .fr-my-md-9w {
    margin-top: 4.5rem !important;
  }
  .fr-mb-md-18v, .fr-mb-md-9w,
  .fr-my-md-18v, .fr-my-md-9w {
    margin-bottom: 4.5rem !important;
  }
  .fr-m-md-19v {
    margin: 4.75rem !important;
  }
  .fr-ml-md-19v,
  .fr-mx-md-19v {
    margin-left: 4.75rem !important;
  }
  .fr-mr-md-19v,
  .fr-mx-md-19v {
    margin-right: 4.75rem !important;
  }
  .fr-mt-md-19v,
  .fr-my-md-19v {
    margin-top: 4.75rem !important;
  }
  .fr-mb-md-19v,
  .fr-my-md-19v {
    margin-bottom: 4.75rem !important;
  }
  .fr-m-md-20v, .fr-m-md-10w {
    margin: 5rem !important;
  }
  .fr-ml-md-20v, .fr-ml-md-10w,
  .fr-mx-md-20v, .fr-mx-md-10w {
    margin-left: 5rem !important;
  }
  .fr-mr-md-20v, .fr-mr-md-10w,
  .fr-mx-md-20v, .fr-mx-md-10w {
    margin-right: 5rem !important;
  }
  .fr-mt-md-20v, .fr-mt-md-10w,
  .fr-my-md-20v, .fr-my-md-10w {
    margin-top: 5rem !important;
  }
  .fr-mb-md-20v, .fr-mb-md-10w,
  .fr-my-md-20v, .fr-my-md-10w {
    margin-bottom: 5rem !important;
  }
  .fr-m-md-21v {
    margin: 5.25rem !important;
  }
  .fr-ml-md-21v,
  .fr-mx-md-21v {
    margin-left: 5.25rem !important;
  }
  .fr-mr-md-21v,
  .fr-mx-md-21v {
    margin-right: 5.25rem !important;
  }
  .fr-mt-md-21v,
  .fr-my-md-21v {
    margin-top: 5.25rem !important;
  }
  .fr-mb-md-21v,
  .fr-my-md-21v {
    margin-bottom: 5.25rem !important;
  }
  .fr-m-md-22v, .fr-m-md-11w {
    margin: 5.5rem !important;
  }
  .fr-ml-md-22v, .fr-ml-md-11w,
  .fr-mx-md-22v, .fr-mx-md-11w {
    margin-left: 5.5rem !important;
  }
  .fr-mr-md-22v, .fr-mr-md-11w,
  .fr-mx-md-22v, .fr-mx-md-11w {
    margin-right: 5.5rem !important;
  }
  .fr-mt-md-22v, .fr-mt-md-11w,
  .fr-my-md-22v, .fr-my-md-11w {
    margin-top: 5.5rem !important;
  }
  .fr-mb-md-22v, .fr-mb-md-11w,
  .fr-my-md-22v, .fr-my-md-11w {
    margin-bottom: 5.5rem !important;
  }
  .fr-m-md-23v {
    margin: 5.75rem !important;
  }
  .fr-ml-md-23v,
  .fr-mx-md-23v {
    margin-left: 5.75rem !important;
  }
  .fr-mr-md-23v,
  .fr-mx-md-23v {
    margin-right: 5.75rem !important;
  }
  .fr-mt-md-23v,
  .fr-my-md-23v {
    margin-top: 5.75rem !important;
  }
  .fr-mb-md-23v,
  .fr-my-md-23v {
    margin-bottom: 5.75rem !important;
  }
  .fr-m-md-24v, .fr-m-md-12w {
    margin: 6rem !important;
  }
  .fr-ml-md-24v, .fr-ml-md-12w,
  .fr-mx-md-24v, .fr-mx-md-12w {
    margin-left: 6rem !important;
  }
  .fr-mr-md-24v, .fr-mr-md-12w,
  .fr-mx-md-24v, .fr-mx-md-12w {
    margin-right: 6rem !important;
  }
  .fr-mt-md-24v, .fr-mt-md-12w,
  .fr-my-md-24v, .fr-my-md-12w {
    margin-top: 6rem !important;
  }
  .fr-mb-md-24v, .fr-mb-md-12w,
  .fr-my-md-24v, .fr-my-md-12w {
    margin-bottom: 6rem !important;
  }
  .fr-m-md-25v {
    margin: 6.25rem !important;
  }
  .fr-ml-md-25v,
  .fr-mx-md-25v {
    margin-left: 6.25rem !important;
  }
  .fr-mr-md-25v,
  .fr-mx-md-25v {
    margin-right: 6.25rem !important;
  }
  .fr-mt-md-25v,
  .fr-my-md-25v {
    margin-top: 6.25rem !important;
  }
  .fr-mb-md-25v,
  .fr-my-md-25v {
    margin-bottom: 6.25rem !important;
  }
  .fr-m-md-26v, .fr-m-md-13w {
    margin: 6.5rem !important;
  }
  .fr-ml-md-26v, .fr-ml-md-13w,
  .fr-mx-md-26v, .fr-mx-md-13w {
    margin-left: 6.5rem !important;
  }
  .fr-mr-md-26v, .fr-mr-md-13w,
  .fr-mx-md-26v, .fr-mx-md-13w {
    margin-right: 6.5rem !important;
  }
  .fr-mt-md-26v, .fr-mt-md-13w,
  .fr-my-md-26v, .fr-my-md-13w {
    margin-top: 6.5rem !important;
  }
  .fr-mb-md-26v, .fr-mb-md-13w,
  .fr-my-md-26v, .fr-my-md-13w {
    margin-bottom: 6.5rem !important;
  }
  .fr-m-md-27v {
    margin: 6.75rem !important;
  }
  .fr-ml-md-27v,
  .fr-mx-md-27v {
    margin-left: 6.75rem !important;
  }
  .fr-mr-md-27v,
  .fr-mx-md-27v {
    margin-right: 6.75rem !important;
  }
  .fr-mt-md-27v,
  .fr-my-md-27v {
    margin-top: 6.75rem !important;
  }
  .fr-mb-md-27v,
  .fr-my-md-27v {
    margin-bottom: 6.75rem !important;
  }
  .fr-m-md-28v, .fr-m-md-14w {
    margin: 7rem !important;
  }
  .fr-ml-md-28v, .fr-ml-md-14w,
  .fr-mx-md-28v, .fr-mx-md-14w {
    margin-left: 7rem !important;
  }
  .fr-mr-md-28v, .fr-mr-md-14w,
  .fr-mx-md-28v, .fr-mx-md-14w {
    margin-right: 7rem !important;
  }
  .fr-mt-md-28v, .fr-mt-md-14w,
  .fr-my-md-28v, .fr-my-md-14w {
    margin-top: 7rem !important;
  }
  .fr-mb-md-28v, .fr-mb-md-14w,
  .fr-my-md-28v, .fr-my-md-14w {
    margin-bottom: 7rem !important;
  }
  .fr-m-md-29v {
    margin: 7.25rem !important;
  }
  .fr-ml-md-29v,
  .fr-mx-md-29v {
    margin-left: 7.25rem !important;
  }
  .fr-mr-md-29v,
  .fr-mx-md-29v {
    margin-right: 7.25rem !important;
  }
  .fr-mt-md-29v,
  .fr-my-md-29v {
    margin-top: 7.25rem !important;
  }
  .fr-mb-md-29v,
  .fr-my-md-29v {
    margin-bottom: 7.25rem !important;
  }
  .fr-m-md-30v, .fr-m-md-15w {
    margin: 7.5rem !important;
  }
  .fr-ml-md-30v, .fr-ml-md-15w,
  .fr-mx-md-30v, .fr-mx-md-15w {
    margin-left: 7.5rem !important;
  }
  .fr-mr-md-30v, .fr-mr-md-15w,
  .fr-mx-md-30v, .fr-mx-md-15w {
    margin-right: 7.5rem !important;
  }
  .fr-mt-md-30v, .fr-mt-md-15w,
  .fr-my-md-30v, .fr-my-md-15w {
    margin-top: 7.5rem !important;
  }
  .fr-mb-md-30v, .fr-mb-md-15w,
  .fr-my-md-30v, .fr-my-md-15w {
    margin-bottom: 7.5rem !important;
  }
  .fr-m-md-31v {
    margin: 7.75rem !important;
  }
  .fr-ml-md-31v,
  .fr-mx-md-31v {
    margin-left: 7.75rem !important;
  }
  .fr-mr-md-31v,
  .fr-mx-md-31v {
    margin-right: 7.75rem !important;
  }
  .fr-mt-md-31v,
  .fr-my-md-31v {
    margin-top: 7.75rem !important;
  }
  .fr-mb-md-31v,
  .fr-my-md-31v {
    margin-bottom: 7.75rem !important;
  }
  .fr-m-md-32v, .fr-m-md-16w {
    margin: 8rem !important;
  }
  .fr-ml-md-32v, .fr-ml-md-16w,
  .fr-mx-md-32v, .fr-mx-md-16w {
    margin-left: 8rem !important;
  }
  .fr-mr-md-32v, .fr-mr-md-16w,
  .fr-mx-md-32v, .fr-mx-md-16w {
    margin-right: 8rem !important;
  }
  .fr-mt-md-32v, .fr-mt-md-16w,
  .fr-my-md-32v, .fr-my-md-16w {
    margin-top: 8rem !important;
  }
  .fr-mb-md-32v, .fr-mb-md-16w,
  .fr-my-md-32v, .fr-my-md-16w {
    margin-bottom: 8rem !important;
  }
  .fr-m-md-auto {
    margin: auto;
  }
  .fr-ml-md-auto,
  .fr-mx-md-auto {
    margin-left: auto;
  }
  .fr-mr-md-auto,
  .fr-mx-md-auto {
    margin-right: auto;
  }
  .fr-mt-md-auto,
  .fr-my-md-auto {
    margin-top: auto;
  }
  .fr-mb-md-auto,
  .fr-my-md-auto {
    margin-bottom: auto;
  }
}

.fr-p-0 {
  padding: 0rem !important;
}

.fr-pl-0,
.fr-px-0 {
  padding-left: 0rem !important;
}

.fr-pr-0,
.fr-px-0 {
  padding-right: 0rem !important;
}

.fr-pt-0,
.fr-py-0 {
  padding-top: 0rem !important;
}

.fr-pb-0,
.fr-py-0 {
  padding-bottom: 0rem !important;
}

.fr-p-n0-5v {
  padding: -0.125rem !important;
}

.fr-pl-n0-5v,
.fr-px-n0-5v {
  padding-left: -0.125rem !important;
}

.fr-pr-n0-5v,
.fr-px-n0-5v {
  padding-right: -0.125rem !important;
}

.fr-pt-n0-5v,
.fr-py-n0-5v {
  padding-top: -0.125rem !important;
}

.fr-pb-n0-5v,
.fr-py-n0-5v {
  padding-bottom: -0.125rem !important;
}

.fr-p-0-5v {
  padding: 0.125rem !important;
}

.fr-pl-0-5v,
.fr-px-0-5v {
  padding-left: 0.125rem !important;
}

.fr-pr-0-5v,
.fr-px-0-5v {
  padding-right: 0.125rem !important;
}

.fr-pt-0-5v,
.fr-py-0-5v {
  padding-top: 0.125rem !important;
}

.fr-pb-0-5v,
.fr-py-0-5v {
  padding-bottom: 0.125rem !important;
}

.fr-p-1v {
  padding: 0.25rem !important;
}

.fr-pl-1v,
.fr-px-1v {
  padding-left: 0.25rem !important;
}

.fr-pr-1v,
.fr-px-1v {
  padding-right: 0.25rem !important;
}

.fr-pt-1v,
.fr-py-1v {
  padding-top: 0.25rem !important;
}

.fr-pb-1v,
.fr-py-1v {
  padding-bottom: 0.25rem !important;
}

.fr-p-1-5v {
  padding: 0.375rem !important;
}

.fr-pl-1-5v,
.fr-px-1-5v {
  padding-left: 0.375rem !important;
}

.fr-pr-1-5v,
.fr-px-1-5v {
  padding-right: 0.375rem !important;
}

.fr-pt-1-5v,
.fr-py-1-5v {
  padding-top: 0.375rem !important;
}

.fr-pb-1-5v,
.fr-py-1-5v {
  padding-bottom: 0.375rem !important;
}

.fr-p-2v, .fr-p-1w {
  padding: 0.5rem !important;
}

.fr-pl-2v, .fr-pl-1w,
.fr-px-2v, .fr-px-1w {
  padding-left: 0.5rem !important;
}

.fr-pr-2v, .fr-pr-1w,
.fr-px-2v, .fr-px-1w {
  padding-right: 0.5rem !important;
}

.fr-pt-2v, .fr-pt-1w,
.fr-py-2v, .fr-py-1w {
  padding-top: 0.5rem !important;
}

.fr-pb-2v, .fr-pb-1w,
.fr-py-2v, .fr-py-1w {
  padding-bottom: 0.5rem !important;
}

.fr-p-3v {
  padding: 0.75rem !important;
}

.fr-pl-3v,
.fr-px-3v {
  padding-left: 0.75rem !important;
}

.fr-pr-3v,
.fr-px-3v {
  padding-right: 0.75rem !important;
}

.fr-pt-3v,
.fr-py-3v {
  padding-top: 0.75rem !important;
}

.fr-pb-3v,
.fr-py-3v {
  padding-bottom: 0.75rem !important;
}

.fr-p-4v, .fr-p-2w {
  padding: 1rem !important;
}

.fr-pl-4v, .fr-pl-2w,
.fr-px-4v, .fr-px-2w {
  padding-left: 1rem !important;
}

.fr-pr-4v, .fr-pr-2w,
.fr-px-4v, .fr-px-2w {
  padding-right: 1rem !important;
}

.fr-pt-4v, .fr-pt-2w,
.fr-py-4v, .fr-py-2w {
  padding-top: 1rem !important;
}

.fr-pb-4v, .fr-pb-2w,
.fr-py-4v, .fr-py-2w {
  padding-bottom: 1rem !important;
}

.fr-p-5v {
  padding: 1.25rem !important;
}

.fr-pl-5v,
.fr-px-5v {
  padding-left: 1.25rem !important;
}

.fr-pr-5v,
.fr-px-5v {
  padding-right: 1.25rem !important;
}

.fr-pt-5v,
.fr-py-5v {
  padding-top: 1.25rem !important;
}

.fr-pb-5v,
.fr-py-5v {
  padding-bottom: 1.25rem !important;
}

.fr-p-6v, .fr-p-3w {
  padding: 1.5rem !important;
}

.fr-pl-6v, .fr-pl-3w,
.fr-px-6v, .fr-px-3w {
  padding-left: 1.5rem !important;
}

.fr-pr-6v, .fr-pr-3w,
.fr-px-6v, .fr-px-3w {
  padding-right: 1.5rem !important;
}

.fr-pt-6v, .fr-pt-3w,
.fr-py-6v, .fr-py-3w {
  padding-top: 1.5rem !important;
}

.fr-pb-6v, .fr-pb-3w,
.fr-py-6v, .fr-py-3w {
  padding-bottom: 1.5rem !important;
}

.fr-p-7v {
  padding: 1.75rem !important;
}

.fr-pl-7v,
.fr-px-7v {
  padding-left: 1.75rem !important;
}

.fr-pr-7v,
.fr-px-7v {
  padding-right: 1.75rem !important;
}

.fr-pt-7v,
.fr-py-7v {
  padding-top: 1.75rem !important;
}

.fr-pb-7v,
.fr-py-7v {
  padding-bottom: 1.75rem !important;
}

.fr-p-8v, .fr-p-4w {
  padding: 2rem !important;
}

.fr-pl-8v, .fr-pl-4w,
.fr-px-8v, .fr-px-4w {
  padding-left: 2rem !important;
}

.fr-pr-8v, .fr-pr-4w,
.fr-px-8v, .fr-px-4w {
  padding-right: 2rem !important;
}

.fr-pt-8v, .fr-pt-4w,
.fr-py-8v, .fr-py-4w {
  padding-top: 2rem !important;
}

.fr-pb-8v, .fr-pb-4w,
.fr-py-8v, .fr-py-4w {
  padding-bottom: 2rem !important;
}

.fr-p-9v {
  padding: 2.25rem !important;
}

.fr-pl-9v,
.fr-px-9v {
  padding-left: 2.25rem !important;
}

.fr-pr-9v,
.fr-px-9v {
  padding-right: 2.25rem !important;
}

.fr-pt-9v,
.fr-py-9v {
  padding-top: 2.25rem !important;
}

.fr-pb-9v,
.fr-py-9v {
  padding-bottom: 2.25rem !important;
}

.fr-p-10v, .fr-p-5w {
  padding: 2.5rem !important;
}

.fr-pl-10v, .fr-pl-5w,
.fr-px-10v, .fr-px-5w {
  padding-left: 2.5rem !important;
}

.fr-pr-10v, .fr-pr-5w,
.fr-px-10v, .fr-px-5w {
  padding-right: 2.5rem !important;
}

.fr-pt-10v, .fr-pt-5w,
.fr-py-10v, .fr-py-5w {
  padding-top: 2.5rem !important;
}

.fr-pb-10v, .fr-pb-5w,
.fr-py-10v, .fr-py-5w {
  padding-bottom: 2.5rem !important;
}

.fr-p-11v {
  padding: 2.75rem !important;
}

.fr-pl-11v,
.fr-px-11v {
  padding-left: 2.75rem !important;
}

.fr-pr-11v,
.fr-px-11v {
  padding-right: 2.75rem !important;
}

.fr-pt-11v,
.fr-py-11v {
  padding-top: 2.75rem !important;
}

.fr-pb-11v,
.fr-py-11v {
  padding-bottom: 2.75rem !important;
}

.fr-p-12v, .fr-p-6w {
  padding: 3rem !important;
}

.fr-pl-12v, .fr-pl-6w,
.fr-px-12v, .fr-px-6w {
  padding-left: 3rem !important;
}

.fr-pr-12v, .fr-pr-6w,
.fr-px-12v, .fr-px-6w {
  padding-right: 3rem !important;
}

.fr-pt-12v, .fr-pt-6w,
.fr-py-12v, .fr-py-6w {
  padding-top: 3rem !important;
}

.fr-pb-12v, .fr-pb-6w,
.fr-py-12v, .fr-py-6w {
  padding-bottom: 3rem !important;
}

.fr-p-13v {
  padding: 3.25rem !important;
}

.fr-pl-13v,
.fr-px-13v {
  padding-left: 3.25rem !important;
}

.fr-pr-13v,
.fr-px-13v {
  padding-right: 3.25rem !important;
}

.fr-pt-13v,
.fr-py-13v {
  padding-top: 3.25rem !important;
}

.fr-pb-13v,
.fr-py-13v {
  padding-bottom: 3.25rem !important;
}

.fr-p-14v, .fr-p-7w {
  padding: 3.5rem !important;
}

.fr-pl-14v, .fr-pl-7w,
.fr-px-14v, .fr-px-7w {
  padding-left: 3.5rem !important;
}

.fr-pr-14v, .fr-pr-7w,
.fr-px-14v, .fr-px-7w {
  padding-right: 3.5rem !important;
}

.fr-pt-14v, .fr-pt-7w,
.fr-py-14v, .fr-py-7w {
  padding-top: 3.5rem !important;
}

.fr-pb-14v, .fr-pb-7w,
.fr-py-14v, .fr-py-7w {
  padding-bottom: 3.5rem !important;
}

.fr-p-15v {
  padding: 3.75rem !important;
}

.fr-pl-15v,
.fr-px-15v {
  padding-left: 3.75rem !important;
}

.fr-pr-15v,
.fr-px-15v {
  padding-right: 3.75rem !important;
}

.fr-pt-15v,
.fr-py-15v {
  padding-top: 3.75rem !important;
}

.fr-pb-15v,
.fr-py-15v {
  padding-bottom: 3.75rem !important;
}

.fr-p-16v, .fr-p-8w {
  padding: 4rem !important;
}

.fr-pl-16v, .fr-pl-8w,
.fr-px-16v, .fr-px-8w {
  padding-left: 4rem !important;
}

.fr-pr-16v, .fr-pr-8w,
.fr-px-16v, .fr-px-8w {
  padding-right: 4rem !important;
}

.fr-pt-16v, .fr-pt-8w,
.fr-py-16v, .fr-py-8w {
  padding-top: 4rem !important;
}

.fr-pb-16v, .fr-pb-8w,
.fr-py-16v, .fr-py-8w {
  padding-bottom: 4rem !important;
}

.fr-p-17v {
  padding: 4.25rem !important;
}

.fr-pl-17v,
.fr-px-17v {
  padding-left: 4.25rem !important;
}

.fr-pr-17v,
.fr-px-17v {
  padding-right: 4.25rem !important;
}

.fr-pt-17v,
.fr-py-17v {
  padding-top: 4.25rem !important;
}

.fr-pb-17v,
.fr-py-17v {
  padding-bottom: 4.25rem !important;
}

.fr-p-18v, .fr-p-9w {
  padding: 4.5rem !important;
}

.fr-pl-18v, .fr-pl-9w,
.fr-px-18v, .fr-px-9w {
  padding-left: 4.5rem !important;
}

.fr-pr-18v, .fr-pr-9w,
.fr-px-18v, .fr-px-9w {
  padding-right: 4.5rem !important;
}

.fr-pt-18v, .fr-pt-9w,
.fr-py-18v, .fr-py-9w {
  padding-top: 4.5rem !important;
}

.fr-pb-18v, .fr-pb-9w,
.fr-py-18v, .fr-py-9w {
  padding-bottom: 4.5rem !important;
}

.fr-p-19v {
  padding: 4.75rem !important;
}

.fr-pl-19v,
.fr-px-19v {
  padding-left: 4.75rem !important;
}

.fr-pr-19v,
.fr-px-19v {
  padding-right: 4.75rem !important;
}

.fr-pt-19v,
.fr-py-19v {
  padding-top: 4.75rem !important;
}

.fr-pb-19v,
.fr-py-19v {
  padding-bottom: 4.75rem !important;
}

.fr-p-20v, .fr-p-10w {
  padding: 5rem !important;
}

.fr-pl-20v, .fr-pl-10w,
.fr-px-20v, .fr-px-10w {
  padding-left: 5rem !important;
}

.fr-pr-20v, .fr-pr-10w,
.fr-px-20v, .fr-px-10w {
  padding-right: 5rem !important;
}

.fr-pt-20v, .fr-pt-10w,
.fr-py-20v, .fr-py-10w {
  padding-top: 5rem !important;
}

.fr-pb-20v, .fr-pb-10w,
.fr-py-20v, .fr-py-10w {
  padding-bottom: 5rem !important;
}

.fr-p-21v {
  padding: 5.25rem !important;
}

.fr-pl-21v,
.fr-px-21v {
  padding-left: 5.25rem !important;
}

.fr-pr-21v,
.fr-px-21v {
  padding-right: 5.25rem !important;
}

.fr-pt-21v,
.fr-py-21v {
  padding-top: 5.25rem !important;
}

.fr-pb-21v,
.fr-py-21v {
  padding-bottom: 5.25rem !important;
}

.fr-p-22v, .fr-p-11w {
  padding: 5.5rem !important;
}

.fr-pl-22v, .fr-pl-11w,
.fr-px-22v, .fr-px-11w {
  padding-left: 5.5rem !important;
}

.fr-pr-22v, .fr-pr-11w,
.fr-px-22v, .fr-px-11w {
  padding-right: 5.5rem !important;
}

.fr-pt-22v, .fr-pt-11w,
.fr-py-22v, .fr-py-11w {
  padding-top: 5.5rem !important;
}

.fr-pb-22v, .fr-pb-11w,
.fr-py-22v, .fr-py-11w {
  padding-bottom: 5.5rem !important;
}

.fr-p-23v {
  padding: 5.75rem !important;
}

.fr-pl-23v,
.fr-px-23v {
  padding-left: 5.75rem !important;
}

.fr-pr-23v,
.fr-px-23v {
  padding-right: 5.75rem !important;
}

.fr-pt-23v,
.fr-py-23v {
  padding-top: 5.75rem !important;
}

.fr-pb-23v,
.fr-py-23v {
  padding-bottom: 5.75rem !important;
}

.fr-p-24v, .fr-p-12w {
  padding: 6rem !important;
}

.fr-pl-24v, .fr-pl-12w,
.fr-px-24v, .fr-px-12w {
  padding-left: 6rem !important;
}

.fr-pr-24v, .fr-pr-12w,
.fr-px-24v, .fr-px-12w {
  padding-right: 6rem !important;
}

.fr-pt-24v, .fr-pt-12w,
.fr-py-24v, .fr-py-12w {
  padding-top: 6rem !important;
}

.fr-pb-24v, .fr-pb-12w,
.fr-py-24v, .fr-py-12w {
  padding-bottom: 6rem !important;
}

.fr-p-25v {
  padding: 6.25rem !important;
}

.fr-pl-25v,
.fr-px-25v {
  padding-left: 6.25rem !important;
}

.fr-pr-25v,
.fr-px-25v {
  padding-right: 6.25rem !important;
}

.fr-pt-25v,
.fr-py-25v {
  padding-top: 6.25rem !important;
}

.fr-pb-25v,
.fr-py-25v {
  padding-bottom: 6.25rem !important;
}

.fr-p-26v, .fr-p-13w {
  padding: 6.5rem !important;
}

.fr-pl-26v, .fr-pl-13w,
.fr-px-26v, .fr-px-13w {
  padding-left: 6.5rem !important;
}

.fr-pr-26v, .fr-pr-13w,
.fr-px-26v, .fr-px-13w {
  padding-right: 6.5rem !important;
}

.fr-pt-26v, .fr-pt-13w,
.fr-py-26v, .fr-py-13w {
  padding-top: 6.5rem !important;
}

.fr-pb-26v, .fr-pb-13w,
.fr-py-26v, .fr-py-13w {
  padding-bottom: 6.5rem !important;
}

.fr-p-27v {
  padding: 6.75rem !important;
}

.fr-pl-27v,
.fr-px-27v {
  padding-left: 6.75rem !important;
}

.fr-pr-27v,
.fr-px-27v {
  padding-right: 6.75rem !important;
}

.fr-pt-27v,
.fr-py-27v {
  padding-top: 6.75rem !important;
}

.fr-pb-27v,
.fr-py-27v {
  padding-bottom: 6.75rem !important;
}

.fr-p-28v, .fr-p-14w {
  padding: 7rem !important;
}

.fr-pl-28v, .fr-pl-14w,
.fr-px-28v, .fr-px-14w {
  padding-left: 7rem !important;
}

.fr-pr-28v, .fr-pr-14w,
.fr-px-28v, .fr-px-14w {
  padding-right: 7rem !important;
}

.fr-pt-28v, .fr-pt-14w,
.fr-py-28v, .fr-py-14w {
  padding-top: 7rem !important;
}

.fr-pb-28v, .fr-pb-14w,
.fr-py-28v, .fr-py-14w {
  padding-bottom: 7rem !important;
}

.fr-p-29v {
  padding: 7.25rem !important;
}

.fr-pl-29v,
.fr-px-29v {
  padding-left: 7.25rem !important;
}

.fr-pr-29v,
.fr-px-29v {
  padding-right: 7.25rem !important;
}

.fr-pt-29v,
.fr-py-29v {
  padding-top: 7.25rem !important;
}

.fr-pb-29v,
.fr-py-29v {
  padding-bottom: 7.25rem !important;
}

.fr-p-30v, .fr-p-15w {
  padding: 7.5rem !important;
}

.fr-pl-30v, .fr-pl-15w,
.fr-px-30v, .fr-px-15w {
  padding-left: 7.5rem !important;
}

.fr-pr-30v, .fr-pr-15w,
.fr-px-30v, .fr-px-15w {
  padding-right: 7.5rem !important;
}

.fr-pt-30v, .fr-pt-15w,
.fr-py-30v, .fr-py-15w {
  padding-top: 7.5rem !important;
}

.fr-pb-30v, .fr-pb-15w,
.fr-py-30v, .fr-py-15w {
  padding-bottom: 7.5rem !important;
}

.fr-p-31v {
  padding: 7.75rem !important;
}

.fr-pl-31v,
.fr-px-31v {
  padding-left: 7.75rem !important;
}

.fr-pr-31v,
.fr-px-31v {
  padding-right: 7.75rem !important;
}

.fr-pt-31v,
.fr-py-31v {
  padding-top: 7.75rem !important;
}

.fr-pb-31v,
.fr-py-31v {
  padding-bottom: 7.75rem !important;
}

.fr-p-32v, .fr-p-16w {
  padding: 8rem !important;
}

.fr-pl-32v, .fr-pl-16w,
.fr-px-32v, .fr-px-16w {
  padding-left: 8rem !important;
}

.fr-pr-32v, .fr-pr-16w,
.fr-px-32v, .fr-px-16w {
  padding-right: 8rem !important;
}

.fr-pt-32v, .fr-pt-16w,
.fr-py-32v, .fr-py-16w {
  padding-top: 8rem !important;
}

.fr-pb-32v, .fr-pb-16w,
.fr-py-32v, .fr-py-16w {
  padding-bottom: 8rem !important;
}

.fr-p-first-0 {
  padding: 0rem !important;
}

.fr-pl-first-0,
.fr-px-first-0 {
  padding-left: 0rem !important;
}

.fr-pr-first-0,
.fr-px-first-0 {
  padding-right: 0rem !important;
}

.fr-pt-first-0,
.fr-py-first-0 {
  padding-top: 0rem !important;
}

.fr-pb-first-0,
.fr-py-first-0 {
  padding-bottom: 0rem !important;
}

.fr-p-first-n0-5v {
  padding: -0.125rem !important;
}

.fr-pl-first-n0-5v,
.fr-px-first-n0-5v {
  padding-left: -0.125rem !important;
}

.fr-pr-first-n0-5v,
.fr-px-first-n0-5v {
  padding-right: -0.125rem !important;
}

.fr-pt-first-n0-5v,
.fr-py-first-n0-5v {
  padding-top: -0.125rem !important;
}

.fr-pb-first-n0-5v,
.fr-py-first-n0-5v {
  padding-bottom: -0.125rem !important;
}

.fr-p-first-0-5v {
  padding: 0.125rem !important;
}

.fr-pl-first-0-5v,
.fr-px-first-0-5v {
  padding-left: 0.125rem !important;
}

.fr-pr-first-0-5v,
.fr-px-first-0-5v {
  padding-right: 0.125rem !important;
}

.fr-pt-first-0-5v,
.fr-py-first-0-5v {
  padding-top: 0.125rem !important;
}

.fr-pb-first-0-5v,
.fr-py-first-0-5v {
  padding-bottom: 0.125rem !important;
}

.fr-p-first-1v {
  padding: 0.25rem !important;
}

.fr-pl-first-1v,
.fr-px-first-1v {
  padding-left: 0.25rem !important;
}

.fr-pr-first-1v,
.fr-px-first-1v {
  padding-right: 0.25rem !important;
}

.fr-pt-first-1v,
.fr-py-first-1v {
  padding-top: 0.25rem !important;
}

.fr-pb-first-1v,
.fr-py-first-1v {
  padding-bottom: 0.25rem !important;
}

.fr-p-first-1-5v {
  padding: 0.375rem !important;
}

.fr-pl-first-1-5v,
.fr-px-first-1-5v {
  padding-left: 0.375rem !important;
}

.fr-pr-first-1-5v,
.fr-px-first-1-5v {
  padding-right: 0.375rem !important;
}

.fr-pt-first-1-5v,
.fr-py-first-1-5v {
  padding-top: 0.375rem !important;
}

.fr-pb-first-1-5v,
.fr-py-first-1-5v {
  padding-bottom: 0.375rem !important;
}

.fr-p-first-2v, .fr-p-first-1w {
  padding: 0.5rem !important;
}

.fr-pl-first-2v, .fr-pl-first-1w,
.fr-px-first-2v, .fr-px-first-1w {
  padding-left: 0.5rem !important;
}

.fr-pr-first-2v, .fr-pr-first-1w,
.fr-px-first-2v, .fr-px-first-1w {
  padding-right: 0.5rem !important;
}

.fr-pt-first-2v, .fr-pt-first-1w,
.fr-py-first-2v, .fr-py-first-1w {
  padding-top: 0.5rem !important;
}

.fr-pb-first-2v, .fr-pb-first-1w,
.fr-py-first-2v, .fr-py-first-1w {
  padding-bottom: 0.5rem !important;
}

.fr-p-first-3v {
  padding: 0.75rem !important;
}

.fr-pl-first-3v,
.fr-px-first-3v {
  padding-left: 0.75rem !important;
}

.fr-pr-first-3v,
.fr-px-first-3v {
  padding-right: 0.75rem !important;
}

.fr-pt-first-3v,
.fr-py-first-3v {
  padding-top: 0.75rem !important;
}

.fr-pb-first-3v,
.fr-py-first-3v {
  padding-bottom: 0.75rem !important;
}

.fr-p-first-4v, .fr-p-first-2w {
  padding: 1rem !important;
}

.fr-pl-first-4v, .fr-pl-first-2w,
.fr-px-first-4v, .fr-px-first-2w {
  padding-left: 1rem !important;
}

.fr-pr-first-4v, .fr-pr-first-2w,
.fr-px-first-4v, .fr-px-first-2w {
  padding-right: 1rem !important;
}

.fr-pt-first-4v, .fr-pt-first-2w,
.fr-py-first-4v, .fr-py-first-2w {
  padding-top: 1rem !important;
}

.fr-pb-first-4v, .fr-pb-first-2w,
.fr-py-first-4v, .fr-py-first-2w {
  padding-bottom: 1rem !important;
}

.fr-p-first-5v {
  padding: 1.25rem !important;
}

.fr-pl-first-5v,
.fr-px-first-5v {
  padding-left: 1.25rem !important;
}

.fr-pr-first-5v,
.fr-px-first-5v {
  padding-right: 1.25rem !important;
}

.fr-pt-first-5v,
.fr-py-first-5v {
  padding-top: 1.25rem !important;
}

.fr-pb-first-5v,
.fr-py-first-5v {
  padding-bottom: 1.25rem !important;
}

.fr-p-first-6v, .fr-p-first-3w {
  padding: 1.5rem !important;
}

.fr-pl-first-6v, .fr-pl-first-3w,
.fr-px-first-6v, .fr-px-first-3w {
  padding-left: 1.5rem !important;
}

.fr-pr-first-6v, .fr-pr-first-3w,
.fr-px-first-6v, .fr-px-first-3w {
  padding-right: 1.5rem !important;
}

.fr-pt-first-6v, .fr-pt-first-3w,
.fr-py-first-6v, .fr-py-first-3w {
  padding-top: 1.5rem !important;
}

.fr-pb-first-6v, .fr-pb-first-3w,
.fr-py-first-6v, .fr-py-first-3w {
  padding-bottom: 1.5rem !important;
}

.fr-p-first-7v {
  padding: 1.75rem !important;
}

.fr-pl-first-7v,
.fr-px-first-7v {
  padding-left: 1.75rem !important;
}

.fr-pr-first-7v,
.fr-px-first-7v {
  padding-right: 1.75rem !important;
}

.fr-pt-first-7v,
.fr-py-first-7v {
  padding-top: 1.75rem !important;
}

.fr-pb-first-7v,
.fr-py-first-7v {
  padding-bottom: 1.75rem !important;
}

.fr-p-first-8v, .fr-p-first-4w {
  padding: 2rem !important;
}

.fr-pl-first-8v, .fr-pl-first-4w,
.fr-px-first-8v, .fr-px-first-4w {
  padding-left: 2rem !important;
}

.fr-pr-first-8v, .fr-pr-first-4w,
.fr-px-first-8v, .fr-px-first-4w {
  padding-right: 2rem !important;
}

.fr-pt-first-8v, .fr-pt-first-4w,
.fr-py-first-8v, .fr-py-first-4w {
  padding-top: 2rem !important;
}

.fr-pb-first-8v, .fr-pb-first-4w,
.fr-py-first-8v, .fr-py-first-4w {
  padding-bottom: 2rem !important;
}

.fr-p-first-9v {
  padding: 2.25rem !important;
}

.fr-pl-first-9v,
.fr-px-first-9v {
  padding-left: 2.25rem !important;
}

.fr-pr-first-9v,
.fr-px-first-9v {
  padding-right: 2.25rem !important;
}

.fr-pt-first-9v,
.fr-py-first-9v {
  padding-top: 2.25rem !important;
}

.fr-pb-first-9v,
.fr-py-first-9v {
  padding-bottom: 2.25rem !important;
}

.fr-p-first-10v, .fr-p-first-5w {
  padding: 2.5rem !important;
}

.fr-pl-first-10v, .fr-pl-first-5w,
.fr-px-first-10v, .fr-px-first-5w {
  padding-left: 2.5rem !important;
}

.fr-pr-first-10v, .fr-pr-first-5w,
.fr-px-first-10v, .fr-px-first-5w {
  padding-right: 2.5rem !important;
}

.fr-pt-first-10v, .fr-pt-first-5w,
.fr-py-first-10v, .fr-py-first-5w {
  padding-top: 2.5rem !important;
}

.fr-pb-first-10v, .fr-pb-first-5w,
.fr-py-first-10v, .fr-py-first-5w {
  padding-bottom: 2.5rem !important;
}

.fr-p-first-11v {
  padding: 2.75rem !important;
}

.fr-pl-first-11v,
.fr-px-first-11v {
  padding-left: 2.75rem !important;
}

.fr-pr-first-11v,
.fr-px-first-11v {
  padding-right: 2.75rem !important;
}

.fr-pt-first-11v,
.fr-py-first-11v {
  padding-top: 2.75rem !important;
}

.fr-pb-first-11v,
.fr-py-first-11v {
  padding-bottom: 2.75rem !important;
}

.fr-p-first-12v, .fr-p-first-6w {
  padding: 3rem !important;
}

.fr-pl-first-12v, .fr-pl-first-6w,
.fr-px-first-12v, .fr-px-first-6w {
  padding-left: 3rem !important;
}

.fr-pr-first-12v, .fr-pr-first-6w,
.fr-px-first-12v, .fr-px-first-6w {
  padding-right: 3rem !important;
}

.fr-pt-first-12v, .fr-pt-first-6w,
.fr-py-first-12v, .fr-py-first-6w {
  padding-top: 3rem !important;
}

.fr-pb-first-12v, .fr-pb-first-6w,
.fr-py-first-12v, .fr-py-first-6w {
  padding-bottom: 3rem !important;
}

.fr-p-first-13v {
  padding: 3.25rem !important;
}

.fr-pl-first-13v,
.fr-px-first-13v {
  padding-left: 3.25rem !important;
}

.fr-pr-first-13v,
.fr-px-first-13v {
  padding-right: 3.25rem !important;
}

.fr-pt-first-13v,
.fr-py-first-13v {
  padding-top: 3.25rem !important;
}

.fr-pb-first-13v,
.fr-py-first-13v {
  padding-bottom: 3.25rem !important;
}

.fr-p-first-14v, .fr-p-first-7w {
  padding: 3.5rem !important;
}

.fr-pl-first-14v, .fr-pl-first-7w,
.fr-px-first-14v, .fr-px-first-7w {
  padding-left: 3.5rem !important;
}

.fr-pr-first-14v, .fr-pr-first-7w,
.fr-px-first-14v, .fr-px-first-7w {
  padding-right: 3.5rem !important;
}

.fr-pt-first-14v, .fr-pt-first-7w,
.fr-py-first-14v, .fr-py-first-7w {
  padding-top: 3.5rem !important;
}

.fr-pb-first-14v, .fr-pb-first-7w,
.fr-py-first-14v, .fr-py-first-7w {
  padding-bottom: 3.5rem !important;
}

.fr-p-first-15v {
  padding: 3.75rem !important;
}

.fr-pl-first-15v,
.fr-px-first-15v {
  padding-left: 3.75rem !important;
}

.fr-pr-first-15v,
.fr-px-first-15v {
  padding-right: 3.75rem !important;
}

.fr-pt-first-15v,
.fr-py-first-15v {
  padding-top: 3.75rem !important;
}

.fr-pb-first-15v,
.fr-py-first-15v {
  padding-bottom: 3.75rem !important;
}

.fr-p-first-16v, .fr-p-first-8w {
  padding: 4rem !important;
}

.fr-pl-first-16v, .fr-pl-first-8w,
.fr-px-first-16v, .fr-px-first-8w {
  padding-left: 4rem !important;
}

.fr-pr-first-16v, .fr-pr-first-8w,
.fr-px-first-16v, .fr-px-first-8w {
  padding-right: 4rem !important;
}

.fr-pt-first-16v, .fr-pt-first-8w,
.fr-py-first-16v, .fr-py-first-8w {
  padding-top: 4rem !important;
}

.fr-pb-first-16v, .fr-pb-first-8w,
.fr-py-first-16v, .fr-py-first-8w {
  padding-bottom: 4rem !important;
}

.fr-p-first-17v {
  padding: 4.25rem !important;
}

.fr-pl-first-17v,
.fr-px-first-17v {
  padding-left: 4.25rem !important;
}

.fr-pr-first-17v,
.fr-px-first-17v {
  padding-right: 4.25rem !important;
}

.fr-pt-first-17v,
.fr-py-first-17v {
  padding-top: 4.25rem !important;
}

.fr-pb-first-17v,
.fr-py-first-17v {
  padding-bottom: 4.25rem !important;
}

.fr-p-first-18v, .fr-p-first-9w {
  padding: 4.5rem !important;
}

.fr-pl-first-18v, .fr-pl-first-9w,
.fr-px-first-18v, .fr-px-first-9w {
  padding-left: 4.5rem !important;
}

.fr-pr-first-18v, .fr-pr-first-9w,
.fr-px-first-18v, .fr-px-first-9w {
  padding-right: 4.5rem !important;
}

.fr-pt-first-18v, .fr-pt-first-9w,
.fr-py-first-18v, .fr-py-first-9w {
  padding-top: 4.5rem !important;
}

.fr-pb-first-18v, .fr-pb-first-9w,
.fr-py-first-18v, .fr-py-first-9w {
  padding-bottom: 4.5rem !important;
}

.fr-p-first-19v {
  padding: 4.75rem !important;
}

.fr-pl-first-19v,
.fr-px-first-19v {
  padding-left: 4.75rem !important;
}

.fr-pr-first-19v,
.fr-px-first-19v {
  padding-right: 4.75rem !important;
}

.fr-pt-first-19v,
.fr-py-first-19v {
  padding-top: 4.75rem !important;
}

.fr-pb-first-19v,
.fr-py-first-19v {
  padding-bottom: 4.75rem !important;
}

.fr-p-first-20v, .fr-p-first-10w {
  padding: 5rem !important;
}

.fr-pl-first-20v, .fr-pl-first-10w,
.fr-px-first-20v, .fr-px-first-10w {
  padding-left: 5rem !important;
}

.fr-pr-first-20v, .fr-pr-first-10w,
.fr-px-first-20v, .fr-px-first-10w {
  padding-right: 5rem !important;
}

.fr-pt-first-20v, .fr-pt-first-10w,
.fr-py-first-20v, .fr-py-first-10w {
  padding-top: 5rem !important;
}

.fr-pb-first-20v, .fr-pb-first-10w,
.fr-py-first-20v, .fr-py-first-10w {
  padding-bottom: 5rem !important;
}

.fr-p-first-21v {
  padding: 5.25rem !important;
}

.fr-pl-first-21v,
.fr-px-first-21v {
  padding-left: 5.25rem !important;
}

.fr-pr-first-21v,
.fr-px-first-21v {
  padding-right: 5.25rem !important;
}

.fr-pt-first-21v,
.fr-py-first-21v {
  padding-top: 5.25rem !important;
}

.fr-pb-first-21v,
.fr-py-first-21v {
  padding-bottom: 5.25rem !important;
}

.fr-p-first-22v, .fr-p-first-11w {
  padding: 5.5rem !important;
}

.fr-pl-first-22v, .fr-pl-first-11w,
.fr-px-first-22v, .fr-px-first-11w {
  padding-left: 5.5rem !important;
}

.fr-pr-first-22v, .fr-pr-first-11w,
.fr-px-first-22v, .fr-px-first-11w {
  padding-right: 5.5rem !important;
}

.fr-pt-first-22v, .fr-pt-first-11w,
.fr-py-first-22v, .fr-py-first-11w {
  padding-top: 5.5rem !important;
}

.fr-pb-first-22v, .fr-pb-first-11w,
.fr-py-first-22v, .fr-py-first-11w {
  padding-bottom: 5.5rem !important;
}

.fr-p-first-23v {
  padding: 5.75rem !important;
}

.fr-pl-first-23v,
.fr-px-first-23v {
  padding-left: 5.75rem !important;
}

.fr-pr-first-23v,
.fr-px-first-23v {
  padding-right: 5.75rem !important;
}

.fr-pt-first-23v,
.fr-py-first-23v {
  padding-top: 5.75rem !important;
}

.fr-pb-first-23v,
.fr-py-first-23v {
  padding-bottom: 5.75rem !important;
}

.fr-p-first-24v, .fr-p-first-12w {
  padding: 6rem !important;
}

.fr-pl-first-24v, .fr-pl-first-12w,
.fr-px-first-24v, .fr-px-first-12w {
  padding-left: 6rem !important;
}

.fr-pr-first-24v, .fr-pr-first-12w,
.fr-px-first-24v, .fr-px-first-12w {
  padding-right: 6rem !important;
}

.fr-pt-first-24v, .fr-pt-first-12w,
.fr-py-first-24v, .fr-py-first-12w {
  padding-top: 6rem !important;
}

.fr-pb-first-24v, .fr-pb-first-12w,
.fr-py-first-24v, .fr-py-first-12w {
  padding-bottom: 6rem !important;
}

.fr-p-first-25v {
  padding: 6.25rem !important;
}

.fr-pl-first-25v,
.fr-px-first-25v {
  padding-left: 6.25rem !important;
}

.fr-pr-first-25v,
.fr-px-first-25v {
  padding-right: 6.25rem !important;
}

.fr-pt-first-25v,
.fr-py-first-25v {
  padding-top: 6.25rem !important;
}

.fr-pb-first-25v,
.fr-py-first-25v {
  padding-bottom: 6.25rem !important;
}

.fr-p-first-26v, .fr-p-first-13w {
  padding: 6.5rem !important;
}

.fr-pl-first-26v, .fr-pl-first-13w,
.fr-px-first-26v, .fr-px-first-13w {
  padding-left: 6.5rem !important;
}

.fr-pr-first-26v, .fr-pr-first-13w,
.fr-px-first-26v, .fr-px-first-13w {
  padding-right: 6.5rem !important;
}

.fr-pt-first-26v, .fr-pt-first-13w,
.fr-py-first-26v, .fr-py-first-13w {
  padding-top: 6.5rem !important;
}

.fr-pb-first-26v, .fr-pb-first-13w,
.fr-py-first-26v, .fr-py-first-13w {
  padding-bottom: 6.5rem !important;
}

.fr-p-first-27v {
  padding: 6.75rem !important;
}

.fr-pl-first-27v,
.fr-px-first-27v {
  padding-left: 6.75rem !important;
}

.fr-pr-first-27v,
.fr-px-first-27v {
  padding-right: 6.75rem !important;
}

.fr-pt-first-27v,
.fr-py-first-27v {
  padding-top: 6.75rem !important;
}

.fr-pb-first-27v,
.fr-py-first-27v {
  padding-bottom: 6.75rem !important;
}

.fr-p-first-28v, .fr-p-first-14w {
  padding: 7rem !important;
}

.fr-pl-first-28v, .fr-pl-first-14w,
.fr-px-first-28v, .fr-px-first-14w {
  padding-left: 7rem !important;
}

.fr-pr-first-28v, .fr-pr-first-14w,
.fr-px-first-28v, .fr-px-first-14w {
  padding-right: 7rem !important;
}

.fr-pt-first-28v, .fr-pt-first-14w,
.fr-py-first-28v, .fr-py-first-14w {
  padding-top: 7rem !important;
}

.fr-pb-first-28v, .fr-pb-first-14w,
.fr-py-first-28v, .fr-py-first-14w {
  padding-bottom: 7rem !important;
}

.fr-p-first-29v {
  padding: 7.25rem !important;
}

.fr-pl-first-29v,
.fr-px-first-29v {
  padding-left: 7.25rem !important;
}

.fr-pr-first-29v,
.fr-px-first-29v {
  padding-right: 7.25rem !important;
}

.fr-pt-first-29v,
.fr-py-first-29v {
  padding-top: 7.25rem !important;
}

.fr-pb-first-29v,
.fr-py-first-29v {
  padding-bottom: 7.25rem !important;
}

.fr-p-first-30v, .fr-p-first-15w {
  padding: 7.5rem !important;
}

.fr-pl-first-30v, .fr-pl-first-15w,
.fr-px-first-30v, .fr-px-first-15w {
  padding-left: 7.5rem !important;
}

.fr-pr-first-30v, .fr-pr-first-15w,
.fr-px-first-30v, .fr-px-first-15w {
  padding-right: 7.5rem !important;
}

.fr-pt-first-30v, .fr-pt-first-15w,
.fr-py-first-30v, .fr-py-first-15w {
  padding-top: 7.5rem !important;
}

.fr-pb-first-30v, .fr-pb-first-15w,
.fr-py-first-30v, .fr-py-first-15w {
  padding-bottom: 7.5rem !important;
}

.fr-p-first-31v {
  padding: 7.75rem !important;
}

.fr-pl-first-31v,
.fr-px-first-31v {
  padding-left: 7.75rem !important;
}

.fr-pr-first-31v,
.fr-px-first-31v {
  padding-right: 7.75rem !important;
}

.fr-pt-first-31v,
.fr-py-first-31v {
  padding-top: 7.75rem !important;
}

.fr-pb-first-31v,
.fr-py-first-31v {
  padding-bottom: 7.75rem !important;
}

.fr-p-first-32v, .fr-p-first-16w {
  padding: 8rem !important;
}

.fr-pl-first-32v, .fr-pl-first-16w,
.fr-px-first-32v, .fr-px-first-16w {
  padding-left: 8rem !important;
}

.fr-pr-first-32v, .fr-pr-first-16w,
.fr-px-first-32v, .fr-px-first-16w {
  padding-right: 8rem !important;
}

.fr-pt-first-32v, .fr-pt-first-16w,
.fr-py-first-32v, .fr-py-first-16w {
  padding-top: 8rem !important;
}

.fr-pb-first-32v, .fr-pb-first-16w,
.fr-py-first-32v, .fr-py-first-16w {
  padding-bottom: 8rem !important;
}

@media (min-width: 48em) {
  .fr-p-md-0 {
    padding: 0rem !important;
  }
  .fr-pl-md-0,
  .fr-px-md-0 {
    padding-left: 0rem !important;
  }
  .fr-pr-md-0,
  .fr-px-md-0 {
    padding-right: 0rem !important;
  }
  .fr-pt-md-0,
  .fr-py-md-0 {
    padding-top: 0rem !important;
  }
  .fr-pb-md-0,
  .fr-py-md-0 {
    padding-bottom: 0rem !important;
  }
  .fr-p-md-n0-5v {
    padding: -0.125rem !important;
  }
  .fr-pl-md-n0-5v,
  .fr-px-md-n0-5v {
    padding-left: -0.125rem !important;
  }
  .fr-pr-md-n0-5v,
  .fr-px-md-n0-5v {
    padding-right: -0.125rem !important;
  }
  .fr-pt-md-n0-5v,
  .fr-py-md-n0-5v {
    padding-top: -0.125rem !important;
  }
  .fr-pb-md-n0-5v,
  .fr-py-md-n0-5v {
    padding-bottom: -0.125rem !important;
  }
  .fr-p-md-0-5v {
    padding: 0.125rem !important;
  }
  .fr-pl-md-0-5v,
  .fr-px-md-0-5v {
    padding-left: 0.125rem !important;
  }
  .fr-pr-md-0-5v,
  .fr-px-md-0-5v {
    padding-right: 0.125rem !important;
  }
  .fr-pt-md-0-5v,
  .fr-py-md-0-5v {
    padding-top: 0.125rem !important;
  }
  .fr-pb-md-0-5v,
  .fr-py-md-0-5v {
    padding-bottom: 0.125rem !important;
  }
  .fr-p-md-1v {
    padding: 0.25rem !important;
  }
  .fr-pl-md-1v,
  .fr-px-md-1v {
    padding-left: 0.25rem !important;
  }
  .fr-pr-md-1v,
  .fr-px-md-1v {
    padding-right: 0.25rem !important;
  }
  .fr-pt-md-1v,
  .fr-py-md-1v {
    padding-top: 0.25rem !important;
  }
  .fr-pb-md-1v,
  .fr-py-md-1v {
    padding-bottom: 0.25rem !important;
  }
  .fr-p-md-1-5v {
    padding: 0.375rem !important;
  }
  .fr-pl-md-1-5v,
  .fr-px-md-1-5v {
    padding-left: 0.375rem !important;
  }
  .fr-pr-md-1-5v,
  .fr-px-md-1-5v {
    padding-right: 0.375rem !important;
  }
  .fr-pt-md-1-5v,
  .fr-py-md-1-5v {
    padding-top: 0.375rem !important;
  }
  .fr-pb-md-1-5v,
  .fr-py-md-1-5v {
    padding-bottom: 0.375rem !important;
  }
  .fr-p-md-2v, .fr-p-md-1w {
    padding: 0.5rem !important;
  }
  .fr-pl-md-2v, .fr-pl-md-1w,
  .fr-px-md-2v, .fr-px-md-1w {
    padding-left: 0.5rem !important;
  }
  .fr-pr-md-2v, .fr-pr-md-1w,
  .fr-px-md-2v, .fr-px-md-1w {
    padding-right: 0.5rem !important;
  }
  .fr-pt-md-2v, .fr-pt-md-1w,
  .fr-py-md-2v, .fr-py-md-1w {
    padding-top: 0.5rem !important;
  }
  .fr-pb-md-2v, .fr-pb-md-1w,
  .fr-py-md-2v, .fr-py-md-1w {
    padding-bottom: 0.5rem !important;
  }
  .fr-p-md-3v {
    padding: 0.75rem !important;
  }
  .fr-pl-md-3v,
  .fr-px-md-3v {
    padding-left: 0.75rem !important;
  }
  .fr-pr-md-3v,
  .fr-px-md-3v {
    padding-right: 0.75rem !important;
  }
  .fr-pt-md-3v,
  .fr-py-md-3v {
    padding-top: 0.75rem !important;
  }
  .fr-pb-md-3v,
  .fr-py-md-3v {
    padding-bottom: 0.75rem !important;
  }
  .fr-p-md-4v, .fr-p-md-2w {
    padding: 1rem !important;
  }
  .fr-pl-md-4v, .fr-pl-md-2w,
  .fr-px-md-4v, .fr-px-md-2w {
    padding-left: 1rem !important;
  }
  .fr-pr-md-4v, .fr-pr-md-2w,
  .fr-px-md-4v, .fr-px-md-2w {
    padding-right: 1rem !important;
  }
  .fr-pt-md-4v, .fr-pt-md-2w,
  .fr-py-md-4v, .fr-py-md-2w {
    padding-top: 1rem !important;
  }
  .fr-pb-md-4v, .fr-pb-md-2w,
  .fr-py-md-4v, .fr-py-md-2w {
    padding-bottom: 1rem !important;
  }
  .fr-p-md-5v {
    padding: 1.25rem !important;
  }
  .fr-pl-md-5v,
  .fr-px-md-5v {
    padding-left: 1.25rem !important;
  }
  .fr-pr-md-5v,
  .fr-px-md-5v {
    padding-right: 1.25rem !important;
  }
  .fr-pt-md-5v,
  .fr-py-md-5v {
    padding-top: 1.25rem !important;
  }
  .fr-pb-md-5v,
  .fr-py-md-5v {
    padding-bottom: 1.25rem !important;
  }
  .fr-p-md-6v, .fr-p-md-3w {
    padding: 1.5rem !important;
  }
  .fr-pl-md-6v, .fr-pl-md-3w,
  .fr-px-md-6v, .fr-px-md-3w {
    padding-left: 1.5rem !important;
  }
  .fr-pr-md-6v, .fr-pr-md-3w,
  .fr-px-md-6v, .fr-px-md-3w {
    padding-right: 1.5rem !important;
  }
  .fr-pt-md-6v, .fr-pt-md-3w,
  .fr-py-md-6v, .fr-py-md-3w {
    padding-top: 1.5rem !important;
  }
  .fr-pb-md-6v, .fr-pb-md-3w,
  .fr-py-md-6v, .fr-py-md-3w {
    padding-bottom: 1.5rem !important;
  }
  .fr-p-md-7v {
    padding: 1.75rem !important;
  }
  .fr-pl-md-7v,
  .fr-px-md-7v {
    padding-left: 1.75rem !important;
  }
  .fr-pr-md-7v,
  .fr-px-md-7v {
    padding-right: 1.75rem !important;
  }
  .fr-pt-md-7v,
  .fr-py-md-7v {
    padding-top: 1.75rem !important;
  }
  .fr-pb-md-7v,
  .fr-py-md-7v {
    padding-bottom: 1.75rem !important;
  }
  .fr-p-md-8v, .fr-p-md-4w {
    padding: 2rem !important;
  }
  .fr-pl-md-8v, .fr-pl-md-4w,
  .fr-px-md-8v, .fr-px-md-4w {
    padding-left: 2rem !important;
  }
  .fr-pr-md-8v, .fr-pr-md-4w,
  .fr-px-md-8v, .fr-px-md-4w {
    padding-right: 2rem !important;
  }
  .fr-pt-md-8v, .fr-pt-md-4w,
  .fr-py-md-8v, .fr-py-md-4w {
    padding-top: 2rem !important;
  }
  .fr-pb-md-8v, .fr-pb-md-4w,
  .fr-py-md-8v, .fr-py-md-4w {
    padding-bottom: 2rem !important;
  }
  .fr-p-md-9v {
    padding: 2.25rem !important;
  }
  .fr-pl-md-9v,
  .fr-px-md-9v {
    padding-left: 2.25rem !important;
  }
  .fr-pr-md-9v,
  .fr-px-md-9v {
    padding-right: 2.25rem !important;
  }
  .fr-pt-md-9v,
  .fr-py-md-9v {
    padding-top: 2.25rem !important;
  }
  .fr-pb-md-9v,
  .fr-py-md-9v {
    padding-bottom: 2.25rem !important;
  }
  .fr-p-md-10v, .fr-p-md-5w {
    padding: 2.5rem !important;
  }
  .fr-pl-md-10v, .fr-pl-md-5w,
  .fr-px-md-10v, .fr-px-md-5w {
    padding-left: 2.5rem !important;
  }
  .fr-pr-md-10v, .fr-pr-md-5w,
  .fr-px-md-10v, .fr-px-md-5w {
    padding-right: 2.5rem !important;
  }
  .fr-pt-md-10v, .fr-pt-md-5w,
  .fr-py-md-10v, .fr-py-md-5w {
    padding-top: 2.5rem !important;
  }
  .fr-pb-md-10v, .fr-pb-md-5w,
  .fr-py-md-10v, .fr-py-md-5w {
    padding-bottom: 2.5rem !important;
  }
  .fr-p-md-11v {
    padding: 2.75rem !important;
  }
  .fr-pl-md-11v,
  .fr-px-md-11v {
    padding-left: 2.75rem !important;
  }
  .fr-pr-md-11v,
  .fr-px-md-11v {
    padding-right: 2.75rem !important;
  }
  .fr-pt-md-11v,
  .fr-py-md-11v {
    padding-top: 2.75rem !important;
  }
  .fr-pb-md-11v,
  .fr-py-md-11v {
    padding-bottom: 2.75rem !important;
  }
  .fr-p-md-12v, .fr-p-md-6w {
    padding: 3rem !important;
  }
  .fr-pl-md-12v, .fr-pl-md-6w,
  .fr-px-md-12v, .fr-px-md-6w {
    padding-left: 3rem !important;
  }
  .fr-pr-md-12v, .fr-pr-md-6w,
  .fr-px-md-12v, .fr-px-md-6w {
    padding-right: 3rem !important;
  }
  .fr-pt-md-12v, .fr-pt-md-6w,
  .fr-py-md-12v, .fr-py-md-6w {
    padding-top: 3rem !important;
  }
  .fr-pb-md-12v, .fr-pb-md-6w,
  .fr-py-md-12v, .fr-py-md-6w {
    padding-bottom: 3rem !important;
  }
  .fr-p-md-13v {
    padding: 3.25rem !important;
  }
  .fr-pl-md-13v,
  .fr-px-md-13v {
    padding-left: 3.25rem !important;
  }
  .fr-pr-md-13v,
  .fr-px-md-13v {
    padding-right: 3.25rem !important;
  }
  .fr-pt-md-13v,
  .fr-py-md-13v {
    padding-top: 3.25rem !important;
  }
  .fr-pb-md-13v,
  .fr-py-md-13v {
    padding-bottom: 3.25rem !important;
  }
  .fr-p-md-14v, .fr-p-md-7w {
    padding: 3.5rem !important;
  }
  .fr-pl-md-14v, .fr-pl-md-7w,
  .fr-px-md-14v, .fr-px-md-7w {
    padding-left: 3.5rem !important;
  }
  .fr-pr-md-14v, .fr-pr-md-7w,
  .fr-px-md-14v, .fr-px-md-7w {
    padding-right: 3.5rem !important;
  }
  .fr-pt-md-14v, .fr-pt-md-7w,
  .fr-py-md-14v, .fr-py-md-7w {
    padding-top: 3.5rem !important;
  }
  .fr-pb-md-14v, .fr-pb-md-7w,
  .fr-py-md-14v, .fr-py-md-7w {
    padding-bottom: 3.5rem !important;
  }
  .fr-p-md-15v {
    padding: 3.75rem !important;
  }
  .fr-pl-md-15v,
  .fr-px-md-15v {
    padding-left: 3.75rem !important;
  }
  .fr-pr-md-15v,
  .fr-px-md-15v {
    padding-right: 3.75rem !important;
  }
  .fr-pt-md-15v,
  .fr-py-md-15v {
    padding-top: 3.75rem !important;
  }
  .fr-pb-md-15v,
  .fr-py-md-15v {
    padding-bottom: 3.75rem !important;
  }
  .fr-p-md-16v, .fr-p-md-8w {
    padding: 4rem !important;
  }
  .fr-pl-md-16v, .fr-pl-md-8w,
  .fr-px-md-16v, .fr-px-md-8w {
    padding-left: 4rem !important;
  }
  .fr-pr-md-16v, .fr-pr-md-8w,
  .fr-px-md-16v, .fr-px-md-8w {
    padding-right: 4rem !important;
  }
  .fr-pt-md-16v, .fr-pt-md-8w,
  .fr-py-md-16v, .fr-py-md-8w {
    padding-top: 4rem !important;
  }
  .fr-pb-md-16v, .fr-pb-md-8w,
  .fr-py-md-16v, .fr-py-md-8w {
    padding-bottom: 4rem !important;
  }
  .fr-p-md-17v {
    padding: 4.25rem !important;
  }
  .fr-pl-md-17v,
  .fr-px-md-17v {
    padding-left: 4.25rem !important;
  }
  .fr-pr-md-17v,
  .fr-px-md-17v {
    padding-right: 4.25rem !important;
  }
  .fr-pt-md-17v,
  .fr-py-md-17v {
    padding-top: 4.25rem !important;
  }
  .fr-pb-md-17v,
  .fr-py-md-17v {
    padding-bottom: 4.25rem !important;
  }
  .fr-p-md-18v, .fr-p-md-9w {
    padding: 4.5rem !important;
  }
  .fr-pl-md-18v, .fr-pl-md-9w,
  .fr-px-md-18v, .fr-px-md-9w {
    padding-left: 4.5rem !important;
  }
  .fr-pr-md-18v, .fr-pr-md-9w,
  .fr-px-md-18v, .fr-px-md-9w {
    padding-right: 4.5rem !important;
  }
  .fr-pt-md-18v, .fr-pt-md-9w,
  .fr-py-md-18v, .fr-py-md-9w {
    padding-top: 4.5rem !important;
  }
  .fr-pb-md-18v, .fr-pb-md-9w,
  .fr-py-md-18v, .fr-py-md-9w {
    padding-bottom: 4.5rem !important;
  }
  .fr-p-md-19v {
    padding: 4.75rem !important;
  }
  .fr-pl-md-19v,
  .fr-px-md-19v {
    padding-left: 4.75rem !important;
  }
  .fr-pr-md-19v,
  .fr-px-md-19v {
    padding-right: 4.75rem !important;
  }
  .fr-pt-md-19v,
  .fr-py-md-19v {
    padding-top: 4.75rem !important;
  }
  .fr-pb-md-19v,
  .fr-py-md-19v {
    padding-bottom: 4.75rem !important;
  }
  .fr-p-md-20v, .fr-p-md-10w {
    padding: 5rem !important;
  }
  .fr-pl-md-20v, .fr-pl-md-10w,
  .fr-px-md-20v, .fr-px-md-10w {
    padding-left: 5rem !important;
  }
  .fr-pr-md-20v, .fr-pr-md-10w,
  .fr-px-md-20v, .fr-px-md-10w {
    padding-right: 5rem !important;
  }
  .fr-pt-md-20v, .fr-pt-md-10w,
  .fr-py-md-20v, .fr-py-md-10w {
    padding-top: 5rem !important;
  }
  .fr-pb-md-20v, .fr-pb-md-10w,
  .fr-py-md-20v, .fr-py-md-10w {
    padding-bottom: 5rem !important;
  }
  .fr-p-md-21v {
    padding: 5.25rem !important;
  }
  .fr-pl-md-21v,
  .fr-px-md-21v {
    padding-left: 5.25rem !important;
  }
  .fr-pr-md-21v,
  .fr-px-md-21v {
    padding-right: 5.25rem !important;
  }
  .fr-pt-md-21v,
  .fr-py-md-21v {
    padding-top: 5.25rem !important;
  }
  .fr-pb-md-21v,
  .fr-py-md-21v {
    padding-bottom: 5.25rem !important;
  }
  .fr-p-md-22v, .fr-p-md-11w {
    padding: 5.5rem !important;
  }
  .fr-pl-md-22v, .fr-pl-md-11w,
  .fr-px-md-22v, .fr-px-md-11w {
    padding-left: 5.5rem !important;
  }
  .fr-pr-md-22v, .fr-pr-md-11w,
  .fr-px-md-22v, .fr-px-md-11w {
    padding-right: 5.5rem !important;
  }
  .fr-pt-md-22v, .fr-pt-md-11w,
  .fr-py-md-22v, .fr-py-md-11w {
    padding-top: 5.5rem !important;
  }
  .fr-pb-md-22v, .fr-pb-md-11w,
  .fr-py-md-22v, .fr-py-md-11w {
    padding-bottom: 5.5rem !important;
  }
  .fr-p-md-23v {
    padding: 5.75rem !important;
  }
  .fr-pl-md-23v,
  .fr-px-md-23v {
    padding-left: 5.75rem !important;
  }
  .fr-pr-md-23v,
  .fr-px-md-23v {
    padding-right: 5.75rem !important;
  }
  .fr-pt-md-23v,
  .fr-py-md-23v {
    padding-top: 5.75rem !important;
  }
  .fr-pb-md-23v,
  .fr-py-md-23v {
    padding-bottom: 5.75rem !important;
  }
  .fr-p-md-24v, .fr-p-md-12w {
    padding: 6rem !important;
  }
  .fr-pl-md-24v, .fr-pl-md-12w,
  .fr-px-md-24v, .fr-px-md-12w {
    padding-left: 6rem !important;
  }
  .fr-pr-md-24v, .fr-pr-md-12w,
  .fr-px-md-24v, .fr-px-md-12w {
    padding-right: 6rem !important;
  }
  .fr-pt-md-24v, .fr-pt-md-12w,
  .fr-py-md-24v, .fr-py-md-12w {
    padding-top: 6rem !important;
  }
  .fr-pb-md-24v, .fr-pb-md-12w,
  .fr-py-md-24v, .fr-py-md-12w {
    padding-bottom: 6rem !important;
  }
  .fr-p-md-25v {
    padding: 6.25rem !important;
  }
  .fr-pl-md-25v,
  .fr-px-md-25v {
    padding-left: 6.25rem !important;
  }
  .fr-pr-md-25v,
  .fr-px-md-25v {
    padding-right: 6.25rem !important;
  }
  .fr-pt-md-25v,
  .fr-py-md-25v {
    padding-top: 6.25rem !important;
  }
  .fr-pb-md-25v,
  .fr-py-md-25v {
    padding-bottom: 6.25rem !important;
  }
  .fr-p-md-26v, .fr-p-md-13w {
    padding: 6.5rem !important;
  }
  .fr-pl-md-26v, .fr-pl-md-13w,
  .fr-px-md-26v, .fr-px-md-13w {
    padding-left: 6.5rem !important;
  }
  .fr-pr-md-26v, .fr-pr-md-13w,
  .fr-px-md-26v, .fr-px-md-13w {
    padding-right: 6.5rem !important;
  }
  .fr-pt-md-26v, .fr-pt-md-13w,
  .fr-py-md-26v, .fr-py-md-13w {
    padding-top: 6.5rem !important;
  }
  .fr-pb-md-26v, .fr-pb-md-13w,
  .fr-py-md-26v, .fr-py-md-13w {
    padding-bottom: 6.5rem !important;
  }
  .fr-p-md-27v {
    padding: 6.75rem !important;
  }
  .fr-pl-md-27v,
  .fr-px-md-27v {
    padding-left: 6.75rem !important;
  }
  .fr-pr-md-27v,
  .fr-px-md-27v {
    padding-right: 6.75rem !important;
  }
  .fr-pt-md-27v,
  .fr-py-md-27v {
    padding-top: 6.75rem !important;
  }
  .fr-pb-md-27v,
  .fr-py-md-27v {
    padding-bottom: 6.75rem !important;
  }
  .fr-p-md-28v, .fr-p-md-14w {
    padding: 7rem !important;
  }
  .fr-pl-md-28v, .fr-pl-md-14w,
  .fr-px-md-28v, .fr-px-md-14w {
    padding-left: 7rem !important;
  }
  .fr-pr-md-28v, .fr-pr-md-14w,
  .fr-px-md-28v, .fr-px-md-14w {
    padding-right: 7rem !important;
  }
  .fr-pt-md-28v, .fr-pt-md-14w,
  .fr-py-md-28v, .fr-py-md-14w {
    padding-top: 7rem !important;
  }
  .fr-pb-md-28v, .fr-pb-md-14w,
  .fr-py-md-28v, .fr-py-md-14w {
    padding-bottom: 7rem !important;
  }
  .fr-p-md-29v {
    padding: 7.25rem !important;
  }
  .fr-pl-md-29v,
  .fr-px-md-29v {
    padding-left: 7.25rem !important;
  }
  .fr-pr-md-29v,
  .fr-px-md-29v {
    padding-right: 7.25rem !important;
  }
  .fr-pt-md-29v,
  .fr-py-md-29v {
    padding-top: 7.25rem !important;
  }
  .fr-pb-md-29v,
  .fr-py-md-29v {
    padding-bottom: 7.25rem !important;
  }
  .fr-p-md-30v, .fr-p-md-15w {
    padding: 7.5rem !important;
  }
  .fr-pl-md-30v, .fr-pl-md-15w,
  .fr-px-md-30v, .fr-px-md-15w {
    padding-left: 7.5rem !important;
  }
  .fr-pr-md-30v, .fr-pr-md-15w,
  .fr-px-md-30v, .fr-px-md-15w {
    padding-right: 7.5rem !important;
  }
  .fr-pt-md-30v, .fr-pt-md-15w,
  .fr-py-md-30v, .fr-py-md-15w {
    padding-top: 7.5rem !important;
  }
  .fr-pb-md-30v, .fr-pb-md-15w,
  .fr-py-md-30v, .fr-py-md-15w {
    padding-bottom: 7.5rem !important;
  }
  .fr-p-md-31v {
    padding: 7.75rem !important;
  }
  .fr-pl-md-31v,
  .fr-px-md-31v {
    padding-left: 7.75rem !important;
  }
  .fr-pr-md-31v,
  .fr-px-md-31v {
    padding-right: 7.75rem !important;
  }
  .fr-pt-md-31v,
  .fr-py-md-31v {
    padding-top: 7.75rem !important;
  }
  .fr-pb-md-31v,
  .fr-py-md-31v {
    padding-bottom: 7.75rem !important;
  }
  .fr-p-md-32v, .fr-p-md-16w {
    padding: 8rem !important;
  }
  .fr-pl-md-32v, .fr-pl-md-16w,
  .fr-px-md-32v, .fr-px-md-16w {
    padding-left: 8rem !important;
  }
  .fr-pr-md-32v, .fr-pr-md-16w,
  .fr-px-md-32v, .fr-px-md-16w {
    padding-right: 8rem !important;
  }
  .fr-pt-md-32v, .fr-pt-md-16w,
  .fr-py-md-32v, .fr-py-md-16w {
    padding-top: 8rem !important;
  }
  .fr-pb-md-32v, .fr-pb-md-16w,
  .fr-py-md-32v, .fr-py-md-16w {
    padding-bottom: 8rem !important;
  }
}

.fr-displayed-lg {
  display: none !important;
}

@media (min-width: 62em) {
  .fr-displayed-lg {
    display: inherit !important;
  }
}

.fr-fi--xs {
  --icon-size: 0.75rem;
}

.fr-fi--sm {
  --icon-size: 1rem;
}

.fr-fi--md {
  --icon-size: 1.5rem;
}

.fr-fi--lg {
  --icon-size: 2rem;
}

body {
  background-color: var(--background-default-grey);
  --hover: var(--background-default-grey-hover);
  --active: var(--background-default-grey-active);
  color: var(--text-default-grey);
}

a:not([href]), button:disabled, input:disabled, input[type="checkbox"]:disabled, input[type="checkbox"]:disabled + label, input[type="radio"]:disabled, input[type="radio"]:disabled + label, textarea:disabled, video:not([href]), audio:not([href]) {
  color: var(--text-disabled-grey);
}

.fr-h6, .fr-h5, .fr-h4, .fr-h3, .fr-h2, .fr-h1, .fr-display-xs, .fr-display-sm, .fr-display-md, .fr-display-lg, .fr-display-xl {
  color: var(--text-title-grey);
}

h6, h5, h4, h3, h2, h1 {
  color: var(--text-title-grey);
}

.fr-background-default--grey {
  background-color: var(--background-default-grey) !important;
  --hover: var(--background-default-grey-hover) !important;
  --active: var(--background-default-grey-active) !important;
}

.fr-background-elevated--grey {
  background-color: var(--background-elevated-grey) !important;
  --hover: var(--background-elevated-grey-hover) !important;
  --active: var(--background-elevated-grey-active) !important;
}

.fr-background-alt--grey {
  background-color: var(--background-alt-grey) !important;
  --hover: var(--background-alt-grey-hover) !important;
  --active: var(--background-alt-grey-active) !important;
}

.fr-background-alt--blue-france {
  background-color: var(--background-alt-blue-france) !important;
  --hover: var(--background-alt-blue-france-hover) !important;
  --active: var(--background-alt-blue-france-active) !important;
}

.fr-background-alt--red-marianne {
  background-color: var(--background-alt-red-marianne) !important;
  --hover: var(--background-alt-red-marianne-hover) !important;
  --active: var(--background-alt-red-marianne-active) !important;
}

.fr-background-alt--green-tilleul-verveine {
  background-color: var(--background-alt-green-tilleul-verveine) !important;
  --hover: var(--background-alt-green-tilleul-verveine-hover) !important;
  --active: var(--background-alt-green-tilleul-verveine-active) !important;
}

.fr-background-alt--green-bourgeon {
  background-color: var(--background-alt-green-bourgeon) !important;
  --hover: var(--background-alt-green-bourgeon-hover) !important;
  --active: var(--background-alt-green-bourgeon-active) !important;
}

.fr-background-alt--green-emeraude {
  background-color: var(--background-alt-green-emeraude) !important;
  --hover: var(--background-alt-green-emeraude-hover) !important;
  --active: var(--background-alt-green-emeraude-active) !important;
}

.fr-background-alt--green-menthe {
  background-color: var(--background-alt-green-menthe) !important;
  --hover: var(--background-alt-green-menthe-hover) !important;
  --active: var(--background-alt-green-menthe-active) !important;
}

.fr-background-alt--green-archipel {
  background-color: var(--background-alt-green-archipel) !important;
  --hover: var(--background-alt-green-archipel-hover) !important;
  --active: var(--background-alt-green-archipel-active) !important;
}

.fr-background-alt--blue-ecume {
  background-color: var(--background-alt-blue-ecume) !important;
  --hover: var(--background-alt-blue-ecume-hover) !important;
  --active: var(--background-alt-blue-ecume-active) !important;
}

.fr-background-alt--blue-cumulus {
  background-color: var(--background-alt-blue-cumulus) !important;
  --hover: var(--background-alt-blue-cumulus-hover) !important;
  --active: var(--background-alt-blue-cumulus-active) !important;
}

.fr-background-alt--purple-glycine {
  background-color: var(--background-alt-purple-glycine) !important;
  --hover: var(--background-alt-purple-glycine-hover) !important;
  --active: var(--background-alt-purple-glycine-active) !important;
}

.fr-background-alt--pink-macaron {
  background-color: var(--background-alt-pink-macaron) !important;
  --hover: var(--background-alt-pink-macaron-hover) !important;
  --active: var(--background-alt-pink-macaron-active) !important;
}

.fr-background-alt--pink-tuile {
  background-color: var(--background-alt-pink-tuile) !important;
  --hover: var(--background-alt-pink-tuile-hover) !important;
  --active: var(--background-alt-pink-tuile-active) !important;
}

.fr-background-alt--yellow-tournesol {
  background-color: var(--background-alt-yellow-tournesol) !important;
  --hover: var(--background-alt-yellow-tournesol-hover) !important;
  --active: var(--background-alt-yellow-tournesol-active) !important;
}

.fr-background-alt--yellow-moutarde {
  background-color: var(--background-alt-yellow-moutarde) !important;
  --hover: var(--background-alt-yellow-moutarde-hover) !important;
  --active: var(--background-alt-yellow-moutarde-active) !important;
}

.fr-background-alt--orange-terre-battue {
  background-color: var(--background-alt-orange-terre-battue) !important;
  --hover: var(--background-alt-orange-terre-battue-hover) !important;
  --active: var(--background-alt-orange-terre-battue-active) !important;
}

.fr-background-alt--brown-cafe-creme {
  background-color: var(--background-alt-brown-cafe-creme) !important;
  --hover: var(--background-alt-brown-cafe-creme-hover) !important;
  --active: var(--background-alt-brown-cafe-creme-active) !important;
}

.fr-background-alt--brown-caramel {
  background-color: var(--background-alt-brown-caramel) !important;
  --hover: var(--background-alt-brown-caramel-hover) !important;
  --active: var(--background-alt-brown-caramel-active) !important;
}

.fr-background-alt--brown-opera {
  background-color: var(--background-alt-brown-opera) !important;
  --hover: var(--background-alt-brown-opera-hover) !important;
  --active: var(--background-alt-brown-opera-active) !important;
}

.fr-background-alt--beige-gris-galet {
  background-color: var(--background-alt-beige-gris-galet) !important;
  --hover: var(--background-alt-beige-gris-galet-hover) !important;
  --active: var(--background-alt-beige-gris-galet-active) !important;
}

.fr-background-contrast--grey {
  background-color: var(--background-contrast-grey) !important;
  --hover: var(--background-contrast-grey-hover) !important;
  --active: var(--background-contrast-grey-active) !important;
}

.fr-background-contrast--blue-france {
  background-color: var(--background-contrast-blue-france) !important;
  --hover: var(--background-contrast-blue-france-hover) !important;
  --active: var(--background-contrast-blue-france-active) !important;
}

.fr-background-contrast--red-marianne {
  background-color: var(--background-contrast-red-marianne) !important;
  --hover: var(--background-contrast-red-marianne-hover) !important;
  --active: var(--background-contrast-red-marianne-active) !important;
}

.fr-background-contrast--green-tilleul-verveine {
  background-color: var(--background-contrast-green-tilleul-verveine) !important;
  --hover: var(--background-contrast-green-tilleul-verveine-hover) !important;
  --active: var(--background-contrast-green-tilleul-verveine-active) !important;
}

.fr-background-contrast--green-bourgeon {
  background-color: var(--background-contrast-green-bourgeon) !important;
  --hover: var(--background-contrast-green-bourgeon-hover) !important;
  --active: var(--background-contrast-green-bourgeon-active) !important;
}

.fr-background-contrast--green-emeraude {
  background-color: var(--background-contrast-green-emeraude) !important;
  --hover: var(--background-contrast-green-emeraude-hover) !important;
  --active: var(--background-contrast-green-emeraude-active) !important;
}

.fr-background-contrast--green-menthe {
  background-color: var(--background-contrast-green-menthe) !important;
  --hover: var(--background-contrast-green-menthe-hover) !important;
  --active: var(--background-contrast-green-menthe-active) !important;
}

.fr-background-contrast--green-archipel {
  background-color: var(--background-contrast-green-archipel) !important;
  --hover: var(--background-contrast-green-archipel-hover) !important;
  --active: var(--background-contrast-green-archipel-active) !important;
}

.fr-background-contrast--blue-ecume {
  background-color: var(--background-contrast-blue-ecume) !important;
  --hover: var(--background-contrast-blue-ecume-hover) !important;
  --active: var(--background-contrast-blue-ecume-active) !important;
}

.fr-background-contrast--blue-cumulus {
  background-color: var(--background-contrast-blue-cumulus) !important;
  --hover: var(--background-contrast-blue-cumulus-hover) !important;
  --active: var(--background-contrast-blue-cumulus-active) !important;
}

.fr-background-contrast--purple-glycine {
  background-color: var(--background-contrast-purple-glycine) !important;
  --hover: var(--background-contrast-purple-glycine-hover) !important;
  --active: var(--background-contrast-purple-glycine-active) !important;
}

.fr-background-contrast--pink-macaron {
  background-color: var(--background-contrast-pink-macaron) !important;
  --hover: var(--background-contrast-pink-macaron-hover) !important;
  --active: var(--background-contrast-pink-macaron-active) !important;
}

.fr-background-contrast--pink-tuile {
  background-color: var(--background-contrast-pink-tuile) !important;
  --hover: var(--background-contrast-pink-tuile-hover) !important;
  --active: var(--background-contrast-pink-tuile-active) !important;
}

.fr-background-contrast--yellow-tournesol {
  background-color: var(--background-contrast-yellow-tournesol) !important;
  --hover: var(--background-contrast-yellow-tournesol-hover) !important;
  --active: var(--background-contrast-yellow-tournesol-active) !important;
}

.fr-background-contrast--yellow-moutarde {
  background-color: var(--background-contrast-yellow-moutarde) !important;
  --hover: var(--background-contrast-yellow-moutarde-hover) !important;
  --active: var(--background-contrast-yellow-moutarde-active) !important;
}

.fr-background-contrast--orange-terre-battue {
  background-color: var(--background-contrast-orange-terre-battue) !important;
  --hover: var(--background-contrast-orange-terre-battue-hover) !important;
  --active: var(--background-contrast-orange-terre-battue-active) !important;
}

.fr-background-contrast--brown-cafe-creme {
  background-color: var(--background-contrast-brown-cafe-creme) !important;
  --hover: var(--background-contrast-brown-cafe-creme-hover) !important;
  --active: var(--background-contrast-brown-cafe-creme-active) !important;
}

.fr-background-contrast--brown-caramel {
  background-color: var(--background-contrast-brown-caramel) !important;
  --hover: var(--background-contrast-brown-caramel-hover) !important;
  --active: var(--background-contrast-brown-caramel-active) !important;
}

.fr-background-contrast--brown-opera {
  background-color: var(--background-contrast-brown-opera) !important;
  --hover: var(--background-contrast-brown-opera-hover) !important;
  --active: var(--background-contrast-brown-opera-active) !important;
}

.fr-background-contrast--beige-gris-galet {
  background-color: var(--background-contrast-beige-gris-galet) !important;
  --hover: var(--background-contrast-beige-gris-galet-hover) !important;
  --active: var(--background-contrast-beige-gris-galet-active) !important;
}

.fr-background-contrast--info {
  background-color: var(--background-contrast-info) !important;
  --hover: var(--background-contrast-info-hover) !important;
  --active: var(--background-contrast-info-active) !important;
}

.fr-background-contrast--success {
  background-color: var(--background-contrast-success) !important;
  --hover: var(--background-contrast-success-hover) !important;
  --active: var(--background-contrast-success-active) !important;
}

.fr-background-contrast--warning {
  background-color: var(--background-contrast-warning) !important;
  --hover: var(--background-contrast-warning-hover) !important;
  --active: var(--background-contrast-warning-active) !important;
}

.fr-background-contrast--error {
  background-color: var(--background-contrast-error) !important;
  --hover: var(--background-contrast-error-hover) !important;
  --active: var(--background-contrast-error-active) !important;
}

.fr-background-flat--grey {
  background-color: var(--background-flat-grey) !important;
}

.fr-background-flat--info {
  background-color: var(--background-flat-info) !important;
}

.fr-background-flat--success {
  background-color: var(--background-flat-success) !important;
}

.fr-background-flat--warning {
  background-color: var(--background-flat-warning) !important;
}

.fr-background-flat--error {
  background-color: var(--background-flat-error) !important;
}

.fr-text-default--grey {
  color: var(--text-default-grey) !important;
}

.fr-text-default--info {
  color: var(--text-default-info) !important;
}

.fr-text-default--success {
  color: var(--text-default-success) !important;
}

.fr-text-default--warning {
  color: var(--text-default-warning) !important;
}

.fr-text-default--error {
  color: var(--text-default-error) !important;
}

.fr-text-title--grey {
  color: var(--text-title-grey) !important;
}

.fr-text-title--blue-france {
  color: var(--text-title-blue-france) !important;
}

.fr-text-title--red-marianne {
  color: var(--text-title-red-marianne) !important;
}

.fr-text-label--grey {
  color: var(--text-label-grey) !important;
}

.fr-text-label--blue-france {
  color: var(--text-label-blue-france) !important;
}

.fr-text-label--red-marianne {
  color: var(--text-label-red-marianne) !important;
}

.fr-text-label--green-tilleul-verveine {
  color: var(--text-label-green-tilleul-verveine) !important;
}

.fr-text-label--green-bourgeon {
  color: var(--text-label-green-bourgeon) !important;
}

.fr-text-label--green-emeraude {
  color: var(--text-label-green-emeraude) !important;
}

.fr-text-label--green-menthe {
  color: var(--text-label-green-menthe) !important;
}

.fr-text-label--green-archipel {
  color: var(--text-label-green-archipel) !important;
}

.fr-text-label--blue-ecume {
  color: var(--text-label-blue-ecume) !important;
}

.fr-text-label--blue-cumulus {
  color: var(--text-label-blue-cumulus) !important;
}

.fr-text-label--purple-glycine {
  color: var(--text-label-purple-glycine) !important;
}

.fr-text-label--pink-macaron {
  color: var(--text-label-pink-macaron) !important;
}

.fr-text-label--pink-tuile {
  color: var(--text-label-pink-tuile) !important;
}

.fr-text-label--yellow-tournesol {
  color: var(--text-label-yellow-tournesol) !important;
}

.fr-text-label--yellow-moutarde {
  color: var(--text-label-yellow-moutarde) !important;
}

.fr-text-label--orange-terre-battue {
  color: var(--text-label-orange-terre-battue) !important;
}

.fr-text-label--brown-cafe-creme {
  color: var(--text-label-brown-cafe-creme) !important;
}

.fr-text-label--brown-caramel {
  color: var(--text-label-brown-caramel) !important;
}

.fr-text-label--brown-opera {
  color: var(--text-label-brown-opera) !important;
}

.fr-text-label--beige-gris-galet {
  color: var(--text-label-beige-gris-galet) !important;
}

.fr-text-mention--grey {
  color: var(--text-mention-grey) !important;
}

.fr-text-inverted--grey {
  color: var(--text-inverted-grey) !important;
}

.fr-text-inverted--blue-france {
  color: var(--text-inverted-blue-france) !important;
}

.fr-text-inverted--red-marianne {
  color: var(--text-inverted-red-marianne) !important;
}

.fr-text-inverted--info {
  color: var(--text-inverted-info) !important;
}

.fr-text-inverted--success {
  color: var(--text-inverted-success) !important;
}

.fr-text-inverted--warning {
  color: var(--text-inverted-warning) !important;
}

.fr-text-inverted--error {
  color: var(--text-inverted-error) !important;
}

.fr-text-inverted--green-tilleul-verveine {
  color: var(--text-inverted-green-tilleul-verveine) !important;
}

.fr-text-inverted--green-bourgeon {
  color: var(--text-inverted-green-bourgeon) !important;
}

.fr-text-inverted--green-emeraude {
  color: var(--text-inverted-green-emeraude) !important;
}

.fr-text-inverted--green-menthe {
  color: var(--text-inverted-green-menthe) !important;
}

.fr-text-inverted--green-archipel {
  color: var(--text-inverted-green-archipel) !important;
}

.fr-text-inverted--blue-ecume {
  color: var(--text-inverted-blue-ecume) !important;
}

.fr-text-inverted--blue-cumulus {
  color: var(--text-inverted-blue-cumulus) !important;
}

.fr-text-inverted--purple-glycine {
  color: var(--text-inverted-purple-glycine) !important;
}

.fr-text-inverted--pink-macaron {
  color: var(--text-inverted-pink-macaron) !important;
}

.fr-text-inverted--pink-tuile {
  color: var(--text-inverted-pink-tuile) !important;
}

.fr-text-inverted--yellow-tournesol {
  color: var(--text-inverted-yellow-tournesol) !important;
}

.fr-text-inverted--yellow-moutarde {
  color: var(--text-inverted-yellow-moutarde) !important;
}

.fr-text-inverted--orange-terre-battue {
  color: var(--text-inverted-orange-terre-battue) !important;
}

.fr-text-inverted--brown-cafe-creme {
  color: var(--text-inverted-brown-cafe-creme) !important;
}

.fr-text-inverted--brown-caramel {
  color: var(--text-inverted-brown-caramel) !important;
}

.fr-text-inverted--brown-opera {
  color: var(--text-inverted-brown-opera) !important;
}

.fr-text-inverted--beige-gris-galet {
  color: var(--text-inverted-beige-gris-galet) !important;
}

.fr-artwork-major--blue-france {
  fill: var(--artwork-major-blue-france) !important;
}

.fr-artwork-major--red-marianne {
  fill: var(--artwork-major-red-marianne) !important;
}

.fr-artwork-minor--blue-france {
  fill: var(--artwork-minor-blue-france) !important;
}

.fr-artwork-minor--red-marianne {
  fill: var(--artwork-minor-red-marianne) !important;
}

.fr-artwork-minor--green-tilleul-verveine {
  fill: var(--artwork-minor-green-tilleul-verveine) !important;
}

.fr-artwork-minor--green-bourgeon {
  fill: var(--artwork-minor-green-bourgeon) !important;
}

.fr-artwork-minor--green-emeraude {
  fill: var(--artwork-minor-green-emeraude) !important;
}

.fr-artwork-minor--green-menthe {
  fill: var(--artwork-minor-green-menthe) !important;
}

.fr-artwork-minor--green-archipel {
  fill: var(--artwork-minor-green-archipel) !important;
}

.fr-artwork-minor--blue-ecume {
  fill: var(--artwork-minor-blue-ecume) !important;
}

.fr-artwork-minor--blue-cumulus {
  fill: var(--artwork-minor-blue-cumulus) !important;
}

.fr-artwork-minor--purple-glycine {
  fill: var(--artwork-minor-purple-glycine) !important;
}

.fr-artwork-minor--pink-macaron {
  fill: var(--artwork-minor-pink-macaron) !important;
}

.fr-artwork-minor--pink-tuile {
  fill: var(--artwork-minor-pink-tuile) !important;
}

.fr-artwork-minor--yellow-tournesol {
  fill: var(--artwork-minor-yellow-tournesol) !important;
}

.fr-artwork-minor--yellow-moutarde {
  fill: var(--artwork-minor-yellow-moutarde) !important;
}

.fr-artwork-minor--orange-terre-battue {
  fill: var(--artwork-minor-orange-terre-battue) !important;
}

.fr-artwork-minor--brown-cafe-creme {
  fill: var(--artwork-minor-brown-cafe-creme) !important;
}

.fr-artwork-minor--brown-caramel {
  fill: var(--artwork-minor-brown-caramel) !important;
}

.fr-artwork-minor--brown-opera {
  fill: var(--artwork-minor-brown-opera) !important;
}

.fr-artwork-minor--beige-gris-galet {
  fill: var(--artwork-minor-beige-gris-galet) !important;
}

.fr-artwork-decorative--blue-france {
  fill: var(--artwork-decorative-blue-france) !important;
}

.fr-artwork-decorative--red-marianne {
  fill: var(--artwork-decorative-red-marianne) !important;
}

hr {
  background-image: linear-gradient(0deg, var(--border-default-grey), var(--border-default-grey));
}

.fr-hr {
  background-image: linear-gradient(0deg, var(--border-default-grey), var(--border-default-grey));
}

@media all and (-ms-high-contrast: none) and (-ms-high-contrast: none), all and (-ms-high-contrast: active) and (-ms-high-contrast: none), all and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: active) {
  .fr-enlarge-link {
    background-color: transparent;
  }
  .fr-enlarge-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .fr-enlarge-link:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-enlarge-link [href] {
    text-decoration: none;
  }
  .fr-enlarge-link [target='_blank']::after {
    content: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  [href],
  .fr-reset-link {
    text-decoration: underline;
  }
  .fr-raw-link {
    text-decoration: none;
  }
  .fr-reset-link::after {
    content: none;
  }
  [target="_blank"] {
    content: '';
  }
  [target="_blank"]::after {
    background-color: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  [target="_blank"]::after {
    background-image: url("../icons/system/external-link-line.svg");
  }
  [target="_blank"]::after {
    width: 1rem;
    height: 1rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-responsive-vid::before {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
  .fr-responsive-vid--16x9::before {
    content: "";
    display: block;
    padding-bottom: 56.25% !important;
  }
  .fr-responsive-vid--4x3::before {
    content: "";
    display: block;
    padding-bottom: 75% !important;
  }
  .fr-responsive-vid--1x1::before {
    content: "";
    display: block;
    padding-bottom: 100% !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul,
  ol {
    padding-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  [class^="fr-icon-"]::before, [class*=" fr-icon-"]::before, [class^="fr-fi-"]::before, [class*=" fr-fi-"]::before {
    background-color: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  [class^="fr-icon-"]::before, [class*=" fr-icon-"]::before, [class^="fr-fi-"]::before, [class*=" fr-fi-"]::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .fr-icon--xs::before {
    width: 0.75rem;
    height: 0.75rem;
  }
  .fr-icon--sm::before {
    width: 1rem;
    height: 1rem;
  }
  .fr-icon--md::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .fr-icon--lg::before {
    width: 2rem;
    height: 2rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    background-color: #fff;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    color: #3a3a3a;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a:not([href]), button:disabled, input:disabled, input[type="checkbox"]:disabled, input[type="checkbox"]:disabled + label, input[type="radio"]:disabled, input[type="radio"]:disabled + label, textarea:disabled, video:not([href]), audio:not([href]) {
    color: #929292;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-h6, .fr-h5, .fr-h4, .fr-h3, .fr-h2, .fr-h1, .fr-display-xs, .fr-display-sm, .fr-display-md, .fr-display-lg, .fr-display-xl {
    color: #161616;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  h6, h5, h4, h3, h2, h1 {
    color: #161616;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-default--grey {
    background-color: #fff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-default--grey:hover {
    background-color: #f6f6f6 !important;
  }
  .fr-background-default--grey:active {
    background-color: #ededed !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-elevated--grey {
    background-color: #fff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-elevated--grey:hover {
    background-color: #f6f6f6 !important;
  }
  .fr-background-elevated--grey:active {
    background-color: #ededed !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--grey {
    background-color: #f6f6f6 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--grey:hover {
    background-color: #dfdfdf !important;
  }
  .fr-background-alt--grey:active {
    background-color: #cfcfcf !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-france {
    background-color: #f5f5fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-france:hover {
    background-color: #dcdcfc !important;
  }
  .fr-background-alt--blue-france:active {
    background-color: #cbcbfa !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--red-marianne {
    background-color: #fef4f4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--red-marianne:hover {
    background-color: #fcd7d7 !important;
  }
  .fr-background-alt--red-marianne:active {
    background-color: #fac4c4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-tilleul-verveine {
    background-color: #fef7da !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-tilleul-verveine:hover {
    background-color: #fce552 !important;
  }
  .fr-background-alt--green-tilleul-verveine:active {
    background-color: #ebd54c !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-bourgeon {
    background-color: #e6feda !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-bourgeon:hover {
    background-color: #a7fc62 !important;
  }
  .fr-background-alt--green-bourgeon:active {
    background-color: #98ed4d !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-emeraude {
    background-color: #e3fdeb !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-emeraude:hover {
    background-color: #94f9b9 !important;
  }
  .fr-background-alt--green-emeraude:active {
    background-color: #6df1a3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-menthe {
    background-color: #dffdf7 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-menthe:hover {
    background-color: #84f9e7 !important;
  }
  .fr-background-alt--green-menthe:active {
    background-color: #70ebd8 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-archipel {
    background-color: #e5fbfd !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--green-archipel:hover {
    background-color: #99f2f8 !important;
  }
  .fr-background-alt--green-archipel:active {
    background-color: #73e9f0 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-ecume {
    background-color: #f4f6fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-ecume:hover {
    background-color: #d7dffb !important;
  }
  .fr-background-alt--blue-ecume:active {
    background-color: #c3cffa !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-cumulus {
    background-color: #f3f6fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--blue-cumulus:hover {
    background-color: #d3dffc !important;
  }
  .fr-background-alt--blue-cumulus:active {
    background-color: #bed0fa !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--purple-glycine {
    background-color: #fef3fd !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--purple-glycine:hover {
    background-color: #fcd4f8 !important;
  }
  .fr-background-alt--purple-glycine:active {
    background-color: #fabff5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--pink-macaron {
    background-color: #fef4f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--pink-macaron:hover {
    background-color: #fcd8d0 !important;
  }
  .fr-background-alt--pink-macaron:active {
    background-color: #fac5b8 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--pink-tuile {
    background-color: #fef4f3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--pink-tuile:hover {
    background-color: #fcd7d3 !important;
  }
  .fr-background-alt--pink-tuile:active {
    background-color: #fac4be !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--yellow-tournesol {
    background-color: #fef6e3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--yellow-tournesol:hover {
    background-color: #fce086 !important;
  }
  .fr-background-alt--yellow-tournesol:active {
    background-color: #f5d24b !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--yellow-moutarde {
    background-color: #fef5e8 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--yellow-moutarde:hover {
    background-color: #fcdca3 !important;
  }
  .fr-background-alt--yellow-moutarde:active {
    background-color: #fbcd64 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--orange-terre-battue {
    background-color: #fef4f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--orange-terre-battue:hover {
    background-color: #fcd8d0 !important;
  }
  .fr-background-alt--orange-terre-battue:active {
    background-color: #fac5b8 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-cafe-creme {
    background-color: #fbf6ed !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-cafe-creme:hover {
    background-color: #f2deb6 !important;
  }
  .fr-background-alt--brown-cafe-creme:active {
    background-color: #eacf91 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-caramel {
    background-color: #fbf5f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-caramel:hover {
    background-color: #f1dbcf !important;
  }
  .fr-background-alt--brown-caramel:active {
    background-color: #ecc9b5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-opera {
    background-color: #fbf5f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--brown-opera:hover {
    background-color: #f1dbcf !important;
  }
  .fr-background-alt--brown-opera:active {
    background-color: #ecc9b5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--beige-gris-galet {
    background-color: #f9f6f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-alt--beige-gris-galet:hover {
    background-color: #eadecd !important;
  }
  .fr-background-alt--beige-gris-galet:active {
    background-color: #e1ceb1 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--grey {
    background-color: #eee !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--grey:hover {
    background-color: #d2d2d2 !important;
  }
  .fr-background-contrast--grey:active {
    background-color: #c1c1c1 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-france {
    background-color: #ececfe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-france:hover {
    background-color: #cecefc !important;
  }
  .fr-background-contrast--blue-france:active {
    background-color: #bbbbfc !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--red-marianne {
    background-color: #fee9e9 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--red-marianne:hover {
    background-color: #fdc5c5 !important;
  }
  .fr-background-contrast--red-marianne:active {
    background-color: #fcafaf !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-tilleul-verveine {
    background-color: #fceeac !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-tilleul-verveine:hover {
    background-color: #e8d45c !important;
  }
  .fr-background-contrast--green-tilleul-verveine:active {
    background-color: #d4c254 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-bourgeon {
    background-color: #c9fcac !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-bourgeon:hover {
    background-color: #9ae95d !important;
  }
  .fr-background-contrast--green-bourgeon:active {
    background-color: #8dd555 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-emeraude {
    background-color: #c3fad5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-emeraude:hover {
    background-color: #77eda5 !important;
  }
  .fr-background-contrast--green-emeraude:active {
    background-color: #6dd897 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-menthe {
    background-color: #bafaee !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-menthe:hover {
    background-color: #79e7d5 !important;
  }
  .fr-background-contrast--green-menthe:active {
    background-color: #6fd3c3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-archipel {
    background-color: #c7f6fc !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--green-archipel:hover {
    background-color: #64ecf8 !important;
  }
  .fr-background-contrast--green-archipel:active {
    background-color: #5bd8e3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-ecume {
    background-color: #e9edfe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-ecume:hover {
    background-color: #c5d0fc !important;
  }
  .fr-background-contrast--blue-ecume:active {
    background-color: #adbffc !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-cumulus {
    background-color: #e6eefe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--blue-cumulus:hover {
    background-color: #bcd3fc !important;
  }
  .fr-background-contrast--blue-cumulus:active {
    background-color: #9fc3fc !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--purple-glycine {
    background-color: #fee7fc !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--purple-glycine:hover {
    background-color: #fdc0f8 !important;
  }
  .fr-background-contrast--purple-glycine:active {
    background-color: #fca8f6 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--pink-macaron {
    background-color: #fee9e6 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--pink-macaron:hover {
    background-color: #fdc6bd !important;
  }
  .fr-background-contrast--pink-macaron:active {
    background-color: #fcb0a2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--pink-tuile {
    background-color: #fee9e7 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--pink-tuile:hover {
    background-color: #fdc6c0 !important;
  }
  .fr-background-contrast--pink-tuile:active {
    background-color: #fcb0a7 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--yellow-tournesol {
    background-color: #feecc2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--yellow-tournesol:hover {
    background-color: #fbd335 !important;
  }
  .fr-background-contrast--yellow-tournesol:active {
    background-color: #e6c130 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--yellow-moutarde {
    background-color: #feebd0 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--yellow-moutarde:hover {
    background-color: #fdcd6d !important;
  }
  .fr-background-contrast--yellow-moutarde:active {
    background-color: #f4be30 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--orange-terre-battue {
    background-color: #fee9e5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--orange-terre-battue:hover {
    background-color: #fdc6ba !important;
  }
  .fr-background-contrast--orange-terre-battue:active {
    background-color: #fcb09e !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-cafe-creme {
    background-color: #f7ecdb !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-cafe-creme:hover {
    background-color: #edce94 !important;
  }
  .fr-background-contrast--brown-cafe-creme:active {
    background-color: #dabd84 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-caramel {
    background-color: #f7ebe5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-caramel:hover {
    background-color: #eccbb9 !important;
  }
  .fr-background-contrast--brown-caramel:active {
    background-color: #e6b79a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-opera {
    background-color: #f7ece4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--brown-opera:hover {
    background-color: #eccdb3 !important;
  }
  .fr-background-contrast--brown-opera:active {
    background-color: #e6ba90 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--beige-gris-galet {
    background-color: #f3ede5 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--beige-gris-galet:hover {
    background-color: #e1d0b5 !important;
  }
  .fr-background-contrast--beige-gris-galet:active {
    background-color: #d1bea2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--info {
    background-color: #e8edff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--info:hover {
    background-color: #c2d1ff !important;
  }
  .fr-background-contrast--info:active {
    background-color: #a9bfff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--success {
    background-color: #b8fec9 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--success:hover {
    background-color: #46fd89 !important;
  }
  .fr-background-contrast--success:active {
    background-color: #34eb7b !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--warning {
    background-color: #ffe9e6 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--warning:hover {
    background-color: #ffc6bd !important;
  }
  .fr-background-contrast--warning:active {
    background-color: #ffb0a2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--error {
    background-color: #ffe9e9 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-contrast--error:hover {
    background-color: #ffc5c5 !important;
  }
  .fr-background-contrast--error:active {
    background-color: #ffafaf !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-flat--grey {
    background-color: #3a3a3a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-flat--info {
    background-color: #0063cb !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-flat--success {
    background-color: #18753c !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-flat--warning {
    background-color: #b34000 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-background-flat--error {
    background-color: #ce0500 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-default--grey {
    color: #3a3a3a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-default--info {
    color: #0063cb !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-default--success {
    color: #18753c !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-default--warning {
    color: #b34000 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-default--error {
    color: #ce0500 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-title--grey {
    color: #161616 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-title--blue-france {
    color: #000091 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-title--red-marianne {
    color: #c9191e !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--grey {
    color: #161616 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--blue-france {
    color: #000091 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--red-marianne {
    color: #c9191e !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--green-tilleul-verveine {
    color: #66673d !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--green-bourgeon {
    color: #447049 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--green-emeraude {
    color: #297254 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--green-menthe {
    color: #37635f !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--green-archipel {
    color: #006a6f !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--blue-ecume {
    color: #2f4077 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--blue-cumulus {
    color: #3558a2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--purple-glycine {
    color: #6e445a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--pink-macaron {
    color: #8d533e !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--pink-tuile {
    color: #a94645 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--yellow-tournesol {
    color: #716043 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--yellow-moutarde {
    color: #695240 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--orange-terre-battue {
    color: #755348 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--brown-cafe-creme {
    color: #685c48 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--brown-caramel {
    color: #845d48 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--brown-opera {
    color: #745b47 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-label--beige-gris-galet {
    color: #6a6156 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-mention--grey {
    color: #666 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--grey {
    color: #fff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--blue-france {
    color: #f5f5fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--red-marianne {
    color: #fef4f4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--info {
    color: #f4f6ff !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--success {
    color: #dffee6 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--warning {
    color: #fff4f3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--error {
    color: #fff4f4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--green-tilleul-verveine {
    color: #fef7da !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--green-bourgeon {
    color: #e6feda !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--green-emeraude {
    color: #e3fdeb !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--green-menthe {
    color: #dffdf7 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--green-archipel {
    color: #e5fbfd !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--blue-ecume {
    color: #f4f6fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--blue-cumulus {
    color: #f3f6fe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--purple-glycine {
    color: #fef3fd !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--pink-macaron {
    color: #fef4f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--pink-tuile {
    color: #fef4f3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--yellow-tournesol {
    color: #fef6e3 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--yellow-moutarde {
    color: #fef5e8 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--orange-terre-battue {
    color: #fef4f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--brown-cafe-creme {
    color: #fbf6ed !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--brown-caramel {
    color: #fbf5f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--brown-opera {
    color: #fbf5f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-text-inverted--beige-gris-galet {
    color: #f9f6f2 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-major--blue-france {
    fill: #000091 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-major--red-marianne {
    fill: #c9191e !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--blue-france {
    fill: #6a6af4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--red-marianne {
    fill: #e1000f !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--green-tilleul-verveine {
    fill: #b7a73f !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--green-bourgeon {
    fill: #68a532 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--green-emeraude {
    fill: #00a95f !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--green-menthe {
    fill: #009081 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--green-archipel {
    fill: #009099 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--blue-ecume {
    fill: #465f9d !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--blue-cumulus {
    fill: #417dc4 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--purple-glycine {
    fill: #a558a0 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--pink-macaron {
    fill: #e18b76 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--pink-tuile {
    fill: #ce614a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--yellow-tournesol {
    fill: #c8aa39 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--yellow-moutarde {
    fill: #c3992a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--orange-terre-battue {
    fill: #e4794a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--brown-cafe-creme {
    fill: #d1b781 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--brown-caramel {
    fill: #c08c65 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--brown-opera {
    fill: #bd987a !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-minor--beige-gris-galet {
    fill: #aea397 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-decorative--blue-france {
    fill: #ececfe !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-artwork-decorative--red-marianne {
    fill: #fee9e9 !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  hr {
    background-image: linear-gradient(0deg, #e5e5e5, #e5e5e5);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fr-hr {
    background-image: linear-gradient(0deg, #e5e5e5, #e5e5e5);
  }
}
