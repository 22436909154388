////
/// Core Tool : Reset list
/// @group core
////

@mixin enable-list-style() {
  $selector: null;

  @if & == null {
    $selector: ':root';
  }

  @include nest($selector) {
    --ul-type: disc;
    --ol-type: decimal;
    --ul-start: #{space(4v)};
    --ol-start: #{space(6v)};
    --xl-block: #{space(2v)};
    --li-bottom: #{space(1v)};
    --xl-base: 1em;
    --ol-content: counters(li-counter, ".") ".\00a0\00a0";
  }
}

@mixin disable-list-style() {
  --ul-type: none;
  --ol-type: none;
  --ul-start: 0;
  --ol-start: 0;
  --xl-block: 0;
  --li-bottom: 0;
  --ol-content: none;
}

@mixin enable-list-style-legacy($unify: false) {
  $ul: ul;
  $ol: ol;

  @if $unify {
    $ul: selector-associate('&', 'ul');
    $ol: selector-associate('&', 'ol');
  }

  @at-root {
    #{$ul} {
      list-style-type: disc;
    }

    #{$ol} {
      list-style-type: decimal;
    }

    #{$ul},
    #{$ol} {
      @include padding-left(4v);
      @include margin-y(2v);
    }
  }
}

@mixin disable-list-style-legacy($unify: false) {
  $ul: & ul;
  $ol: & ol;

  @if $unify {
    $ul: selector-associate(&, ul);
    $ol: selector-associate(&, ol);
  }

  @at-root {
    #{$ul} {
      list-style-type: none;
    }

    #{$ol} {
      list-style-type: none;
    }

    #{$ul},
    #{$ol} {
      @include padding-left(0);
      @include margin-y(0);
    }
  }
}
