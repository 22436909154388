////
/// Core TTool : Medias
/// @group core
////

/// Force un ratio d'aspect sur le block par l'ajout d'un pseudo-element en before
/// @param {String} $ratio - key du ratio attendu selon la map $ratio-scale
/// @param {Size | Bool} $block - détermine si le block est créé et sa taille correspondante
@mixin aspect-ratio($ratio: 16x9, $important: false) {
  aspect-ratio: important(map_get($ratio-scale, $ratio), $important);
}

@mixin media-overlay-cover {
  @include fit-cover-center;
  @include absolute(0, 0, 0, 0, 100%, 100%);
}

@mixin aspect-ratio-legacy($ratio: 16x9, $important: false) {
  @include before('', block) {
    $pb: important(100% / map_get($ratio-scale, $ratio), $important);
    padding-bottom: $pb;
  }
}
