////
/// Core Tool : Action focus
/// @group core
////

@mixin focusable-selector($elements: $action-elements) {
  $selectors: ();
  @each $element, $setting in $elements {
    $focus: map-get($setting, focus);
    $selector: map-get($setting, selector);
    @if $focus {
      $selectors: append($selectors, nest($selector, map-get($focus, selector)), 'comma');
    }
  }

  #{$selectors} {
    @content;
  }
}

@mixin focusable-selector-focus($elements: $action-elements) {
  $selectors: ();
  @each $element, $setting in $elements {
    $focus: map-get($setting, focus);
    $selector: map-get($setting, selector);
    @if $focus {
      $selectors: append($selectors, nest($selector, '&:focus', map-get($focus, selector)), 'comma');
    }
  }

  #{$selectors} {
    @content;
  }
}

@mixin focusable-selector-focus-not-visible($elements: $action-elements) {
  $selectors: ();
  @each $element, $setting in $elements {
    $focus: map-get($setting, focus);
    $selector: map-get($setting, selector);
    @if $focus {
      $selectors: append($selectors, nest($selector, '&:focus:not(:focus-visible)', map-get($focus, selector)), 'comma');
    }
  }

  #{$selectors} {
    @content;
  }
}

@mixin focusable-selector-focus-visible($elements: $action-elements) {
  $selectors: ();

  @each $element, $setting in $elements {
    $focus: map-get($setting, focus);
    $selector: map-get($setting, selector);
    @if $focus {
      $selectors: append($selectors, nest($selector, '&:focus-visible', map-get($focus, selector)), 'comma');
    }
  }

  #{$selectors} {
    @content;
  }
}
