////
/// Media query order
/// Fix temporaire, déclare les média query vides de chaque breakpoints, pour garder le bon ordre au build
////

@import 'core/style/selector/setting/breakpoint';
@import 'core/style/selector/tool/breakpoint';

@each $bp, $limits in $breakpoints {
  @if $bp != xs {
    @include respond-from($bp) {
      /*! media #{$bp} */
    }
  }
}
