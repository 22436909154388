////
/// Core Setting : Spacing z-index
/// @group core
////

/// Liste des z-index présent dans le DS
/// @type map
$z-indexes: (
  auto: auto,
  below: -2,
  under: -1,
  base: 0,
  over: 1,
  above: 2,
  focus: 500,
  higher: 1000,
  higher-focus: 1500,
  menu: 2000,
  menu-focus: 2500,
  nav: 3000,
  nav-focus: 3500,
  consent: 4000,
  consent-focus: 4500,
  modal: 5000,
  modal-focus: 5500,
  modal-footer: 6000,
  modal-footer-focus: 6500,
);
