////
/// Core Tool : Icon
/// @group core
////

/// Return icon size from map
/// @param {String} $size ['md'] - Icon size from `$icon-size-map` (Default to 'md' = 16px)
///
/// @example scss - Set icon size to `SM` (12px)
///   .foo {
///     width: icon-size(sm);
///     height: icon-size(sm);
///   }
@function icon-size($size: md) {
  @return px-rem(map-get($icon-size-map, $size));
}

@function get-url-icon($icon, $important: false) {
  $config: map-get($icons-config, $icon);
  $url: url('#{$dist-path}#{map-get($config, path)}');
  @return important($url, $important);
}

@mixin with-icon($size:md) {
  @include has-icon {
    @include before {
      @include icon-size($size);
      @content;
    }
  }
}

@mixin icon-size($size:md, $pseudo: null) {
  $selector: null;
  @if $pseudo == before or $pseudo == after {
    $selector: '&::#{$pseudo}';
  }
  @include nest($selector) {
    --icon-size: #{icon-size($size)};
  }
}

@mixin icon-size-legacy($size:md, $pseudo: null) {
  $selector: null;
  @if $pseudo == before or $pseudo == after {
    $selector: '&::#{$pseudo}';
  }
  @include nest($selector) {
    $size: icon-size($size);
    @include size($size, $size);
  }
}

@mixin icon-content($icon, $pseudo: before, $important: false, $content: null) {
  @include _pseudo($pseudo, $content) {
    $url: get-url-icon($icon, $important);
    -webkit-mask-image: $url;
    mask-image: $url;
  }
}

@mixin icon-content-legacy($icon, $pseudo: before, $important: false, $content: null) {
  @include _pseudo($pseudo, $content) {
    $url: get-url-icon($icon, $important);
    background-image: $url;
  }
}

@mixin icon-style($pseudo: before, $content: '') {
  @include _pseudo($pseudo, $content) {
    flex: 0 0 auto;
    display: inline-block;
    vertical-align: middle;
    background-color: currentColor;
    @include size(var(--icon-size), var(--icon-size));
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    @content;
  }
}

@mixin icon-style-legacy($pseudo: before, $content: null) {
  @include _pseudo($pseudo, $content) {
    background-color: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

@mixin icon($icon: null, $size: md, $pseudo: before, $init: true) {
  @if $init {
    $content: '';
    @if $icon == null {
      $content: null;
    }
    @include icon-style($pseudo, $content);
  }

  @if $icon != null {
    @include icon-content($icon, $pseudo);
  }

  @if $size != null {
    @include icon-size($size, $pseudo);
  }

  @include _pseudo($pseudo) {
    @content;
  }
}

@mixin icon-legacy($icon: null, $size: md, $pseudo: before, $init: true) {
  @if $init {
    $content: null;
    @if $icon != null {
      content: '';
    }
    @include icon-style-legacy($pseudo, $content);
  }

  @if $icon != null {
    @include icon-content-legacy($icon, $pseudo);
  }

  @if $size != null {
    @include icon-size-legacy($size, $pseudo);
  }

  @include _pseudo($pseudo) {
    @content;
  }
}

@function filter-icons($category, $config: $icons-config) {
  $filtered: ();
  @each $icon, $setting in $config {
    @if map-get($setting, category) == $category {
      $filtered: map-merge($filtered, (#{$icon}: $setting));
    }
  }
  @return $filtered;
}

@mixin generate-icons($config: $icons-config) {
  @each $icon, $setting in $config {
    #{ns(icon-#{$icon})} {
      @include icon-content($icon, before, true);
    }
  }
}

@mixin generate-icons-legacy($config: $icons-config) {
  @each $icon, $setting in $config {
    #{ns(icon-#{$icon})} {
      @include icon-content-legacy($icon, before, true);
    }
  }
}
