////
/// Core Module : Display no-scroll
/// @group core
////

/**
* Fixe le scroll en arrière plan
*/
// TODO: transformer en attribut ?
:root#{ns-attr(scrolling)} body {
  --scroll-top: 0;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  top: var(--scroll-top);
}
