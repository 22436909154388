////
/// Core Module : Action cursor
/// @group core
////

/**
 * Override de l'opacité chrome sur un élément disabled
 */
@include disabled-selector {
  opacity: 1;
}
