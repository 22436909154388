////
/// Core Legacy : Action link
/// @group core
////

@include legacy(ie11) {
  [href],
  #{ns(reset-link)} {
    text-decoration: underline;
  }

  #{ns(raw-link)} {
    text-decoration: none;
  }

  #{ns(reset-link)} {
    @include after(none);
  }

  [target="_blank"] {
    @include icon-legacy(external-link-line, sm, after);
  }
}
