////
/// Grid Tool : colonne
/// @group core
////

/// Définit la largeur de colonne
///
/// @param {Number} $size - Proportion de la taille de colonne
/// @param {Number} $columns [$grid-columns] - Nombre de colonne dans la grille (Par défaut `$grid-columns` = 12)
///
/// @require {variable} $grid-columns
///
/// @example scss - Définit la taille de colonne à 6 sur 12
///   .foo {
///     @include grid-col(6);
///   }
@mixin grid-col($size, $columns: 12) {
  $percent: #{100% * $size};
  $calc: 'calc(#{$percent} / #{$columns})';
  flex: 0 0 #{$calc};
  width: #{$calc};
  max-width: #{$calc};
}

/// Définit le décalage de colonne (créé une marge à gauche)
///
/// @param {Number} $size - Proportion du décalage de colonne
/// @param {Number} $columns [$grid-columns] - Nombre de colonne dans la grille (Par défaut `$grid-columns` = 12)
///
/// @require {variable} $grid-columns
///
/// @example scss - Définit la taille du décalage à 2 colonnes sur 12
///   .foo {
///     @include grid-col-offset(2);
///   }
@mixin grid-col-offset($size, $columns: 12) {
  $percent: #{100% * $size};
  $calc: 'calc(#{$percent} / #{$columns})';

  @if $size == 0 {
    $calc: '0';
  }

  &:not(&--right) {
    @include margin-left($calc);
  }

  &--right {
    @include margin-right($calc);
  }
}
