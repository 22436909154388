////
/// Core Module : Reset body
/// @group core
////

@if (map-get($reset-settings, body)) {
  body {
    @include font-family;
    @include text-adjustments;
    @include margin(0);
    @include padding(0);
    @include text-style(md);
  }
}
