////
/// Core Variables
/// Les utilitaires des cores sont paramètrables ici, ces variables reprennent celles des settings de chaque utilitaire sans le '!default' à la fin.
/// De cette façon les utilitaires générés peuvent être modifiés ici au besoin (en reprennant la même structure de données) ou retirés en les passant à 'false'
/// Tips: `yarn build --styles -p core` pour tester la compilation du core
/// @group core
////

$variables: (
  reset: (
    font-face: true,
    body: true,
    box-sizing: true,
    focus: true,
    heading: true,
    hr: true,
    list: true,
    text: true,
    button: true,
    input: true
  ),
  grid: (
    breakpoints: first sm md lg
  ),
  display: (
    hr: true,
    ellipsis: true,
    hidden: (
      breakpoints: all,
    ),
    unhidden: (
      breakpoints: all,
    ),
  ),
  colors: (
  ),
  typography: (
    text: true,
    title: true,
    font-weight: (
      // values: 100 200 300 400 500 600 700 800 900 = 'all'
      values: 300 400 700 900
    )
  ),
  spacing: (
    margin: (
      from: -8,
      through: 32,
      half: 2,
      special: auto,
      breakpoints: first md
    ),
    padding: (
      from: 0,
      half: 2,
      through: 32,
      breakpoints: first md
    )
  )
);
