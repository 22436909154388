////
/// Core Setting : Color options
/// @group core
////

// Chaque couleur est définie suivant ce modèle :
// [color-name]-[grade]: [hex] [hue] [saturation] [lightness];

$scheme-options: (
  // grey
  grey-50: #161616 #343434 #474747,
  grey-75: #1e1e1e #3f3f3f #525252,
  grey-100: #242424 #474747 #5b5b5b,
  grey-125: #2a2a2a #4e4e4e #636363,
  grey-200: #3a3a3a #616161 #777,
  grey-425: #666 #919191 #a6a6a6,
  grey-625: #929292 #bbb #cecece,
  grey-850: #cecece #a8a8a8 #939393,
  grey-925: #e5e5e5 #c5c5c5 #b2b2b2,
  grey-950: #eee #d2d2d2 #c1c1c1,
  grey-975: #f6f6f6 #dfdfdf #cfcfcf,
  grey-1000: #fff #f6f6f6 #ededed,
  grey-main-525: #7b7b7b #a6a6a6 #bababa #a6a6a6 #bababa,
  // blue-france
  blue-france-75: #1b1b35 #3a3a68 #4d4d83,
  blue-france-100: #21213f #424275 #56568c,
  blue-france-125: #272747 #4a4a7d #5e5e90,
  blue-france-200: #313178 #5757ad #6c6cbb,
  blue-france-625: #8585f6 #b1b1f9 #c6c6fb,
  blue-france-850: #cacafb #a1a1f8 #8b8bf6,
  blue-france-925: #e3e3fd #c1c1fb #adadf9,
  blue-france-950: #ececfe #cecefc #bbbbfc,
  blue-france-975: #f5f5fe #dcdcfc #cbcbfa,
  blue-france-sun-113: #000091 #1212ff #2323ff,
  blue-france-main-525: #6a6af4 #9898f8 #aeaef9 #9898f8 #aeaef9,
  // red-marianne
  red-marianne-75: #2b1919 #573737 #704848,
  red-marianne-100: #331f1f #613f3f #7b5151,
  red-marianne-125: #3b2424 #6b4545 #865757,
  red-marianne-200: #5e2a2b #9c4a4c #be5c5e,
  red-marianne-425: #c9191e #f93f42 #f95a5c,
  red-marianne-625: #f95c5e #fa9293 #fbabac,
  red-marianne-850: #fcbfbf #fb8f8f #fa7474,
  red-marianne-925: #fddede #fbb6b6 #fa9e9e,
  red-marianne-950: #fee9e9 #fdc5c5 #fcafaf,
  red-marianne-975: #fef4f4 #fcd7d7 #fac4c4,
  red-marianne-main-472: #e1000f #ff292f #ff4347 #ff292f #ff4347,
  // info
  info-75: #171d2e #333e5c #445177,
  info-100: #1d2437 #3b4767 #4c5b83,
  info-125: #222a3f #414e71 #53638d,
  info-200: #273961 #45619f #5576c0,
  info-425: #0063cb #3b87ff #6798ff,
  info-625: #518fff #98b4ff #b4c7ff,
  info-850: #bccdff #83a9ff #5f96ff,
  info-925: #dde5ff #b1c6ff #95b4ff,
  info-950: #e8edff #c2d1ff #a9bfff,
  info-975: #f4f6ff #d6deff #c2cfff,
  info-main-525: #0078f3 #6196ff #85a9ff #6196ff #85a9ff,
  // success
  success-75: #142117 #2e4533 #3d5943,
  success-100: #19271d #344c3b #44624d,
  success-125: #1e2e22 #3b5541 #4b6b53,
  success-200: #204129 #3a6d48 #478558,
  success-425: #18753c #27a959 #2fc368,
  success-625: #27a658 #36d975 #3df183,
  success-850: #3bea7e #2cb862 #259e53,
  success-925: #88fdaa #3ee87e #36d070,
  success-950: #b8fec9 #46fd89 #34eb7b,
  success-975: #dffee6 #8afcab #4efb8d,
  success-main-525: #1f8d49 #2ec166 #36db75 #2ec166 #36db75,
  // warning
  warning-75: #2d1814 #5b352e #75473e,
  warning-100: #361e19 #663d35 #824f44,
  warning-125: #3e231e #70433b #8d564c,
  warning-200: #5d2c20 #9a4d3a #bc5f49,
  warning-425: #b34000 #ff6218 #ff7a55,
  warning-625: #fc5d00 #ff8c73 #ffa595,
  warning-850: #ffbeb4 #ff8e77 #ff7550,
  warning-925: #ffded9 #ffb6a9 #ff9f8b,
  warning-950: #ffe9e6 #ffc6bd #ffb0a2,
  warning-975: #fff4f3 #ffd7d3 #ffc4bd,
  warning-main-525: #d64d00 #ff754e #ff8e76 #ff754e #ff8e76,
  // error
  error-75: #301717 #603434 #7c4444,
  error-100: #391c1c #6c3a3a #894b4b,
  error-125: #412121 #764040 #935252,
  error-200: #642626 #a74545 #cb5555,
  error-425: #ce0500 #ff2725 #ff4140,
  error-625: #ff5655 #ff8c8c #ffa6a6,
  error-850: #ffbdbd #ff8c8c #ff7272,
  error-925: #fdd #ffb4b4 #ff9c9c,
  error-950: #ffe9e9 #ffc5c5 #ffafaf,
  error-975: #fff4f4 #ffd7d7 #ffc3c3,
  error-main-525: #f60700 #ff3634 #ff5150 #ff3634 #ff5150,
  // green-tilleul-verveine
  green-tilleul-verveine-75: #201e14 #433f2e #57533d,
  green-tilleul-verveine-100: #272419 #4c4734 #615b44,
  green-tilleul-verveine-125: #2d2a1d #534f39 #696349,
  green-tilleul-verveine-200: #3f3a20 #6a6239 #807747,
  green-tilleul-verveine-850: #e2cf58 #b7a846 #a0923c,
  green-tilleul-verveine-925: #fbe769 #d7c655 #c2b24c,
  green-tilleul-verveine-950: #fceeac #e8d45c #d4c254,
  green-tilleul-verveine-975: #fef7da #fce552 #ebd54c,
  green-tilleul-verveine-sun-418: #66673d #929359 #a7a967,
  green-tilleul-verveine-moon-817: #d8c634 #fee943 #fef1ab,
  green-tilleul-verveine-main-707: #b7a73f #e3cf50 #f6e157 #e3cf50 #f6e157,
  // green-bourgeon
  green-bourgeon-75: #182014 #35432e #46573d,
  green-bourgeon-100: #1e2719 #3d4c34 #4e6144,
  green-bourgeon-125: #232d1d #435339 #556949,
  green-bourgeon-200: #2a401a #496b30 #5a833c,
  green-bourgeon-850: #95e257 #77b645 #679e3b,
  green-bourgeon-925: #a9fb68 #8ed654 #7fc04b,
  green-bourgeon-950: #c9fcac #9ae95d #8dd555,
  green-bourgeon-975: #e6feda #a7fc62 #98ed4d,
  green-bourgeon-sun-425: #447049 #639f6a #72b77a,
  green-bourgeon-moon-759: #99c221 #baec2a #c9fd2e,
  green-bourgeon-main-640: #68a532 #87d443 #95e94a #87d443 #95e94a,
  // green-emeraude
  green-emeraude-75: #142018 #2e4335 #3d5846,
  green-emeraude-100: #19271e #344c3d #44624f,
  green-emeraude-125: #1e2e23 #3b5543 #4b6b55,
  green-emeraude-200: #21402c #3b6c4c #49835e,
  green-emeraude-850: #6fe49d #58b77d #4b9f6c,
  green-emeraude-925: #9ef9be #69df97 #5ec988,
  green-emeraude-950: #c3fad5 #77eda5 #6dd897,
  green-emeraude-975: #e3fdeb #94f9b9 #6df1a3,
  green-emeraude-sun-425: #297254 #3ea47a #49bc8d,
  green-emeraude-moon-753: #34cb6a #42fb84 #80fda3,
  green-emeraude-main-632: #00a95f #00e180 #00fb90 #00e180 #00fb90,
  // green-menthe
  green-menthe-75: #15201e #30433f #3f5753,
  green-menthe-100: #1a2624 #364b47 #46605b,
  green-menthe-125: #1f2d2a #3c534e #4d6963,
  green-menthe-200: #223f3a #3d6a62 #4b8178,
  green-menthe-850: #73e0cf #5bb5a7 #4f9d91,
  green-menthe-925: #8bf8e7 #6ed5c5 #62bfb1,
  green-menthe-950: #bafaee #79e7d5 #6fd3c3,
  green-menthe-975: #dffdf7 #84f9e7 #70ebd8,
  green-menthe-sun-373: #37635f #53918c #62a9a2,
  green-menthe-moon-652: #21ab8e #2eddb8 #34f4cc,
  green-menthe-main-548: #009081 #00c7b3 #00e2cb #00c7b3 #00e2cb,
  // green-archipel
  green-archipel-75: #152021 #2f4345 #3f5759,
  green-archipel-100: #1a2628 #364a4e #465f63,
  green-archipel-125: #1f2c2e #3c5255 #4d676b,
  green-archipel-200: #233e41 #3e686d #4d7f85,
  green-archipel-850: #60e0eb #4cb4bd #419ca4,
  green-archipel-925: #a6f2fa #62dbe5 #58c5cf,
  green-archipel-950: #c7f6fc #64ecf8 #5bd8e3,
  green-archipel-975: #e5fbfd #99f2f8 #73e9f0,
  green-archipel-sun-391: #006a6f #009fa7 #00bbc3,
  green-archipel-moon-716: #34bab5 #43e9e2 #4cfdf6,
  green-archipel-main-557: #009099 #00c6d2 #00e0ee #00c6d2 #00e0ee,
  // blue-ecume
  blue-ecume-75: #171d2f #333e5e #445179,
  blue-ecume-100: #1d2437 #3b4767 #4c5b83,
  blue-ecume-125: #222940 #424d73 #536190,
  blue-ecume-200: #273962 #4561a1 #5576c2,
  blue-ecume-850: #bfccfb #8ba7f8 #6b93f6,
  blue-ecume-925: #dee5fd #b4c5fb #99b3f9,
  blue-ecume-950: #e9edfe #c5d0fc #adbffc,
  blue-ecume-975: #f4f6fe #d7dffb #c3cffa,
  blue-ecume-sun-247: #2f4077 #4e68bb #667dcf,
  blue-ecume-moon-675: #869ece #b8c5e2 #ced6ea,
  blue-ecume-main-400: #465f9d #6f89d1 #8b9eda #6f89d1 #8b9eda,
  // blue-cumulus
  blue-cumulus-75: #171e2b #333f56 #43536f,
  blue-cumulus-100: #1c2433 #3a4761 #4a5b7b,
  blue-cumulus-125: #212a3a #404f69 #516384,
  blue-cumulus-200: #263b58 #436491 #5279af,
  blue-cumulus-850: #b6cffb #76adf8 #4a9df7,
  blue-cumulus-925: #dae6fd #a9c8fb #8ab8f9,
  blue-cumulus-950: #e6eefe #bcd3fc #9fc3fc,
  blue-cumulus-975: #f3f6fe #d3dffc #bed0fa,
  blue-cumulus-sun-368: #3558a2 #5982e0 #7996e6,
  blue-cumulus-moon-732: #7ab1e8 #bad2f2 #d2e2f6,
  blue-cumulus-main-526: #417dc4 #74a5ec #96b8f0 #74a5ec #96b8f0,
  // purple-glycine
  purple-glycine-75: #251a24 #4c394a #634a60,
  purple-glycine-100: #2c202b #554053 #6c536a,
  purple-glycine-125: #332632 #5d485c #755b73,
  purple-glycine-200: #502e4d #855080 #a1629c,
  purple-glycine-850: #fbb8f6 #f983f1 #f767ef,
  purple-glycine-925: #fddbfa #fbaff5 #fa96f2,
  purple-glycine-950: #fee7fc #fdc0f8 #fca8f6,
  purple-glycine-975: #fef3fd #fcd4f8 #fabff5,
  purple-glycine-sun-319: #6e445a #a66989 #bb7f9e,
  purple-glycine-moon-630: #ce70cc #dfa4dd #e7bbe6,
  purple-glycine-main-494: #a558a0 #d282cd #db9cd6 #d282cd #db9cd6,
  // pink-macaron
  pink-macaron-75: #261b19 #4e3a37 #654c48,
  pink-macaron-100: #2e211f #58423f #705551,
  pink-macaron-125: #352724 #614a45 #795d57,
  pink-macaron-200: #52312a #885449 #a5675a,
  pink-macaron-850: #fcc0b4 #fb9175 #fa794a,
  pink-macaron-925: #fddfda #fbb8ab #faa18d,
  pink-macaron-950: #fee9e6 #fdc6bd #fcb0a2,
  pink-macaron-975: #fef4f2 #fcd8d0 #fac5b8,
  pink-macaron-sun-406: #8d533e #ca795c #e08e73,
  pink-macaron-moon-833: #ffb7ae #ffe0dc #fff0ee,
  pink-macaron-main-689: #e18b76 #eabab1 #f0cfc9 #eabab1 #f0cfc9,
  // pink-tuile
  pink-tuile-75: #281b19 #513a37 #694c48,
  pink-tuile-100: #2f211f #5a423e #725550,
  pink-tuile-125: #372624 #644845 #7d5b57,
  pink-tuile-200: #55302a #8d5349 #ab665a,
  pink-tuile-850: #fcbfb7 #fb907d #fa7659,
  pink-tuile-925: #fddfdb #fbb8ad #faa191,
  pink-tuile-950: #fee9e7 #fdc6c0 #fcb0a7,
  pink-tuile-975: #fef4f3 #fcd7d3 #fac4be,
  pink-tuile-sun-425: #a94645 #d5706f #da8a89,
  pink-tuile-moon-750: #ff9575 #ffc4b7 #ffd8d0,
  pink-tuile-main-556: #ce614a #e89082 #eba89f #e89082 #eba89f,
  // yellow-tournesol
  yellow-tournesol-75: #221d11 #473e29 #5c5136,
  yellow-tournesol-100: #292416 #4f472f #655b3d,
  yellow-tournesol-125: #302a1a #584e34 #6f6342,
  yellow-tournesol-200: #43391a #706030 #88753c,
  yellow-tournesol-850: #efcb3a #c1a42d #a88e26,
  yellow-tournesol-925: #fde39c #e9c53b #d3b235,
  yellow-tournesol-950: #feecc2 #fbd335 #e6c130,
  yellow-tournesol-975: #fef6e3 #fce086 #f5d24b,
  yellow-tournesol-sun-407: #716043 #a28a62 #ba9f72,
  yellow-tournesol-moon-922: #ffe552 #e1c700 #cab300,
  yellow-tournesol-main-731: #c8aa39 #967f29 #7d6a20 #f5d148 #fcdf8a,
  // yellow-moutarde
  yellow-moutarde-75: #231d14 #483e2e #5e513d,
  yellow-moutarde-100: #2a2319 #514534 #685944,
  yellow-moutarde-125: #30291d #584d39 #6f6149,
  yellow-moutarde-200: #453820 #735f39 #8c7447,
  yellow-moutarde-850: #fcc63a #cb9f2d #b18a26,
  yellow-moutarde-925: #fde2b5 #f6c43c #dfb135,
  yellow-moutarde-950: #feebd0 #fdcd6d #f4be30,
  yellow-moutarde-975: #fef5e8 #fcdca3 #fbcd64,
  yellow-moutarde-sun-348: #695240 #9b7b61 #b58f72,
  yellow-moutarde-moon-860: #ffca00 #cda200 #b28c00,
  yellow-moutarde-main-679: #c3992a #f5c137 #fcd17b #f5c137 #fcd17b,
  // orange-terre-battue
  orange-terre-battue-75: #281a16 #513932 #6a4b42,
  orange-terre-battue-100: #31201c #5d403a #77534a,
  orange-terre-battue-125: #382621 #664840 #7f5b51,
  orange-terre-battue-200: #543125 #8b5442 #a96751,
  orange-terre-battue-850: #fcc0b0 #fb926b #fa7a35,
  orange-terre-battue-925: #fddfd8 #fbb8a5 #faa184,
  orange-terre-battue-950: #fee9e5 #fdc6ba #fcb09e,
  orange-terre-battue-975: #fef4f2 #fcd8d0 #fac5b8,
  orange-terre-battue-sun-370: #755348 #ab7b6b #c68f7d,
  orange-terre-battue-moon-672: #ff732c #ffa48b #ffbbab,
  orange-terre-battue-main-645: #e4794a #f1a892 #f4bfb1 #f1a892 #f4bfb1,
  // brown-cafe-creme
  brown-cafe-creme-75: #211d16 #453e31 #5a5141,
  brown-cafe-creme-100: #28241c #4e4739 #635b4a,
  brown-cafe-creme-125: #2e2a21 #554e3f #6b6351,
  brown-cafe-creme-200: #423925 #6e6041 #867550,
  brown-cafe-creme-850: #e7ca8e #bca473 #a48f64,
  brown-cafe-creme-925: #f4e3c7 #e1c386 #ccb078,
  brown-cafe-creme-950: #f7ecdb #edce94 #dabd84,
  brown-cafe-creme-975: #fbf6ed #f2deb6 #eacf91,
  brown-cafe-creme-sun-383: #685c48 #97866a #ae9b7b,
  brown-cafe-creme-moon-885: #ecd7a2 #c5b386 #af9f77,
  brown-cafe-creme-main-782: #d1b781 #a38e63 #8b7954 #f0dab2 #f6ead5,
  // brown-caramel
  brown-caramel-75: #251c16 #4c3c31 #624e41,
  brown-caramel-100: #2c221c #554439 #6c574a,
  brown-caramel-125: #332821 #5d4b40 #755f51,
  brown-caramel-200: #4b3525 #7c5a41 #976e51,
  brown-caramel-850: #eac7b2 #d69e75 #bc8a66,
  brown-caramel-925: #f3e2d9 #e7bea6 #e1a982,
  brown-caramel-950: #f7ebe5 #eccbb9 #e6b79a,
  brown-caramel-975: #fbf5f2 #f1dbcf #ecc9b5,
  brown-caramel-sun-425: #845d48 #bb8568 #d69978,
  brown-caramel-moon-901: #fbd8ab #efb547 #d6a23e,
  brown-caramel-main-648: #c08c65 #e6b594 #eccab6 #e6b594 #eccab6,
  // brown-opera
  brown-opera-75: #241c17 #4a3c33 #604f44,
  brown-opera-100: #2b221c #53443a #6a574a,
  brown-opera-125: #322821 #5c4b40 #735f51,
  brown-opera-200: #493625 #795c41 #937050,
  brown-opera-850: #eac7ad #d09f74 #b68b65,
  brown-opera-925: #f3e2d7 #e7bfa0 #deaa7e,
  brown-opera-950: #f7ece4 #eccdb3 #e6ba90,
  brown-opera-975: #fbf5f2 #f1dbcf #ecc9b5,
  brown-opera-sun-395: #745b47 #a78468 #c09979,
  brown-opera-moon-820: #e6be92 #f2e2d3 #f8f0e9,
  brown-opera-main-680: #bd987a #e1c0a8 #e9d4c5 #e1c0a8 #e9d4c5,
  // beige-gris-galet
  beige-gris-galet-75: #211d19 #453e37 #595148,
  beige-gris-galet-100: #28231f #4e453f #635950,
  beige-gris-galet-125: #2e2924 #554d45 #6b6157,
  beige-gris-galet-200: #433829 #705f47 #887358,
  beige-gris-galet-850: #e0cab0 #baa48a #a38f78,
  beige-gris-galet-925: #eee4d9 #dbc3a4 #c6b094,
  beige-gris-galet-950: #f3ede5 #e1d0b5 #d1bea2,
  beige-gris-galet-975: #f9f6f2 #eadecd #e1ceb1,
  beige-gris-galet-sun-407: #6a6156 #988b7c #afa08f,
  beige-gris-galet-moon-821: #d0c3b7 #eae5e1 #f4f2f0,
  beige-gris-galet-main-702: #aea397 #d5cabd #e2dbd4 #d5cabd #e2dbd4,
);
