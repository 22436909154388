////
/// Core Tool : Typography build
/// @group core
////

@function typography-selector($type, $name, $is-modifier: false, $alt: null) {
  $base: '';
  $separator: '-';

  @if $is-modifier {
    $separator: '--';
  }

  @if $type != heading {
    $base: '#{$type + $separator}';
  }

  $selectors: (ns('#{$base + $name}'));

  @if $alt != null {
    $selectors: append($selectors, ns('#{$base + $alt}'), 'comma');
  }

  @return $selectors;
}

@mixin build-utillity-typography() {
  @if $text-utilities {
    @each $name, $variation in $title-settings {
      $type: display;
      @if str-index(quote($name), 'h') == 1 {
        $type: heading;
      }

      #{typography-selector($type, $name, true, map-get($variation, alt))} {
        @include title-style($name, true, true, null, true);
      }
    }

    #{ns(text--alt)} {
      @include font-family(alt, true);
    }
  }

  @if $title-utilities {
    @each $name, $variation in $text-settings {
      #{typography-selector(text, $name, true, map-get($variation, alt))} {
        @include text-style($name, true, true, null, true);
      }
    }
  }
}

@mixin title-selector() {
  $titles: ();
  @each $name, $variation in $title-settings {
    $type: display;
    @if str-index(quote($name), 'h') == 1 {
      $type: heading;
    }

    $titles: append($titles, typography-selector($type, $name, false, map-get($variation, alt)), comma);
  }
  #{$titles} {
    @content;
  }
}

@mixin generate-margin-vars() {
  :root {
    @each $name, $margin in $text-spacing {
      --#{$name}-spacing: #{space($margin)};
    }
  }
}
