////
/// Core Function : Color get color
/// @group core
////

@function get-colors($tokens, $type, $options: ()) {
  $colors: ();
  $length: length($tokens);
  @for $i from 1 through $length {
    $colors: append($colors, get-color(nth($tokens, $i), $type, $options));
  }
  @return $colors;
}

@function get-color($token, $type, $options: ()) {
  @if $type == hex {
    @return _get-color-hex($token, $options);
  }
  @else if $type == decision {
    @return _get-color-decision($token, $options);
  }
  @else if $type == set {
    @return _get-color-set($token, $options);
  }
}

@function _get-color-hex($token, $options) {
  $theme: map-get($options, theme);
  $settings: _get-set-settings($token);
  $index: 1;
  @if $theme == dark {
    $index: 2;
  }
  $name: nth($settings, $index);

  @if not map-has-key($scheme-options, $name) {
    @error ('color ' + $name + ' doesn\'t exists');
  }

  $option: map-get($scheme-options, $name);

  $hex: nth($option, 1);

  @if map-get($options, hover) {
    $hex: nth($option, 2);
  }

  @if map-get($options, active) {
    $hex: nth($option, 3);
  }

  @if length($settings) > 2 {
    $setting: nth($settings, 3);
    @if map-has-key($setting, alpha) {
      @return rgba($hex, map-get($setting, alpha));
    }
  }

  @return $hex;
}

@function _get-color-decision($token, $options) {
  $var: map-get($options, var);
  $prop: --#{nth($token, 1)}-#{nth($token, 2)}-#{nth($token, 3)};

  @if map-get($options, hover) == true {
    $prop: #{$prop}-hover;
  }

  @if map-get($options, active) == true {
    $prop: #{$prop}-active;
  }

  @if map-get($options, var) == true {
    @return var($prop);
  }
  @return $prop;
}

@function _get-color-set($token, $options) {
  $settings: _get-set-settings($token);
  $light: nth($settings, 1);
  $light-split: str-split($light, '-');
  $light-length: length($light-split);
  $dark: nth($settings, 2);
  $dark-split: str-split($dark, '-');
  $dark-length: length($dark-split);
  $name: $light;
  $identical: true;
  @for $i from 1 through $dark-length {
    $chunk: nth($dark-split, $i);
    @if index($light-split, $chunk) == null {
      $name: #{$name}-#{$chunk};
    }
  }

  $prop: --#{$name};
  @if length($settings) > 2 {
    $params: nth($settings, 3);
    @if map-has-key($params, alpha) {
      $prop: _set-prop-alpha($prop, map-get($params, alpha));
    }
  }

  @if map-get($options, hover) == true {
    $prop: #{$prop}-hover;
  }

  @if map-get($options, active) == true {
    $prop: #{$prop}-active;
  }

  @if map-get($options, var) == true {
    @return var($prop);
  }
  @return $prop;
}

/// Retourne l'option correspondante
/// @access private
/// @param {String} $token
/// @param {String} $theme
/// @return {String} Retourne la liste correspondant à l'option
@function _get-set-settings($token, $theme: light) {
  $context: nth($token, 1);
  $use: nth($token, 2);
  $tint: nth($token, 3);

  $family: null;
  @if map-has-key($scheme-sets, $tint) {
    $family: $tint;
  }
  @else {
    @each $set-family, $tints in $scheme-sets {
      @if map-has-key($tints, $tint) {
        $family: $set-family;
      }
    }
  }
  @if $family == null {
    @error ('no family corresponding to tint #{$tint}');
  }

  @if not map-has-key($scheme-decisions, $context) {
    @error ('no $context #{$context} in $scheme-decisions');
  }
  $uses: map-get($scheme-decisions, $context);

  @if not map-has-key($uses, $use) {
    @error ('no $use #{$use} in $scheme-decisions #{$context}');
  }
  $families: map-get($uses, $use);

  @if not map-has-key($families, $family) {
    @error ('no $family #{$family} in $scheme-decisions #{$context} #{$use}');
  }
  $decision: map-get($families, $family);
  $shade: nth($decision, 1);

  $set-families: map-get($scheme-sets, $family);
  $set-shades: map-get($set-families, $tint);
  $settings: map-get($set-shades, $shade);

  @if length($decision) > 1 {
    $settings: append($settings, nth($decision, 2));
  }

  @return $settings;
}

@function _set-prop-alpha($prop, $alpha: 1) {
  @if $alpha == null or $alpha == 1 {
    @return $prop;
  }

  $a: nth(str-split(quote($alpha), '.'), 2);
  @return #{$prop}-a#{$a};
}
