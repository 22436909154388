////
/// Core Main
/// @group core
////

/* ¯¯¯¯¯¯¯¯¯ *\
  CORE
\* ˍˍˍˍˍˍˍˍˍ */

@import 'style/path/path-1';
@import '../media-query-order';
@import 'index';
@import 'style/module';
@import 'style/scheme';

// deprecated
@import 'deprecated/style/module';

@include _core-scheme;
