////
/// Core Tool : Typography font-weight
/// @group core
////

/// Return font-weight value from font-weight map.
///
/// @param {String} $scale ['regular'] - Type scale value from `$weight-scale` (Default to 'regular' = 400)
///
/// @example scss - Set font-weight to bold (700)
///   .foo {
///     font-weight: font-weight('bold');
///   }

@function font-weight($scale: regular) {
  @return map-get($font-weight-scale, $scale);
}

@mixin font-weight($scale) {
  font-weight: font-weight($scale);
}

@mixin build-utillity-font-weight() {
  $fontWeightValues: map-get($font-weight-vars, values);
  @if $fontWeightValues == 'all' {
    $fontWeightValues: map-keys($font-weight-names);
  }
  @each $weight in $fontWeightValues {
    $name: map-get($font-weight-names, $weight);
    & #{ns('text--' + $name)} {
      font-weight: #{$weight} !important;
    }
  }
}
