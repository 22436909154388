////
/// Core Tools : Color decisions
/// @group schemes
////

@mixin build-decisions($with-theme: false) {
  $sets: ();
  @each $context, $uses in $scheme-decisions {
    @each $use, $families in $uses {
      @each $family, $settings in $families {
        $shade: nth($settings, 1);
        $alpha: false;
        $hover: false;

        @if length($settings) > 1 {
          $params: nth($settings, 2);
          @if map-has-key($params, alpha) {
            $alpha: true;
          }
          @if map-has-key($params, hover) {
            $hover: true;
          }
        }
        @each $tint, $shades in map-get($scheme-sets, $family) {
          @if map-has-key($shades, $shade) {
            $token: $context $use $tint;
            $set: get-color($token, set);
            $sets-tint: map-get($sets, $tint);
            @if $sets-tint == null {
              $sets-tint: ();
            }
            @if not map-has-key($sets-tint, $set) or $hover {
              $sets-tint: map-merge($sets-tint, ($set: (token: $token, hover: $hover)));
            }

            $sets: map-merge($sets, ($tint: $sets-tint));

            @if not $with-theme {
              $decision-prop: get-color($token, decision);
              #{$decision-prop}: get-color($token, set, (var: true));

              @if $hover == true {
                $decision-prop-hover: get-color($token, decision, (hover: true));
                #{$decision-prop-hover}: get-color($token, set, (var: true, hover: true));

                $decision-prop-active: get-color($token, decision, (active: true));
                #{$decision-prop-active}: get-color($token, set, (var: true, active: true));
              }
            }
          }
        }
      }
    }
  }

  $theme: light;
  @if $with-theme {
    $theme: dark;
  }

  @each $family, $tints in $scheme-sets {
    @each $tint, $shades in $tints {
      @if map-has-key($sets, $tint) {
        @each $set, $map in map-get($sets, $tint) {
          $token: map-get($map, token);
          $hover: map-get($map, hover);
          #{$set}: get-color($token, hex, (theme: $theme));

          @if $hover {
            #{$set}-hover: get-color($token, hex, (theme: $theme, hover: true));
            #{$set}-active: get-color($token, hex, (theme: $theme, active: true));
          }
        }
      }
    }
  }
}
