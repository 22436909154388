////
/// Core Tool : Action cursor
/// @group core
////

@mixin cursor-selector($type, $elements: $action-elements) {
  $selectors: ();
  @each $element, $setting in $elements {
    $cursor: map-get($setting, cursor);
    $selector: map-get($setting, selector);
    @if $cursor {
      $kind: map-get($cursor, $type);
      @if $kind {
        $selectors: append($selectors, nest($selector, map-get($kind, selector)), 'comma');
      }
    }
  }

  #{$selectors} {
    @content;
  }
}
