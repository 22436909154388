////
/// Core Tool : Reset hr
/// @group core
////

@mixin build-hr() {
  @include padding(var(--text-spacing));
  @include margin(0);
  border: 0;
  display: block;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

@mixin build-hr-scheme($legacy) {
  @include scheme-element-bg-image-color(border default grey, (legacy:$legacy));
}
