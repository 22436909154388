////
/// Core Tool : Reset
/// @group core
////

$underline-thickness: 0.0625em;

@mixin a-reset ($minimal: false) {
  text-decoration: none;
  @if not $minimal {
    color: inherit;
  }
}

@mixin enable-underline() {
  --underline-img: linear-gradient(0deg, currentColor, currentColor);
  --link-blank-content: '';
}

@mixin enable-underline-legacy() {
  [href] {
    text-decoration: underline;
  }

  [target='_blank']::after {
    content: '';
  }
}

@mixin disable-underline() {
  --underline-img: none;
  --link-blank-content: none;
}

@mixin disable-underline-legacy() {
  [href] {
    text-decoration: none;
  }

  [target='_blank']::after {
    content: none;
  }
}

@mixin link-underline() {
  background-image: var(--underline-img), var(--underline-img);
  background-position: 50% calc(50% + #{10.5 * $underline-thickness }), 50% calc(50% + #{10.5 * $underline-thickness });
  background-repeat: no-repeat, no-repeat;
  transition: background-size 0s;
  @include link-underline-background-size(100%, 0);
}

@mixin link-underline-background-size($base-width: 100%, $hover-width: 100%) {
  background-size: #{$hover-width} #{$underline-thickness * 2 }, #{$base-width} #{$underline-thickness };
}

@mixin hover-underline () {
  &:hover,
  &:active {
    @include link-underline-background-size(100%, 100%);
  }
}

@mixin link-blank() {
  @include icon-style(after) {
    @include margin-left(1v);
    @include margin-bottom(1v);
  }
  @include icon-size(sm, after);
  @include icon-content(external-link-line, after, null, var(--link-blank-content));
}
