////
/// Core Module : Reset input & form field
/// @group core
////

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include font-family;
  @include text-adjustments;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  margin: 0; // Address margins set differently in Firefox 4+, Safari, and Chrome.
}

// Fix for NVDA
input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
