////
/// Core Color Scheme : Decisions
/// @group core
////

@mixin _core-color-decisions-scheme($legacy: false) {
  $decisions: map-get(map-get($color-variable, utilities), decisions);

  @each $context, $list in $decisions {
    $uses: map-get($scheme-decisions, $context);
    @if $uses == null {
      @error 'context #{$context} doesn\'t exist in $scheme-decisions';
    }
    @each $use in $list {
      $families: map-get($uses, $use);
      @if $families == null {
        @error 'use #{$use} doesn\'t exist in $scheme-decisions';
      }
      @each $family, $settings in $families {
        $shade: nth($settings, 1);
        @each $tint, $shades in map-get($scheme-sets, $family) {
          @if map-has-key($shades, $shade) {
            $selector: '#{$context}-#{$use}--#{$tint}';
            #{ns($selector)} {
              @if $context == background {
                $hover: false;
                @if length($settings) > 1 {
                  $params: nth($settings, 2);
                  @if map-get($params, hover) {
                    $hover: true;
                  }
                }
                @include scheme-element-bg-color($use $tint, (legacy: $legacy, important: true, hover: $hover));
              }
              @else if $context == text {
                @include scheme-element-text-color($use $tint, (legacy: $legacy, important: true));
              }
              @else if $context == artwork {
                @include scheme-element-fill-color(artwork $use $tint, (legacy: $legacy, important: true));
              }
            }
          }
        }
      }
    }
  }
}
