////
/// Core Tool : Display display
/// @group core
////

@mixin display-flex($flex-direction: row, $align-items: null, $justify-content: null, $flex-wrap: null, $inline: false) {

  @if $inline == true {
    display: inline-flex;
  }
  @else {
    display: flex;
  }

  @if $flex-direction != null {
    flex-direction: #{$flex-direction};
  }

  @if $align-items != null {
    align-items: #{$align-items};
  }

  @if $justify-content != null {
    justify-content: #{$justify-content};
  }

  @if $flex-wrap != null {
    flex-wrap: #{$flex-wrap};
  }
}
