////
/// Core Legacy : Action hover
/// @group core
////

@include legacy(ie11) {
  #{ns(enlarge-link)} {
    @include scheme-element-bg-transparent((legacy: true, hover: true));

    @include disable-underline-legacy;
  }
}
