////
/// Core Module : Reset font-face
/// @group core
////

/**
 * Déclaration des fontes
 */
@if (map-get($reset-settings, font-face)) {
  @include build-font-faces;
}
