////
/// Core Module : Selector pseudo
/// @group core
////

#{ns(no-before)} {
  @include before(none);
}

#{ns(no-after)} {
  @include after(none);
}
