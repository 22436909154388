////
/// Core Tool : Collapse
/// @group core
////

@mixin collapse($className: collapse) {
  --collapse-max-height: 0;
  --collapse: -99999px;
  --collapser: '';

  overflow: hidden;
  transition: visibility 0.3s;
  max-height: 0;
  max-height: var(--collapse-max-height);

  &::before {
    display: block;
    content: '';
    content: var(--collapser);
    transition: margin-top 0.3s;
    margin-top: 0;
  }

  #{&}:not(&--expanded) {
    visibility: hidden;

    @include before {
      margin-top: -99999px;
      margin-top: var(--collapse);
    }
  }
}
