////
/// Core Tool : Spacing size
/// @group core
////

@mixin width($width:null, $from: null) {
  @include respond-from($from) {
    @if $width != null {
      width: #{space($width)};
    }
  }
}

@mixin min-width($width:null, $from: null) {
  @include respond-from($from) {
    @if $width != null {
      min-width: #{space($width)};
    }
  }
}

@mixin max-width($width:null, $from: null) {
  @include respond-from($from) {
    @if $width != null {
      max-width: #{space($width)};
    }
  }
}

@mixin height($height:null, $from: null) {
  @include respond-from($from) {
    @if $height != null {
      height: #{space($height)};
    }
  }
}

@mixin min-height($height:null, $from: null) {
  @include respond-from($from) {
    @if $height != null {
      min-height: #{space($height)};
    }
  }
}

@mixin max-height($height:null, $from: null) {
  @include respond-from($from) {
    @if $height != null {
      max-height: #{space($height)};
    }
  }
}

@mixin size($width:null, $height:null, $from: null) {
  @include respond-from($from) {
    @if $width != null {
      width: #{space($width)};
    }
    @if $height != null {
      height: #{space($height)};
    }
  }
}

@mixin min-size($min-width:null, $min-height:null, $from: null) {
  @include respond-from($from) {
    @if $min-width != null {
      min-width: #{space($min-width)};
    }
    @if $min-height != null {
      min-height: #{space($min-height)};
    }
  }
}

@mixin max-size($max-width:null, $max-height:null, $from: null) {
  @include respond-from($from) {
    @if $max-width != null {
      max-width: #{space($max-width)};
    }
    @if $max-height != null {
      max-height: #{space($max-height)};
    }
  }
}
