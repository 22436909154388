////
/// Core Module : Action hover
/// @group core
////

@include hover-media-query {
  :root {
    --brighten: -1;
  }

  @include hover-type-selector($action-elements, all, true) {
    @include hover-tint;
  }

  @include hover-type-selector($action-elements, underline, true) {
    @include hover-underline;
  }
}

@include hover-type-selector($action-elements, tint) {
  @include enable-tint;
}

@include hover-type-selector($action-elements, underline) {
  @include tint-enabled(false, true);
}
