////
/// Core Tool : Action Hover
/// @group core
////

@mixin hover-media-query () {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover-type-selector($elements, $type: all, $check-disabled: false) {
  $selectors: ();

  @each $element, $setting in $elements {
    $hover: map-get($setting, hover);
    $selector: map-get($setting, selector);
    @if $hover {
      @if $type == map-get($hover, type) or $type == all {
        $nested: $selector;
        @if $check-disabled {
          $nested: nest($selector, map-get($hover, selector));
        }
        $associated: $nested;
        @if & != null and quote(&) != ':root' {
          $associated: selector-associate(quote(&), $nested);
        }
        $selectors: append($selectors, $associated, 'comma');
      }
    }
  }

  @at-root #{$selectors} {
    @content;
  }
}

@mixin hover-tint () {
  &:hover {
    background-color: var(--hover-tint);
  }

  &:active {
    background-color: var(--active-tint);
  }
}

@mixin enable-tint () {
  @include tint-enabled(true, true);
}

@mixin disable-tint() {
  @include tint-enabled(false, false);
}

@mixin tint-enabled ($hover: null, $active: null) {
  @if $hover {
    --hover-tint: var(--hover);
  }
  @else if $hover == false {
    --hover-tint: transparent;
  }

  @if $active {
    --active-tint: var(--active);
  }
  @else if $active == false {
    --active-tint: transparent;
  }
}

@mixin hover-brighten ($element, $img) {
  @at-root #{$element} {
    &:hover {
      #{$img} {
        --brightness: calc(100% + var(--brighten) * #{$hover-brighten});
      }
    }

    &:active {
      #{$img} {
        --brightness: calc(100% + var(--brighten) * #{$hover-brighten * 2});
      }
    }
  }
}

@mixin hover-brighten-filter () {
  filter: brightness(var(--brightness));
}
