////
/// Core Tool : Display sr-only
/// @group core
////

@mixin sr-only() {
  @include absolute;
  @include size(1px, 1px);
  @include padding(0);
  @include margin(-1px);
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

@mixin sr-only-from() {
  @each $bp, $limits in $breakpoints {
    @if $bp != xs {
      #{ns('sr-only-' + $bp)} {
        @include respond-from($bp) {
          @include sr-only();
        }
      }
    }
  }
}

@mixin sr-only-to() {
  @each $bp, $limits in $breakpoints {
    @if $bp != xs {
      #{ns('sr-only-to-' + $bp)} {
        @include respond-to($bp) {
          @include sr-only();
        }
      }
    }
  }
}
