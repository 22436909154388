////
/// Core Module : Grid row
/// @group core
////

#{ns(grid-row)} {
  @include display-flex(null, null, null, wrap);
  @include margin(0);
  @include padding(0);

  // Vertical alignment
  &--top {
    align-items: flex-start;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--middle {
    align-items: center;
  }

  // Horizontal alignment
  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }
}
