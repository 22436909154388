////
/// Core scheme : Display hr
/// @group core
////

@mixin _core-display-hr-scheme($legacy: false) {
  hr {
    @include build-hr-scheme($legacy);
  }

  @include build-utility(display, hr) {
    @include build-hr-scheme($legacy);
  }
}
