////
/// Core Color Tools : Element
/// @group core
////

$ACCENT: none;

@mixin _scheme-element($prop, $context, $tokens, $options: (), $value: $COLOR) {
  $legacy: map-get($options, legacy);
  $important: map-get($options, important);
  $hover: map-get($options, hover);
  $standalone: map-get($options, standalone);

  $legacy-target: null;
  @if $legacy {
    $legacy-target: ie11;
  }

  $tokens: normalise-tokens($tokens, $context);
  $type: decision;
  $options: (var: true);

  @if $legacy or $standalone {
    $type: hex;
    $option: (theme: light);
  }

  $colors: get-colors($tokens, $type, $options);
  $result: get-result($colors, $value);
  $result: important($result, $important);

  @include legacy($legacy-target) {
    #{$prop}: #{$result};
  }

  @if ($hover == true or ($hover == inherit and $legacy == false)) and ($context == background and length($tokens) == 1) {
    $nest-hover: null;
    $prop-hover: --hover;
    $result-hover: #{important(get-color(nth($tokens, 1), decision, (var: true, hover: true)), $important)};
    $nest-active: null;
    $prop-active: --active;
    $result-active: #{important(get-color(nth($tokens, 1), decision, (var: true, active: true)), $important)};

    @if $legacy or $standalone {
      $type: hex;
      $option: (theme: light);

      $nest-hover: '&:hover';
      $prop-hover: $prop;
      $result-hover: #{important(get-color(nth($tokens, 1), hex, (hover: true)), $important)};
      $nest-active: '&:active';
      $prop-active: $prop;
      $result-active: #{important(get-color(nth($tokens, 1), hex, (active: true)), $important)};
    }

    @include legacy($legacy-target) {
      @include nest($nest-hover) {
        #{$prop-hover}: #{$result-hover};
      }

      @include nest($nest-active) {
        #{$prop-active}: #{$result-active};
      }
    }
  }
}

/// Ajout d'une couleur de background sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
@mixin scheme-element-bg-color($tokens, $options:()) {
  @if not map-has-key($options, hover) {
    $options: map-merge($options, (hover: inherit));
  }
  @include _scheme-element(background-color, background, $tokens, $options);
}

/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
///   - hover {boolean}: si true, surcharge la valeur de blend pour être héritée
@mixin scheme-element-bg-transparent($options) {
  $legacy: map-get($options, legacy);
  $important: map-get($options, important);
  $hover: map-get($options, hover);
  $tokens: normalise-tokens(default grey, background);
  $value: important(transparent, $important);

  @if $legacy {
    @include legacy(ie11) {
      background-color: transparent;

      @if $hover {
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  @else {
    background-color: #{$value};
    @if $hover {
      --hover: inherit;
      --active: inherit;
    }
  }
}

/// Ajout d'une couleur de background sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
@mixin scheme-element-bg-image-color($tokens, $options, $value: linear-gradient(0deg, $COLOR, $COLOR)) {
  $d: get-tokens-length($tokens);
  @if $d > 1 and $value == linear-gradient(0deg, $COLOR, $COLOR) {
    $transformed: ();
    @for $i from 1 through $d {
      $c: unquote('$color##{$i}');
      $transformed: append($transformed, linear-gradient(0deg, $c, $c), comma);
    }
    $value: $transformed;
  }
  @include _scheme-element(background-image, background, $tokens, $options, $value);
}

/// Ajout d'une couleur de texte sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
@mixin scheme-element-text-color($tokens, $options) {
  @include _scheme-element(color, text, $tokens, $options);
}

/// Ajout d'une couleur de fill sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
@mixin scheme-element-fill-color($tokens, $options) {
  @include _scheme-element(fill, background , $tokens, $options);
}

/// Ajout d'une couleur de border sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
///   - side {String}: le côté affecté. valeurs: top, right, bottom, left
/// @param {List} $value - définition des propriétés de border
@mixin scheme-element-border-color($tokens, $options, $value:1px solid $COLOR) {
  $prop:border;
  @if map-has-key($options, side) {
    $prop:border-#{map-get($options, side)};
  }
  @include _scheme-element($prop, border, $tokens, $options, $value);
}

@mixin scheme-element-no-border($breakpoint: null) {
  @include respond-from($breakpoint) {
    border: 0;
  }
}

/// Ajout d'une couleur d'outline sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
/// @param {List} $value - définition des propriétés d'outline
@mixin scheme-element-outline-color($tokens, $options, $value:1px solid $COLOR) {
  @include _scheme-element(outline, border, $tokens, $options, $value);
}

/// Ajout d'une couleur de box-shadow sur un élément
/// @access public
/// @param {list} $tokens - liste des tokens de décision
/// @param {map} $options - map des options :
///   - legacy {boolean}: version pour navigateurs modernes ou anciens.
///   - important {boolean}: si true, applique !important à la règle css
/// @param {List} $value - définition des propriétés de box-shadow
@mixin scheme-element-box-shadow-color($tokens, $options, $value: all-1-in) {
  $has-keys: true;
  $transformed: ();
  $d: get-tokens-length($tokens);
  $i: 1;
  @each $v in $value {
    @if map-has-key($box-shadows, $v) {
      $transformed: append($transformed, _get-box-shadow($v, $i), comma);
      @if $i < $d {
        $i: $i + 1;
      }
    }
    @else {
      $has-keys: false;
    }
  }
  @if $has-keys {
    $value: $transformed;
  }
  @include _scheme-element(box-shadow, border, $tokens, $options, space($value));
}

/// Supprime la shadow-box sur l'élément
/// @access public
/// @param {string} $breakpoint - la shadow-box est retiré à partir d'un breakpoint si celui-ci est défini
@mixin scheme-element-no-box-shadow($breakpoint: null) {
  @include respond-from($breakpoint) {
    box-shadow: none;
  }
}
