////
/// Core Module : Motion
/// @group core
////

#{ns(transition)} {
  &-none {
    transition: none !important;
  }
}
